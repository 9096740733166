import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/reset.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// Tạo một phiên bản mới của QueryClient
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 300000, // Lưu trữ dữ liệu trong cache trong 5 phút (tính bằng mili giây)
            staleTime: 60000, // Coi dữ liệu là cũ sau 1 phút (tính bằng mili giây)
            refetchOnWindowFocus: false, // Tắt tự động refetch khi cửa sổ được focus
            retry: 3, // Thử lại truy vấn lỗi tối đa 3 lần
        },

        mutations: {
            throwOnError: true, // Throw error nếu mutation gặp lỗi
        },
    },
});

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    //Will render 2 times on every re-render:
    // <React.StrictMode>
    //     <App />
    // </React.StrictMode>
    <QueryClientProvider client={queryClient}>
        {/* Các thành phần của ứng dụng */}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <App />
    </QueryClientProvider>,

    // Will render 1 time:
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
