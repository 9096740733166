// const BASE_URL = 'http://localhost:8069/api/';
const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://api.nagaco.com/api/';
const AUTH_URL = process.env.REACT_APP_SSO_ENDPOINT ?? 'https://ssoerp.nagaco.com/';
const API_AUTH_URL = process.env.REACT_APP_SSO_API ?? 'https://ssoerp.nagaco.com/api/';

export const HELP_URL = `/help`;

export const HELP_NOT_FOUND_URL = `${HELP_URL}/not_found.txt`;

const dev = {
    API_ENDPOINT_URL: BASE_URL,
    API_AUTH_URL: API_AUTH_URL,
    AUTH_ENDPOINT_URL: AUTH_URL,
};

const prod = {
    API_ENDPOINT_URL: BASE_URL,
    API_AUTH_URL: API_AUTH_URL,
    AUTH_ENDPOINT_URL: AUTH_URL,
};

const test = {
    API_ENDPOINT_URL: BASE_URL,
    API_AUTH_URL: API_AUTH_URL,
    AUTH_ENDPOINT_URL: AUTH_URL,
};

const getEnv = () => {
    //let obj=JSON.parse(process.env.NODE_ENV)
    // console.log(process.env.NODE_ENV)
    // const myObject = (JSON.parse(process.env.API)).dev
    // console.log('myObject',myObject)
    switch (process.env.NODE_ENV) {
        case 'development':
            return dev;
        case 'production':
            return prod;
        case 'test':
            return test;
        default:
            break;
    }
};

export const env = getEnv();
