import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig';

export const APP_NAME = 'Nagaco';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const API_AUTH_URL = env.API_AUTH_URL;
export const AUTH_ENDPOINT_URL = env.AUTH_ENDPOINT_URL;
export const APP_PREFIX_PATH = '/fob';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect';
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
export const UNAUTHENTICATED_ENTRY = '/login';

export const EDITOR_API_KEY = 'b5po5o74aat9p0538x0cd2b1y31zb6pjsg36z722xxa3nllf';

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: localStorage.getItem('i18nextLng') || 'vi',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#1e5f85',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: localStorage.getItem('theme') || 'light',
    direction: DIR_LTR,
    blankLayout: false,
};

export const NAGACO_MODE = {
    add: 'ADD',
    detail: 'DETAIL',
    edit: 'EDIT',
    export: 'EXPORT',
    export_one: 'EXPORT_ONE',
    import: 'IMPORT',
    list: 'LIST',
    masscopy: 'MASSCOPY',
    copy: 'COPY',
};

export const EXPORT_TYPE = {
    pdf: 'pdf',
    xml: 'xml',
    xlsx: 'xlsx',
    json: 'json',
    docx: 'docx',
    csv: 'csv',
};

export const EXPORT_VALUE = {
    pdf: '1',
    xml: '2',
    xlsx: '3',
    json: '4',
    docx: '5',
    csv: '6',
    export_with_template: 'export_with_template',
    export_new_template: 'export_new_template',
};

export const IMPORT_TYPE = {
    xlsx: 'xlsx',
    xml: 'xml',
    json: 'json',
    csv: 'csv',
};

export const IMPORT_VALUE = {
    xlsx: '1',
    xml: '2',
    json: '3',
    csv: '4',
};

export const FUNCTION_ID = {
    list: '01',
    edit: '02',
    add: '03',
    detail: '04',
    import: '05',
    export: '06',
    masscopy: '07',
    help: '08',
};

export const CATEGORY_PREFIX = {
    size: 'FOB_CAT_SIZE',
    fit: 'FOB_CAT_FIT',
    stuff: 'FOB_CAT_STUFF',
    product_group: 'FOB_CAT_PROD_GRP',
    package: 'FOB_CAT_PACK',
    supplier: 'FOB_CAT_SUPP',
    article: 'FOB_CAT_ARTICLE',
    customer_group: 'FOB_CAT_CUSTOMER_GROUP',
    customer_type: 'FOB_CAT_CUSTOMER_TYPE',
    customer: 'FOB_CAT_CUSTOMER',
    brand: 'FOB_CAT_BRAND',
    season: 'FOB_CAT_SEAS',
    currency: 'FOB_CAT_CURRENCY',
    cost_sheet_template: 'FOB_CAT_COST_SHEET_TEMPLATE',
    other_cost_group: 'FOB_CAT_OTHER_COST_GROUP',
    other_cost_type: 'FOB_CAT_OTHER_COST_TYPE',
    other_cost: 'FOB_CAT_OTHER_COST',
    color: 'FOB_CAT_COL',
    wash_type: 'FOB_CAT_WASH_TYPE',
    invoice_type: 'FOB_CAT_INVOICE_TYPE',
    invoice_status: 'FOB_CAT_INVOICE_STATUS',
    invoice_term: 'FOB_CAT_INVOICE_TERM',
    invoice_contract_type: 'FOB_CAT_INVOICE_CONTRACT_TYPE',
    payment_method: 'FOB_CAT_PAYMENT_METHOD',
    country: 'FOB_CAT_COUNTRY',
    notification_template: 'FOB_CAT_NOTIFICATION_TEMPLATE',
    notification_flow: 'FOB_CAT_NOTIFICATION_FLOW',
    manufacturer: 'FOB_CAT_MANUFACTURER',
    position_in_product: 'FOB_CAT_POSITION_IN_PRODUCT',
    market: 'FOB_CAT_MARKET',
    material_size: 'FOB_CAT_MATERIAL_SIZE',
    entry_status: 'FOB_CAT_ENTRY_STATUS',
    doc_status: 'FOB_CAT_DOC_STATUS',
    produce_status: 'FOB_CAT_PRODUCE_STATUS',
    inout_kind: 'FOB_CAT_INOUT_KIND',
    partner_contact: 'FOB_CAT_PARTNER_CONTACT',
    partner_payment_info: 'FOB_CAT_PARTNER_PAYMENT_INFO',
    contract_status: 'FOB_CAT_CONTRACT_STATUS',
    material_accountant: 'FOB_CAT_MATERIAL_ACCOUNTANT',
    bom_doc_type: 'FOB_CAT_BOM_DOC_TYPE',
    master_file_type: 'FOB_CAT_MASTER_FILE_TYPE',
    product_type: 'FOB_CAT_PRODUCT_TYPE',
    semi_product_type: 'FOB_CAT_SEMI_PRODUCT_TYPE',
    group_article_type: 'FOB_CAT_GROUP_ARTICLE_TYPE',

    sale_orders: 'FOB_SO',
    bom: 'FOB_BOM',
};

export const LIST_MODULE = [
    'FOB_Article',
    'FOB_Articletype',
    'FOB_Bomdoc',
    'FOB_BomDocType',
    'FOB_BomMaterialNorm',
    'FOB_BomSetColor',
    'FOB_BomUseMaterial',
    'FOB_Brand',
    'FOB_Color',
    'FOB_ContractStatus',
    'FOB_CostSheetTemplate',
    'FOB_Country',
    'FOB_Currency',
    'FOB_Customer',
    'FOB_CustomerGroup',
    'FOB_CustomerType',
    'FOB_Department',
    'FOB_DocStatus',
    'FOB_Employee',
    'FOB_EntryStatus',
    'FOB_Fit',
    'FOB_GroupArticleType',
    'FOB_GroupPurchasingOrder',
    'FOB_HrPosition',
    'FOB_InoutKind',
    'FOB_Invoice',
    'FOB_InvoiceContractType',
    'FOB_InvoiceStatus',
    'FOB_InvoiceTerm',
    'FOB_InvoiceType',
    'FOB_Lot',
    'FOB_Manufacturer',
    'FOB_Market',
    'FOB_MasterFile',
    'FOB_MasterFileArticlePriceGroup',
    'FOB_MasterFileDetailArticle',
    'FOB_MasterFileDetailOtherCost',
    'FOB_MasterFileOtherCostPriceGroup',
    'FOB_MasterFilePriceGroup',
    'FOB_MasterFileType',
    'FOB_MasterFileVersion',
    'FOB_Material',
    'FOB_MaterialAccountant',
    'FOB_MaterialCategory',
    'FOB_MaterialSize',
    'FOB_NotificationFlow',
    'FOB_NotificationTemplate',
    'FOB_Order',
    'FOB_OrderProduct',
    'FOB_OtherCost',
    'FOB_OtherCostGroup',
    'FOB_OtherCostType',
    'FOB_Package',
    'FOB_PackingList',
    'FOB_PaymentMethod',
    'FOB_PositionInProduct',
    'FOB_PriceGroupOrderProduct',
    'FOB_ProcedureStatus',
    'FOB_Product',
    'FOB_ProductGroup',
    'FOB_ProductType',
    'FOB_PurchaseGroup',
    'FOB_SaleGroup',
    'FOB_SaleorderGroup',
    'FOB_Season',
    'FOB_SemiProductType',
    'FOB_SettingTemplate',
    'FOB_Size',
    'FOB_StatusEntry',
    'FOB_Stuff',
    'FOB_Style',
    'FOB_StyleDocument',
    'FOB_StylePriceGroup',
    'FOB_StyleStatus',
    'FOB_Supplier',
    'FOB_Unit',
    'FOB_UnitConvert',
    'FOB_WashType',
    'FOB_BOM',
    'FOB_CompanyProfile',
    'FOB_CompanyProfileBank',
    'FOB_Language',
    'FOB_PartnerContact',
    'FOB_PartnerPaymentInfo',
    'FOB_PartnerType',
    'FOB_ProduceStatus',
    'FOB_StyleDocumentType',
];

export const COLUMN_WIDTH_CONFIG = {
    double_click_expand_width: 300,
    min_width: 100,
};

export const SYSTEM_CODE = 'FOB';
