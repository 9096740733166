import React, { createContext, useState, useContext } from 'react';

const DecimalContext = createContext();

export const useDecimal = () => useContext(DecimalContext);

export const DecimalProvider = ({ children }) => {
  const [decimalPlaces, setDecimalPlaces] = useState(localStorage.getItem('decimal')||2);

  return (
    <DecimalContext.Provider value={{ decimalPlaces, setDecimalPlaces }}>
      {children}
    </DecimalContext.Provider>
  );
};