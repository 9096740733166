import React from 'react';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const RoutesFob = [
    /*-------------Invoice----------------*/
    {
        key: 'fob',
        path: `${APP_PREFIX_PATH}/invoices/invoice/`,
        component: React.lazy(() => import('views/fob/invoices/invoice/')),
    },
    {
        key: 'fob.invoices',
        path: `${APP_PREFIX_PATH}/invoices/invoice/`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_list')),
    },
    {
        key: 'fob.invoices.invoice_list',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_list`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_list')),
    },
    {
        key: 'fob.invoices.invoice_add',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_add`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_add')),
    },
    {
        key: 'fob.invoices.invoice_edit',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_edit/:id`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_edit')),
    },
    {
        key: 'fob.invoices.invoice_masscopy',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_masscopy`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_masscopy')),
    },
    {
        key: 'fob.invoices.invoice_export',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_export`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_export')),
    },
    {
        key: 'fob.invoices.invoice_import',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_import`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_import')),
    },
    /*-------------Master-file----------------*/
    {
        key: 'fob',
        path: `${APP_PREFIX_PATH}/invoices/master_file/`,
        component: React.lazy(() => import('views/fob/invoices/master_file/')),
    },
    {
        key: 'fob.invoices',
        path: `${APP_PREFIX_PATH}/invoices/master_file/`,
        component: React.lazy(() => import('views/fob/invoices/master_file/master_file_list')),
    },
    {
        key: 'fob.invoices.master_file_list',
        path: `${APP_PREFIX_PATH}/invoices/master_file/master_file_list`,
        component: React.lazy(() => import('views/fob/invoices/master_file/master_file_list')),
    },
    {
        key: 'fob.invoices.master_file_add',
        path: `${APP_PREFIX_PATH}/invoices/master_file/master_file_add`,
        component: React.lazy(() => import('views/fob/invoices/master_file/master_file_add')),
    },
    {
        key: 'fob.invoices.master_file_edit',
        path: `${APP_PREFIX_PATH}/invoices/master_file/master_file_edit/:id`,
        component: React.lazy(() => import('views/fob/invoices/master_file/master_file_edit')),
    },
    {
        key: 'fob.invoices.master_file_masscopy',
        path: `${APP_PREFIX_PATH}/invoices/master_file/master_file_masscopy`,
        component: React.lazy(() => import('views/fob/invoices/master_file/master_file_masscopy')),
    },
    {
        key: 'fob.invoices.master_file_export',
        path: `${APP_PREFIX_PATH}/invoices/master_file/master_file_export`,
        component: React.lazy(() => import('views/fob/invoices/master_file/master_file_export')),
    },
    {
        key: 'fob.invoices.master_file_import',
        path: `${APP_PREFIX_PATH}/invoices/master_file/master_file_import`,
        component: React.lazy(() => import('views/fob/invoices/master_file/master_file_import')),
    },
    /*-------------Master_file_approval----------------*/
    {
        key: 'fob',
        path: `${APP_PREFIX_PATH}/invoices/master_file_approval/`,
        component: React.lazy(() => import('views/fob/invoices/master_file_approval/')),
    },
    {
        key: 'fob.invoices',
        path: `${APP_PREFIX_PATH}/invoices/master_file_approval/`,
        component: React.lazy(() => import('views/fob/invoices/master_file_approval/master_file_approval_list')),
    },
    {
        key: 'fob.invoices.master_file_approval_list',
        path: `${APP_PREFIX_PATH}/invoices/master_file_approval/master_file_approval_list`,
        component: React.lazy(() => import('views/fob/invoices/master_file_approval/master_file_approval_list')),
    },
    {
        key: 'fob.invoices.master_file_approval_add',
        path: `${APP_PREFIX_PATH}/invoices/master_file_approval/master_file_approval_add`,
        component: React.lazy(() => import('views/fob/invoices/master_file_approval/master_file_approval_add')),
    },
    {
        key: 'fob.invoices.master_file_approval_edit',
        path: `${APP_PREFIX_PATH}/invoices/master_file_approval/master_file_approval_edit/:id`,
        component: React.lazy(() => import('views/fob/invoices/master_file_approval/master_file_approval_edit')),
    },
    {
        key: 'fob.invoices.master_file_approval_masscopy',
        path: `${APP_PREFIX_PATH}/invoices/master_file_approval/master_file_approval_masscopy`,
        component: React.lazy(() => import('views/fob/invoices/master_file_approval/master_file_approval_masscopy')),
    },
    {
        key: 'fob.invoices.master_file_approval_export',
        path: `${APP_PREFIX_PATH}/invoices/master_file_approval/master_file_approval_export`,
        component: React.lazy(() => import('views/fob/invoices/master_file_approval/master_file_approval_export')),
    },
    {
        key: 'fob.invoices.master_file_approval_import',
        path: `${APP_PREFIX_PATH}/invoices/master_file_approval/master_file_approval_import`,
        component: React.lazy(() => import('views/fob/invoices/master_file_approval/master_file_approval_import')),
    },
    /*-------------Master_file_version----------------*/
    {
        key: 'fob',
        path: `${APP_PREFIX_PATH}/invoices/master_file_version/`,
        component: React.lazy(() => import('views/fob/invoices/master_file_version/')),
    },
    {
        key: 'fob.invoices',
        path: `${APP_PREFIX_PATH}/invoices/master_file_version/`,
        component: React.lazy(() => import('views/fob/invoices/master_file_version/master_file_version_list')),
    },
    {
        key: 'fob.invoices.master_file_version_list',
        path: `${APP_PREFIX_PATH}/invoices/master_file_version/master_file_version_list`,
        component: React.lazy(() => import('views/fob/invoices/master_file_version/master_file_version_list')),
    },
    {
        key: 'fob.invoices.master_file_version_add',
        path: `${APP_PREFIX_PATH}/invoices/master_file_version/master_file_version_add`,
        component: React.lazy(() => import('views/fob/invoices/master_file_version/master_file_version_add')),
    },
    {
        key: 'fob.invoices.master_file_version_edit',
        path: `${APP_PREFIX_PATH}/invoices/master_file_version/master_file_version_edit/:id`,
        component: React.lazy(() => import('views/fob/invoices/master_file_version/master_file_version_edit')),
    },
    {
        key: 'fob.invoices.master_file_version_masscopy',
        path: `${APP_PREFIX_PATH}/invoices/master_file_version/master_file_version_masscopy`,
        component: React.lazy(() => import('views/fob/invoices/master_file_version/master_file_version_masscopy')),
    },
    {
        key: 'fob.invoices.master_file_version_export',
        path: `${APP_PREFIX_PATH}/invoices/master_file_version/master_file_version_export`,
        component: React.lazy(() => import('views/fob/invoices/master_file_version/master_file_version_export')),
    },
    {
        key: 'fob.invoices.master_file_version_import',
        path: `${APP_PREFIX_PATH}/invoices/master_file_version/master_file_version_import`,
        component: React.lazy(() => import('views/fob/invoices/master_file_version/master_file_version_import')),
    },
];
export default RoutesFob;
