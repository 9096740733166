import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth/authentication/login')),
    },
    {
        key: 'sso',
        path: `${AUTH_PREFIX_PATH}/sso`,
        component: React.lazy(() => import('views/auth/authentication/sso')),
    },
    // {
    //     key: 'login-1',
    //     path: `${AUTH_PREFIX_PATH}/login-1`,
    //     component: React.lazy(() => import('views/auth/authentication/login-1')),
    // },
    // {
    //     key: 'register-1',
    //     path: `${AUTH_PREFIX_PATH}/register-1`,
    //     component: React.lazy(() => import('views/auth/authentication/register-1')),
    // },
    // {
    //     key: 'forgot-password',
    //     path: `${AUTH_PREFIX_PATH}/forgot-password`,
    //     component: React.lazy(() => import('views/auth/authentication/forgot-password')),
    // },
    // {
    //     key: 'error-page-1',
    //     path: `${AUTH_PREFIX_PATH}/error-page-1`,
    //     component: React.lazy(() => import('views/auth/errors/error-page-1')),
    // },
    // {
    //     key: 'error-page-2',
    //     path: `${AUTH_PREFIX_PATH}/error-page-2`,
    //     component: React.lazy(() => import('views/auth/errors/error-page-2')),
    // },
];

export const protectedRoutes = [
    {
        key: 'erp-fob',
        path: `fob`,
        component: React.lazy(() => import('app_modules/erp-fob')),
    },
    {
        key: 'erp-hrm',
        path: `hrm`,
        component: React.lazy(() => import('app_modules/erp-hrm')),
    },
    {
        key: 'erp-fin',
        path: `fin`,
        component: React.lazy(() => import('app_modules/erp-fin')),
    },
    {
        key: 'development',
        path: 'dev',
        component: React.lazy(() => import('views/development')),
    },
    {
        key: 'categories',
        path: 'categories',
        component: React.lazy(() => import('views/categories')),
    },
    {
        key: 'cache-notification',
        path: 'stored_event/list',
        component: React.lazy(() => import('views/storedEvent')),
    },
];
