import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTH_ENDPOINT_URL, AUTHENTICATED_ENTRY } from 'configs/AppConfig';

const PublicRoute = () => {
    let { token } = useSelector((state) => state.auth);
    let location = useLocation();
    console.log(location.pathname);

    if (location.pathname === '/auth/sso') {
        return <Outlet />;
    }

    if (!token) {
        window.location.href = `${AUTH_ENDPOINT_URL}app/sso?redirect=${window.location.origin}`;
    }

    return <Navigate to={AUTHENTICATED_ENTRY} />;
};

export default PublicRoute;
