import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { 
	AUTH_ENDPOINT_URL,
} from 'configs/AppConfig'

const ProtectedRoute = () => {
	
	const { token } = useSelector(state => state.auth)

	if (!token) {
		window.location.href = `${AUTH_ENDPOINT_URL}app/sso?redirect=${window.location.href}`
		// return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	}

	return <Outlet />
}

export default ProtectedRoute