import { USER_KEY } from "constants/AuthConstant";

class ManageLocalStorage {
    // Cập nhật một mục trong Local Storage
    static update(moduleName, keyName, value) {
        let objectFromStorage = JSON.parse(localStorage.getItem(moduleName));

        let tmpArray = [];

        if (
            objectFromStorage !== null &&
            objectFromStorage[keyName] !== undefined &&
            objectFromStorage[keyName] !== null
        )
            tmpArray = objectFromStorage[keyName].filter((e) => e !== value);

        tmpArray.push(value);

        objectFromStorage[keyName] = tmpArray;

        localStorage.setItem(moduleName, JSON.stringify(objectFromStorage));

        return tmpArray;
    }

    // Cập nhật lấy nội dung một mục trong Local Storage
    static get(moduleName, keyName) {
        let objectFromStorage = JSON.parse(localStorage.getItem(moduleName));

        if (
            objectFromStorage == null ||
            objectFromStorage[keyName] === undefined ||
            objectFromStorage[keyName] === null
        )
            return null;
        else return objectFromStorage[keyName];
    }

    // Cập nhật thiết lập nội dung một mục trong Local Storage
    static set(moduleName, keyName, arrayValue) {
        let objectFromStorage = JSON.parse(localStorage.getItem(moduleName));

        if (objectFromStorage == null) objectFromStorage = {};

        objectFromStorage[keyName] = arrayValue;

        localStorage.setItem(moduleName, JSON.stringify(objectFromStorage));
    }

    //Xóa thông tin tab đang được lưu trong Local Storage
    static delete(moduleName, keyName, value) {
        let objectFromStorage = JSON.parse(localStorage.getItem(moduleName));

        if (
            objectFromStorage == null ||
            objectFromStorage[keyName] === undefined ||
            objectFromStorage[keyName] === null
        )
            objectFromStorage = {};

        let tmpArray = [];
        if (objectFromStorage[keyName] instanceof Array) {
            tmpArray = objectFromStorage[keyName].filter((e) => e.info.tabId !== value);
        }

        objectFromStorage[keyName] = tmpArray;

        localStorage.setItem(moduleName, JSON.stringify(objectFromStorage));

        return objectFromStorage[keyName];
    }
    //Xóa các module của phân hệ FOB đang được lưu trong Local Storage 
    static deleteModule(moduleName) {
        let objectFromStorage = JSON.parse(localStorage.getItem(moduleName));
        if (  objectFromStorage !== null ) localStorage.removeItem(moduleName);
        
    }
    // Kiểm tra quyền của đối tượng
    static check_role(action_code) {
        let user_info = JSON.parse(localStorage.getItem(USER_KEY));
        return user_info?.permissions?.filter(x => x === action_code).length > 0;
    }    
}
export default ManageLocalStorage;
