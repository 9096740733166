import React from 'react';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const RoutesHrm = [
    {
        key: 'fob',
        path: `${APP_PREFIX_PATH}/invoices`,
        component: React.lazy(() => import('views/fob/invoices')),
    },
    {
        key: 'fob.invoices',
        path: `${APP_PREFIX_PATH}/invoices/`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_list')),
    },
    {
        key: 'fob.invoices.invoice_list',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_list`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_list')),
    },
    {
        key: 'fob.invoices.invoice_add',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_add`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_add')),
    },
    {
        key: 'fob.invoices.invoice_edit',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_edit/:id`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_edit')),
    },
    {
        key: 'fob.invoices.invoice_masscopy',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_masscopy`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_masscopy')),
    },
    {
        key: 'fob.invoices.invoice_export',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_export`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_export')),
    },
    {
        key: 'fob.invoices.invoice_import',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_import`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_import')),
    },

    /*-------------hrm_department_type----------------*/
    {
        key: 'apps.hrm_department_type',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_type`,
        component: React.lazy(() => import('views/hrm/hrm_department_type')),
    },
    {
        key: 'apps.hrm_department_type.hrm_department_type_list',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_type/hrm_department_type_list`,
        component: React.lazy(() => import('views/hrm/hrm_department_type/hrm_department_type_list')),
    },
    {
        key: 'apps.hrm_department_type.hrm_department_type_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_type/hrm_department_type_add`,
        component: React.lazy(() => import('views/hrm/hrm_department_type/hrm_department_type_add')),
    },
    {
        key: 'apps.hrm_department_type.hrm_department_type_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_type/hrm_department_type_add/:id`,
        component: React.lazy(() => import('views/hrm/hrm_department_type/hrm_department_type_add')),
    },
    {
        key: 'apps.hrm_department_type.hrm_department_type_edit',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_type/hrm_department_type_edit/:id`,
        component: React.lazy(() => import('views/hrm/hrm_department_type/hrm_department_type_edit')),
    },
    {
        key: 'apps.hrm_department_type.hrm_department_type_detail',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_type/hrm_department_type_detail/:id`,
        component: React.lazy(() => import('views/hrm/hrm_department_type/hrm_department_type_detail')),
    },
    {
        key: 'apps.hrm_department_type.hrm_department_type_masscopy',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_type/hrm_department_type_masscopy`,
        component: React.lazy(() => import('views/hrm/hrm_department_type/hrm_department_type_masscopy')),
    },
    {
        key: 'apps.hrm_department_type.hrm_department_type_export',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_type/hrm_department_type_export`,
        component: React.lazy(() => import('views/hrm/hrm_department_type/hrm_department_type_export')),
    },
    {
        key: 'apps.hrm_department_type.hrm_department_type_export_one',
        path: `${APP_PREFIX_PATH}/hrm_department_type/hrm_department_type_export_one`,
        component: React.lazy(() => import('views/hrm/hrm_department_type/hrm_department_type_export_one')),
    },
    {
        key: 'apps.hrm_department_type.hrm_department_type_import',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_type/hrm_department_type_import`,
        component: React.lazy(() => import('views/hrm/hrm_department_type/hrm_department_type_import')),
    },
    /*-------------End of hrm_department_type----------------*/

    /*-------------hrm_type_of_department----------------*/
    {
        key: 'apps.hrm_type_of_department',
        path: `${APP_PREFIX_PATH}/hrm/hrm_type_of_department`,
        component: React.lazy(() => import('views/hrm/hrm_type_of_department')),
    },
    {
        key: 'apps.hrm_type_of_department.hrm_type_of_department_list',
        path: `${APP_PREFIX_PATH}/hrm/hrm_type_of_department/hrm_type_of_department_list`,
        component: React.lazy(() => import('views/hrm/hrm_type_of_department/hrm_type_of_department_list')),
    },
    {
        key: 'apps.hrm_type_of_department.hrm_type_of_department_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_type_of_department/hrm_type_of_department_add`,
        component: React.lazy(() => import('views/hrm/hrm_type_of_department/hrm_type_of_department_add')),
    },
    {
        key: 'apps.hrm_type_of_department.hrm_type_of_department_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_type_of_department/hrm_type_of_department_add/:id`,
        component: React.lazy(() => import('views/hrm/hrm_type_of_department/hrm_type_of_department_add')),
    },
    {
        key: 'apps.hrm_type_of_department.hrm_type_of_department_edit',
        path: `${APP_PREFIX_PATH}/hrm/hrm_type_of_department/hrm_type_of_department_edit/:id`,
        component: React.lazy(() => import('views/hrm/hrm_type_of_department/hrm_type_of_department_edit')),
    },
    {
        key: 'apps.hrm_type_of_department.hrm_type_of_department_detail',
        path: `${APP_PREFIX_PATH}/hrm/hrm_type_of_department/hrm_type_of_department_detail/:id`,
        component: React.lazy(() => import('views/hrm/hrm_type_of_department/hrm_type_of_department_detail')),
    },
    {
        key: 'apps.hrm_type_of_department.hrm_type_of_department_masscopy',
        path: `${APP_PREFIX_PATH}/hrm/hrm_type_of_department/hrm_type_of_department_masscopy`,
        component: React.lazy(() => import('views/hrm/hrm_type_of_department/hrm_type_of_department_masscopy')),
    },
    {
        key: 'apps.hrm_type_of_department.hrm_type_of_department_export',
        path: `${APP_PREFIX_PATH}/hrm/hrm_type_of_department/hrm_type_of_department_export`,
        component: React.lazy(() => import('views/hrm/hrm_type_of_department/hrm_type_of_department_export')),
    },
    {
        key: 'apps.hrm_type_of_department.hrm_type_of_department_export_one',
        path: `${APP_PREFIX_PATH}/hrm_type_of_department/hrm_type_of_department_export_one`,
        component: React.lazy(() => import('views/hrm/hrm_type_of_department/hrm_type_of_department_export_one')),
    },
    {
        key: 'apps.hrm_type_of_department.hrm_type_of_department_import',
        path: `${APP_PREFIX_PATH}/hrm/hrm_type_of_department/hrm_type_of_department_import`,
        component: React.lazy(() => import('views/hrm/hrm_type_of_department/hrm_type_of_department_import')),
    },
    /*-------------End of hrm_type_of_department----------------*/

    /*-------------hrm_department----------------*/
    {
        key: 'apps.hrm_department',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department`,
        component: React.lazy(() => import('views/hrm/hrm_department')),
    },
    {
        key: 'apps.hrm_department.hrm_department_list',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department/hrm_department_list`,
        component: React.lazy(() => import('views/hrm/hrm_department/hrm_department_list')),
    },
    {
        key: 'apps.hrm_department.hrm_department_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department/hrm_department_add`,
        component: React.lazy(() => import('views/hrm/hrm_department/hrm_department_add')),
    },
    {
        key: 'apps.hrm_department.hrm_department_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department/hrm_department_add/:id`,
        component: React.lazy(() => import('views/hrm/hrm_department/hrm_department_add')),
    },
    {
        key: 'apps.hrm_department.hrm_department_edit',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department/hrm_department_edit/:id`,
        component: React.lazy(() => import('views/hrm/hrm_department/hrm_department_edit')),
    },
    {
        key: 'apps.hrm_department.hrm_department_detail',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department/hrm_department_detail/:id`,
        component: React.lazy(() => import('views/hrm/hrm_department/hrm_department_detail')),
    },
    {
        key: 'apps.hrm_department.hrm_department_masscopy',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department/hrm_department_masscopy`,
        component: React.lazy(() => import('views/hrm/hrm_department/hrm_department_masscopy')),
    },
    {
        key: 'apps.hrm_department.hrm_department_export',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department/hrm_department_export`,
        component: React.lazy(() => import('views/hrm/hrm_department/hrm_department_export')),
    },
    {
        key: 'apps.hrm_department.hrm_department_export_one',
        path: `${APP_PREFIX_PATH}/hrm_department/hrm_department_export_one`,
        component: React.lazy(() => import('views/hrm/hrm_department/hrm_department_export_one')),
    },
    {
        key: 'apps.hrm_department.hrm_department_import',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department/hrm_department_import`,
        component: React.lazy(() => import('views/hrm/hrm_department/hrm_department_import')),
    },
    /*-------------End of hrm_department----------------*/

    /*-------------hrm_position_type----------------*/
    {
        key: 'apps.hrm_position_type',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position_type`,
        component: React.lazy(() => import('views/hrm/hrm_position_type')),
    },
    {
        key: 'apps.hrm_position_type.hrm_position_type_list',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position_type/hrm_position_type_list`,
        component: React.lazy(() => import('views/hrm/hrm_position_type/hrm_position_type_list')),
    },
    {
        key: 'apps.hrm_position_type.hrm_position_type_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position_type/hrm_position_type_add`,
        component: React.lazy(() => import('views/hrm/hrm_position_type/hrm_position_type_add')),
    },
    {
        key: 'apps.hrm_position_type.hrm_position_type_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position_type/hrm_position_type_add/:id`,
        component: React.lazy(() => import('views/hrm/hrm_position_type/hrm_position_type_add')),
    },
    {
        key: 'apps.hrm_position_type.hrm_position_type_edit',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position_type/hrm_position_type_edit/:id`,
        component: React.lazy(() => import('views/hrm/hrm_position_type/hrm_position_type_edit')),
    },
    {
        key: 'apps.hrm_position_type.hrm_position_type_detail',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position_type/hrm_position_type_detail/:id`,
        component: React.lazy(() => import('views/hrm/hrm_position_type/hrm_position_type_detail')),
    },
    {
        key: 'apps.hrm_position_type.hrm_position_type_masscopy',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position_type/hrm_position_type_masscopy`,
        component: React.lazy(() => import('views/hrm/hrm_position_type/hrm_position_type_masscopy')),
    },
    {
        key: 'apps.hrm_position_type.hrm_position_type_export',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position_type/hrm_position_type_export`,
        component: React.lazy(() => import('views/hrm/hrm_position_type/hrm_position_type_export')),
    },
    {
        key: 'apps.hrm_position_type.hrm_position_type_export_one',
        path: `${APP_PREFIX_PATH}/hrm_position_type/hrm_position_type_export_one`,
        component: React.lazy(() => import('views/hrm/hrm_position_type/hrm_position_type_export_one')),
    },
    {
        key: 'apps.hrm_position_type.hrm_position_type_import',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position_type/hrm_position_type_import`,
        component: React.lazy(() => import('views/hrm/hrm_position_type/hrm_position_type_import')),
    },
    /*-------------End of hrm_position_type----------------*/

    /*-------------hrm_position----------------*/
    {
        key: 'apps.hrm_position',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position`,
        component: React.lazy(() => import('views/hrm/hrm_position')),
    },
    {
        key: 'apps.hrm_position.hrm_position_list',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position/hrm_position_list`,
        component: React.lazy(() => import('views/hrm/hrm_position/hrm_position_list')),
    },
    {
        key: 'apps.hrm_position.hrm_position_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position/hrm_position_add`,
        component: React.lazy(() => import('views/hrm/hrm_position/hrm_position_add')),
    },
    {
        key: 'apps.hrm_position.hrm_position_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position/hrm_position_add/:id`,
        component: React.lazy(() => import('views/hrm/hrm_position/hrm_position_add')),
    },
    {
        key: 'apps.hrm_position.hrm_position_edit',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position/hrm_position_edit/:id`,
        component: React.lazy(() => import('views/hrm/hrm_position/hrm_position_edit')),
    },
    {
        key: 'apps.hrm_position.hrm_position_detail',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position/hrm_position_detail/:id`,
        component: React.lazy(() => import('views/hrm/hrm_position/hrm_position_detail')),
    },
    {
        key: 'apps.hrm_position.hrm_position_masscopy',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position/hrm_position_masscopy`,
        component: React.lazy(() => import('views/hrm/hrm_position/hrm_position_masscopy')),
    },
    {
        key: 'apps.hrm_position.hrm_position_export',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position/hrm_position_export`,
        component: React.lazy(() => import('views/hrm/hrm_position/hrm_position_export')),
    },
    {
        key: 'apps.hrm_position.hrm_position_export_one',
        path: `${APP_PREFIX_PATH}/hrm_position/hrm_position_export_one`,
        component: React.lazy(() => import('views/hrm/hrm_position/hrm_position_export_one')),
    },
    {
        key: 'apps.hrm_position.hrm_position_import',
        path: `${APP_PREFIX_PATH}/hrm/hrm_position/hrm_position_import`,
        component: React.lazy(() => import('views/hrm/hrm_position/hrm_position_import')),
    },
    /*-------------End of hrm_position----------------*/

    /*-------------hrm_department_position----------------*/
    {
        key: 'apps.hrm_department_position',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_position`,
        component: React.lazy(() => import('views/hrm/hrm_department_position')),
    },
    {
        key: 'apps.hrm_department_position.hrm_department_position_list',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_position/hrm_department_position_list`,
        component: React.lazy(() => import('views/hrm/hrm_department_position/hrm_department_position_list')),
    },
    {
        key: 'apps.hrm_department_position.hrm_department_position_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_position/hrm_department_position_add`,
        component: React.lazy(() => import('views/hrm/hrm_department_position/hrm_department_position_add')),
    },
    {
        key: 'apps.hrm_department_position.hrm_department_position_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_position/hrm_department_position_add/:id`,
        component: React.lazy(() => import('views/hrm/hrm_department_position/hrm_department_position_add')),
    },
    {
        key: 'apps.hrm_department_position.hrm_department_position_edit',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_position/hrm_department_position_edit/:id`,
        component: React.lazy(() => import('views/hrm/hrm_department_position/hrm_department_position_edit')),
    },
    {
        key: 'apps.hrm_department_position.hrm_department_position_detail',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_position/hrm_department_position_detail/:id`,
        component: React.lazy(() => import('views/hrm/hrm_department_position/hrm_department_position_detail')),
    },
    {
        key: 'apps.hrm_department_position.hrm_department_position_masscopy',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_position/hrm_department_position_masscopy`,
        component: React.lazy(() => import('views/hrm/hrm_department_position/hrm_department_position_masscopy')),
    },
    {
        key: 'apps.hrm_department_position.hrm_department_position_export',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_position/hrm_department_position_export`,
        component: React.lazy(() => import('views/hrm/hrm_department_position/hrm_department_position_export')),
    },
    {
        key: 'apps.hrm_department_position.hrm_department_position_export_one',
        path: `${APP_PREFIX_PATH}/hrm_department_position/hrm_department_position_export_one`,
        component: React.lazy(() => import('views/hrm/hrm_department_position/hrm_department_position_export_one')),
    },
    {
        key: 'apps.hrm_department_position.hrm_department_position_import',
        path: `${APP_PREFIX_PATH}/hrm/hrm_department_position/hrm_department_position_import`,
        component: React.lazy(() => import('views/hrm/hrm_department_position/hrm_department_position_import')),
    },
    /*-------------End of hrm_department_position----------------*/

    /*-------------hrm_employee----------------*/
    {
        key: 'apps.hrm_employee',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee`,
        component: React.lazy(() => import('views/hrm/hrm_employee')),
    },
    {
        key: 'apps.hrm_employee.hrm_employee_list',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee/hrm_employee_list`,
        component: React.lazy(() => import('views/hrm/hrm_employee/hrm_employee_list')),
    },
    {
        key: 'apps.hrm_employee.hrm_employee_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee/hrm_employee_add`,
        component: React.lazy(() => import('views/hrm/hrm_employee/hrm_employee_add')),
    },
    {
        key: 'apps.hrm_employee.hrm_employee_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee/hrm_employee_add/:id`,
        component: React.lazy(() => import('views/hrm/hrm_employee/hrm_employee_add')),
    },
    {
        key: 'apps.hrm_employee.hrm_employee_edit',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee/hrm_employee_edit/:id`,
        component: React.lazy(() => import('views/hrm/hrm_employee/hrm_employee_edit')),
    },
    {
        key: 'apps.hrm_employee.hrm_employee_detail',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee/hrm_employee_detail/:id`,
        component: React.lazy(() => import('views/hrm/hrm_employee/hrm_employee_detail')),
    },
    {
        key: 'apps.hrm_employee.hrm_employee_masscopy',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee/hrm_employee_masscopy`,
        component: React.lazy(() => import('views/hrm/hrm_employee/hrm_employee_masscopy')),
    },
    {
        key: 'apps.hrm_employee.hrm_employee_export',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee/hrm_employee_export`,
        component: React.lazy(() => import('views/hrm/hrm_employee/hrm_employee_export')),
    },
    {
        key: 'apps.hrm_employee.hrm_employee_export_one',
        path: `${APP_PREFIX_PATH}/hrm_employee/hrm_employee_export_one`,
        component: React.lazy(() => import('views/hrm/hrm_employee/hrm_employee_export_one')),
    },
    {
        key: 'apps.hrm_employee.hrm_employee_import',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee/hrm_employee_import`,
        component: React.lazy(() => import('views/hrm/hrm_employee/hrm_employee_import')),
    },
    /*-------------End of hrm_employee----------------*/

    /*-------------hrm_employee_position----------------*/
    {
        key: 'apps.hrm_employee_position',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee_position`,
        component: React.lazy(() => import('views/hrm/hrm_employee_position')),
    },
    {
        key: 'apps.hrm_employee_position.hrm_employee_position_list',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee_position/hrm_employee_position_list`,
        component: React.lazy(() => import('views/hrm/hrm_employee_position/hrm_employee_position_list')),
    },
    {
        key: 'apps.hrm_employee_position.hrm_employee_position_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee_position/hrm_employee_position_add`,
        component: React.lazy(() => import('views/hrm/hrm_employee_position/hrm_employee_position_add')),
    },
    {
        key: 'apps.hrm_employee_position.hrm_employee_position_add',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee_position/hrm_employee_position_add/:id`,
        component: React.lazy(() => import('views/hrm/hrm_employee_position/hrm_employee_position_add')),
    },
    {
        key: 'apps.hrm_employee_position.hrm_employee_position_edit',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee_position/hrm_employee_position_edit/:id`,
        component: React.lazy(() => import('views/hrm/hrm_employee_position/hrm_employee_position_edit')),
    },
    {
        key: 'apps.hrm_employee_position.hrm_employee_position_detail',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee_position/hrm_employee_position_detail/:id`,
        component: React.lazy(() => import('views/hrm/hrm_employee_position/hrm_employee_position_detail')),
    },
    {
        key: 'apps.hrm_employee_position.hrm_employee_position_masscopy',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee_position/hrm_employee_position_masscopy`,
        component: React.lazy(() => import('views/hrm/hrm_employee_position/hrm_employee_position_masscopy')),
    },
    {
        key: 'apps.hrm_employee_position.hrm_employee_position_export',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee_position/hrm_employee_position_export`,
        component: React.lazy(() => import('views/hrm/hrm_employee_position/hrm_employee_position_export')),
    },
    {
        key: 'apps.hrm_employee_position.hrm_employee_position_export_one',
        path: `${APP_PREFIX_PATH}/hrm_employee_position/hrm_employee_position_export_one`,
        component: React.lazy(() => import('views/hrm/hrm_employee_position/hrm_employee_position_export_one')),
    },
    {
        key: 'apps.hrm_employee_position.hrm_employee_position_import',
        path: `${APP_PREFIX_PATH}/hrm/hrm_employee_position/hrm_employee_position_import`,
        component: React.lazy(() => import('views/hrm/hrm_employee_position/hrm_employee_position_import')),
    },
    /*-------------End of hrm_employee_position----------------*/

    /*-------------hrm_approval_subject----------------*/
    {
        key: 'apps.hrm_approval_subject',
        path: `/hrm/hrm_approval_subject`,
        component: React.lazy(() => import('views/hrm/hrm_approval_subject')),
    },
    {
        key: 'apps.hrm_approval_subject.hrm_approval_subject_list',
        path: `/hrm/hrm_approval_subject/hrm_approval_subject_list`,
        component: React.lazy(() => import('views/hrm/hrm_approval_subject/hrm_approval_subject_list')),
    },
    {
        key: 'apps.hrm_approval_subject.hrm_approval_subject_add',
        path: `/hrm/hrm_approval_subject/hrm_approval_subject_add`,
        component: React.lazy(() => import('views/hrm/hrm_approval_subject/hrm_approval_subject_add')),
    },
    {
        key: 'apps.hrm_approval_subject.hrm_approval_subject_add',
        path: `/hrm/hrm_approval_subject/hrm_approval_subject_add/:id`,
        component: React.lazy(() => import('views/hrm/hrm_approval_subject/hrm_approval_subject_add')),
    },
    {
        key: 'apps.hrm_approval_subject.hrm_approval_subject_edit',
        path: `/hrm/hrm_approval_subject/hrm_approval_subject_edit/:id`,
        component: React.lazy(() => import('views/hrm/hrm_approval_subject/hrm_approval_subject_edit')),
    },
    {
        key: 'apps.hrm_approval_subject.hrm_approval_subject_detail',
        path: `/hrm/hrm_approval_subject/hrm_approval_subject_detail/:id`,
        component: React.lazy(() => import('views/hrm/hrm_approval_subject/hrm_approval_subject_detail')),
    },
    {
        key: 'apps.hrm_approval_subject.hrm_approval_subject_masscopy',
        path: `/hrm/hrm_approval_subject/hrm_approval_subject_masscopy`,
        component: React.lazy(() => import('views/hrm/hrm_approval_subject/hrm_approval_subject_masscopy')),
    },
    {
        key: 'apps.hrm_approval_subject.hrm_approval_subject_export',
        path: `/hrm/hrm_approval_subject/hrm_approval_subject_export`,
        component: React.lazy(() => import('views/hrm/hrm_approval_subject/hrm_approval_subject_export')),
    },
    {
        key: 'apps.hrm_approval_subject.hrm_approval_subject_export_one',
        path: `/hrm/hrm_approval_subject/hrm_approval_subject_export_one`,
        component: React.lazy(() => import('views/hrm/hrm_approval_subject/hrm_approval_subject_export_one')),
    },
    {
        key: 'apps.hrm_approval_subject.hrm_approval_subject_import',
        path: `/hrm/hrm_approval_subject/hrm_approval_subject_import`,
        component: React.lazy(() => import('views/hrm/hrm_approval_subject/hrm_approval_subject_import')),
    },
    {
        key: 'apps.hrm_approval_subject.hrm_approval_subject_config',
        path: `/hrm/hrm_approval_subject/hrm_approval_subject_config/:id`,
        component: React.lazy(() => import('views/hrm/hrm_approval_subject/hrm_approval_subject_config')),
    },
    /*-------------End of hrm_approval_subject----------------*/
];
export default RoutesHrm;
