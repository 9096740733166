import {
    DashboardOutlined,
    CalculatorOutlined,
    SafetyOutlined,
    StopOutlined,
    PlusCircleOutlined,
    BookOutlined,
    FileUnknownOutlined,
    ProfileOutlined,
    CodeOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import { Children } from 'react';
import { SYSTEM_CODE } from 'configs/AppConfig';

const dashBoardNavTree = [
    {
        key: 'apps',
        // path: `${APP_PREFIX_PATH}/fob`,
        title: 'sidenav.apps',
        icon: DashboardOutlined,
        breadcrumb: false,
        isGroupTitle: true,
        submenu: [
            {
                key: 'fob',
                code: 'FOB-R',
                path: `${APP_PREFIX_PATH}/fob`,
                title: 'sidenav.apps.fob',
                icon: SafetyOutlined,
                breadcrumb: false,
                isGroupTitle: false,
                submenu: [],
            },
            {
                key: 'hrm',
                code: 'HRM-R',
                path: `${APP_PREFIX_PATH}/hrm`,
                title: 'sidenav.apps.hrm',
                icon: StopOutlined,
                breadcrumb: false,
                isGroupTitle: false,
                submenu: [],
            },
            // {
            //     key: 'fin',
            //     code: 'FIN-R',
            //     path: `${APP_PREFIX_PATH}/fin`,
            //     title: 'Tài chính',
            //     icon: StopOutlined,
            //     breadcrumb: false,
            //     isGroupTitle: false,
            //     submenu: [],
            // },
            {
                key: 'fin',
                //code: 'FIN-R',
                path: `${APP_PREFIX_PATH}/fin`,
                title: 'sidenav.apps.fin',
                icon: CalculatorOutlined,
                breadcrumb: false,
                isGroupTitle: false,
                submenu: [],
            },
        ],
    },
];

const docsNavTree = [
    {
        key: 'docs',
        path: `${APP_PREFIX_PATH}/docs`,
        title: 'sidenav.docs',
        icon: BookOutlined,
        breadcrumb: false,
        isGroupTitle: true,
        submenu: [
            {
                key: 'docs-documentation',
                path: `${APP_PREFIX_PATH}/docs/documentation`,
                title: 'sidenav.docs.documentation',
                icon: FileUnknownOutlined,
                breadcrumb: false,
                submenu: [],
            },
            {
                key: 'docs-changelog',
                path: `${APP_PREFIX_PATH}/docs/documentation/changelog`,
                title: 'sidenav.docs.changelog',
                icon: ProfileOutlined,
                breadcrumb: false,
                submenu: [],
            },
        ],
    },
];

const developmentNav = [
    {
        key: 'development',
        title: 'sidenav.dev',
        icon: BookOutlined,
        breadcrumb: false,
        isGroupTitle: true,
        code: 'DEV-R',
        submenu: [
            {
                key: 'dev-development-xml',
                code: 'DEV-R',
                path: `${APP_PREFIX_PATH}/dev/xml`,
                title: 'sidenav.dev.xml',
                icon: CodeOutlined,
                breadcrumb: false,
                submenu: [],
            },
            {
                key: 'dev-development-frontend',
                code: 'DEV-R',
                path: `${APP_PREFIX_PATH}/dev/frontend`,
                title: 'sidenav.dev.frontend',
                icon: CodeOutlined,
                breadcrumb: false,
                submenu: [],
            },
            // {
            //     key: 'dev-development-backend',
            //     // code: 'DEV-R',
            //     path: `${APP_PREFIX_PATH}/dev/backend`,
            //     title: 'sidenav.dev.backend',
            //     icon: CodeOutlined,
            //     breadcrumb: false,
            //     submenu: [],
            // },
        ],
    },
];

const generalCategoryNav = [
    {
        key: 'general_category',
        title: 'sidenav.general',
        icon: BookOutlined,
        breadcrumb: false,
        isGroupTitle: true,
        code: 'FOB-R',
        submenu: [
            {
                key: 'setting_template',
                code: 'FOB_CAT-SET_TEM-R',
                path: `${APP_PREFIX_PATH}/categories/setting_template`,
                title: 'sidenav.general.setting_template',
                icon: CodeOutlined,
                breadcrumb: false,
                submenu: [],
            },
        ],
    },
];

const navigationConfig = [...dashBoardNavTree, ...docsNavTree, ...developmentNav, ...generalCategoryNav];

export default navigationConfig;
