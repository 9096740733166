import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig';

export const APP_NAME = 'Nagaco';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const API_AUTH_URL = env.API_AUTH_URL;
export const AUTH_ENDPOINT_URL = env.AUTH_ENDPOINT_URL;
export const APP_PREFIX_PATH = '';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect';
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboard`;
export const UNAUTHENTICATED_ENTRY = '/login';

export const EDITOR_API_KEY = 'b5po5o74aat9p0538x0cd2b1y31zb6pjsg36z722xxa3nllf';

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: localStorage.getItem('i18nextLng') || 'vi',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#1e5f85',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: localStorage.getItem('theme') || 'light',
    direction: DIR_LTR,
    blankLayout: false,
};

export const NAGACO_MODE = {
    add: 'ADD',
    detail: 'DETAIL',
    edit: 'EDIT',
    export: 'EXPORT',
    export_one: 'EXPORT_ONE',
    import: 'IMPORT',
    list: 'LIST',
    masscopy: 'MASSCOPY',
    copy: 'COPY',
};

export const EXPORT_TYPE = {
    pdf: 'pdf',
    xml: 'xml',
    xlsx: 'xlsx',
    json: 'json',
    docx: 'docx',
    csv: 'csv',
};

export const EXPORT_VALUE = {
    pdf: '1',
    xml: '2',
    xlsx: '3',
    json: '4',
    docx: '5',
    csv: '6',
    export_with_template: 'export_with_template',
    export_new_template: 'export_new_template',
};

export const IMPORT_TYPE = {
    xlsx: 'xlsx',
    xml: 'xml',
    json: 'json',
    csv: 'csv',
};

export const IMPORT_VALUE = {
    xlsx: '1',
    xml: '2',
    json: '3',
    csv: '4',
};

export const FUNCTION_ID = {
    list: '01',
    edit: '02',
    add: '03',
    detail: '04',
    import: '05',
    export: '06',
    masscopy: '07',
    help: '08',
};

export const CATEGORY_PREFIX = {
    fob_size: 'FOB_CAT_SIZE',
    fob_fit: 'FOB_CAT_FIT',
    fob_stuff: 'FOB_CAT_STUFF',
    fob_product_group: 'FOB_CAT_PROD_GRP',
    fob_package: 'FOB_CAT_PACK',
    fob_supplier: 'FOB_CAT_SUPP',
    fob_article: 'FOB_CAT_ARTICLE',
    fob_customer_group: 'FOB_CAT_CUSTOMER_GROUP',
    fob_customer_type: 'FOB_CAT_CUSTOMER_TYPE',
    fob_customer: 'FOB_CAT_CUSTOMER',
    fob_brand: 'FOB_CAT_BRAND',
    fob_season: 'FOB_CAT_SEAS',
    fob_currency: 'FOB_CAT_CURRENCY',
    fob_cost_sheet_template: 'FOB_CAT_COST_SHEET_TEMPLATE',
    fob_other_cost_group: 'FOB_CAT_OTHER_COST_GROUP',
    fob_other_cost_type: 'FOB_CAT_OTHER_COST_TYPE',
    fob_other_cost: 'FOB_CAT_OTHER_COST',
    fob_color: 'FOB_CAT_COL',
    fob_wash_type: 'FOB_CAT_WASH_TYPE',
    fob_invoice_type: 'FOB_CAT_INVOICE_TYPE',
    fob_invoice_status: 'FOB_CAT_INVOICE_STATUS',
    fob_invoice_term: 'FOB_CAT_INVOICE_TERM',
    fob_invoice_contract_type: 'FOB_CAT_INVOICE_CONTRACT_TYPE',
    fob_payment_method: 'FOB_CAT_PAYMENT_METHOD',
    fob_country: 'FOB_CAT_COUNTRY',
    fob_notification_template: 'FOB_CAT_NOTIFICATION_TEMPLATE',
    fob_notification_flow: 'FOB_CAT_NOTIFICATION_FLOW',
    fob_manufacturer: 'FOB_CAT_MANUFACTURER',
    fob_position_in_product: 'FOB_CAT_POSITION_IN_PRODUCT',
    fob_market: 'FOB_CAT_MARKET',
    fob_material_size: 'FOB_CAT_MATERIAL_SIZE',
    fob_entry_status: 'FOB_CAT_ENTRY_STATUS',
    fob_doc_status: 'FOB_CAT_DOC_STATUS',
    fob_produce_status: 'FOB_CAT_PRODUCE_STATUS',
    fob_inout_kind: 'FOB_CAT_INOUT_KIND',
    fob_partner_contact: 'FOB_CAT_PARTNER_CONTACT',
    fob_partner_payment_info: 'FOB_CAT_PARTNER_PAYMENT_INFO',
    fob_contract_status: 'FOB_CAT_CONTRACT_STATUS',
    fob_material_accountant: 'FOB_CAT_MATERIAL_ACCOUNTANT',
    fob_bom_doc_type: 'FOB_CAT_BOM_DOC_TYPE',
    fob_master_file_type: 'FOB_CAT_MASTER_FILE_TYPE',
    fob_product_type: 'FOB_CAT_PRODUCT_TYPE',
    fob_semi_product_type: 'FOB_CAT_SEMI_PRODUCT_TYPE',
    fob_group_article_type: 'FOB_CAT_GROUP_ARTICLE_TYPE',

    article_by_supplier: 'FOB_CAT_ARTICLE',
    unit_convert_by_unit: 'FOB_CAT_UNIT_CONVERT',
    other_cost_calculation_by_other_cost: 'FOB_CAT_OTHER_COST_CALCULATION',

    sale_orders: 'FOB_SO',
    bom: 'FOB_BOM',
    purchase_material: 'FOB_PUR_MAT',
};

export const LIST_MODULE = [
    'FOB_FobArticle',
    'FOB_FobArticleType',
    'FOB_FobBomdoc',
    'FOB_FobBomDocType',
    'FOB_FobBomMaterialNorm',
    'FOB_FobBomSetColor',
    'FOB_FobBomUseMaterial',
    'FOB_FobBrand',
    'FOB_FobColor',
    'FOB_FobContractStatus',
    'FOB_FobCostSheetTemplate',
    'FOB_FobCountry',
    'FOB_FobCurrency',
    'FOB_FobCustomer',
    'FOB_FobCustomerGroup',
    'FOB_FobCustomerType',
    'FOB_FobDepartment',
    'FOB_FobDocStatus',
    'FOB_FobEmployee',
    'FOB_FobEntryStatus',
    'FOB_FobFit',
    'FOB_FobGroupArticleType',
    'FOB_FobGroupPurchasingOrder',
    'FOB_FobHrPosition',
    'FOB_FobInoutKind',
    'FOB_FobInvoice',
    'FOB_FobInvoiceContractType',
    'FOB_FobInvoiceStatus',
    'FOB_FobInvoiceTerm',
    'FOB_FobInvoiceType',
    'FOB_FobLot',
    'FOB_FobManufacturer',
    'FOB_FobMarket',
    'FOB_FobMasterFile',
    'FOB_FobMasterFileArticlePriceGroup',
    'FOB_FobMasterFileDetailArticle',
    'FOB_FobMasterFileDetailOtherCost',
    'FOB_FobMasterFileOtherCostPriceGroup',
    'FOB_FobMasterFilePriceGroup',
    'FOB_FobMasterFileType',
    'FOB_FobMasterFileVersion',
    'FOB_FobMaterial',
    'FOB_FobMaterialAccountant',
    'FOB_FobMaterialCategory',
    'FOB_FobMaterialSize',
    'FOB_FobNotificationFlow',
    'FOB_FobNotificationTemplate',
    'FOB_FobOrder',
    'FOB_FobOrderProduct',
    'FOB_FobOtherCost',
    'FOB_FobOtherCostGroup',
    'FOB_FobOtherCostType',
    'FOB_FobPackage',
    'FOB_FobPackingList',
    'FOB_FobPaymentMethod',
    'FOB_FobPositionInProduct',
    'FOB_FobPriceGroupOrderProduct',
    'FOB_FobProcedureStatus',
    'FOB_FobProduct',
    'FOB_FobProductGroup',
    'FOB_FobProductType',
    'FOB_FobPurchaseGroup',
    'FOB_FobSaleGroup',
    'FOB_FobSaleorderGroup',
    'FOB_FobSeason',
    'FOB_FobSemiProductType',
    'FOB_FobSettingTemplate',
    'FOB_FobSize',
    'FOB_FobStatusEntry',
    'FOB_FobStuff',
    'FOB_FobStyle',
    'FOB_FobStyleDocument',
    'FOB_FobStylePriceGroup',
    'FOB_FobStyleStatus',
    'FOB_FobSupplier',
    'FOB_FobUnit',
    'FOB_FobUnitConvert',
    'FOB_FobWashType',
    'FOB_FobBOM',
    'FOB_FobCompanyProfile',
    'FOB_FobCompanyProfileBank',
    'FOB_FobLanguage',
    'FOB_FobPartnerContact',
    'FOB_FobPartnerPaymentInfo',
    'FOB_FobPartnerType',
    'FOB_FobProduceStatus',
    'FOB_FobStyleDocumentType',
];

export const COLUMN_WIDTH_CONFIG = {
    double_click_expand_width: 250,
    normal_width: 'auto',
    min_width: 150,
};

export const SYSTEM_CODE = 'FOB';

export const DEFAULT_EXPORT_TEMPLATE =
    '<div style="font-family: Noto Serif CJK SC"><p>&nbsp;</p><table class="data-table non-editable" style="width:100%;text-align:center"><tbody><tr><td>Bảng dữ liệu</td></tr></tbody></table><p>&nbsp;</p></div>';
