import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';
import RoutesFob from './RoutesFob';
import RoutesFinance from './RoutesFinance';
import RoutesHrm from './RoutesHrm';

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth/authentication/login')),
    },
    {
        key: 'sso',
        path: `${AUTH_PREFIX_PATH}/sso`,
        component: React.lazy(() => import('views/auth/authentication/sso')),
    },
    // {
    //     key: 'login-1',
    //     path: `${AUTH_PREFIX_PATH}/login-1`,
    //     component: React.lazy(() => import('views/auth/authentication/login-1')),
    // },
    // {
    //     key: 'register-1',
    //     path: `${AUTH_PREFIX_PATH}/register-1`,
    //     component: React.lazy(() => import('views/auth/authentication/register-1')),
    // },
    // {
    //     key: 'forgot-password',
    //     path: `${AUTH_PREFIX_PATH}/forgot-password`,
    //     component: React.lazy(() => import('views/auth/authentication/forgot-password')),
    // },
    // {
    //     key: 'error-page-1',
    //     path: `${AUTH_PREFIX_PATH}/error-page-1`,
    //     component: React.lazy(() => import('views/auth/errors/error-page-1')),
    // },
    // {
    //     key: 'error-page-2',
    //     path: `${AUTH_PREFIX_PATH}/error-page-2`,
    //     component: React.lazy(() => import('views/auth/errors/error-page-2')),
    // },
];

export const protectedRoutes = [
    /*-------------Start of Fob----------------*/
    ...RoutesFob,
    /*-------------End of Fob----------------*/
    /*-------------Start of Finance----------------*/
    ...RoutesFinance,
    /*-------------End of Finance----------------*/
    /*-------------Start of Hrm----------------*/
    ...RoutesHrm,
    /*-------------End of Hrm----------------*/
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/fob/dashboards/sales')),
    },
    {
        key: 'dashboard.analytic',
        path: `${APP_PREFIX_PATH}/dashboards/analytic`,
        component: React.lazy(() => import('views/fob/dashboards/analytic')),
    },
    {
        key: 'dashboard.sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        component: React.lazy(() => import('views/fob/dashboards/sales')),
    },
    {
        key: 'apps',
        path: `${APP_PREFIX_PATH}/sale_orders`,
        component: React.lazy(() => import('views/fob/sale_orders')),
    },
    {
        key: 'apps.sale_orders',
        path: `${APP_PREFIX_PATH}/sale_orders/`,
        component: React.lazy(() => import('views/fob/sale_orders/sale_orders_list')),
    },
    {
        key: 'apps.sale_orders.sale_orders_list',
        path: `${APP_PREFIX_PATH}/sale_orders/sale_orders_list`,
        component: React.lazy(() => import('views/fob/sale_orders/sale_orders_list')),
    },
    {
        key: 'apps.sale_orders.sale_orders_add',
        path: `${APP_PREFIX_PATH}/sale_orders/sale_orders_add`,
        component: React.lazy(() => import('views/fob/sale_orders/sale_orders_add')),
    },
    {
        key: 'apps.sale_orders.sale_orders_edit',
        path: `${APP_PREFIX_PATH}/sale_orders/sale_orders_edit/:id`,
        component: React.lazy(() => import('views/fob/sale_orders/sale_orders_edit')),
    },
    {
        key: 'apps.sale_orders.sale_orders_copy',
        path: `${APP_PREFIX_PATH}/sale_orders/sale_orders_copy`,
        component: React.lazy(() => import('views/fob/sale_orders/sale_orders_copy')),
    },
    {
        key: 'apps.sale_orders.sale_orders_export',
        path: `${APP_PREFIX_PATH}/sale_orders/sale_orders_export`,
        component: React.lazy(() => import('views/fob/sale_orders/sale_orders_export')),
    },
    {
        key: 'apps.sale_orders.sale_orders_import',
        path: `${APP_PREFIX_PATH}/sale_orders/sale_orders_import`,
        component: React.lazy(() => import('views/fob/sale_orders/sale_orders_import')),
    },
    {
        key: 'apps.sale_orders.sale_orders_master_file',
        path: `${APP_PREFIX_PATH}/sale_orders/sale_orders_master_file/:id`,
        component: React.lazy(() => import('views/fob/sale_orders/sale_orders_master_file')),
    },
    {
        key: 'apps.sale_orders.sale_orders_approve_master_file',
        path: `${APP_PREFIX_PATH}/sale_orders/sale_orders_approve_master_file/:id`,
        component: React.lazy(() => import('views/fob/sale_orders/sale_orders_approve_master_file')),
    },
    {
        key: 'apps.notification',
        path: `${APP_PREFIX_PATH}/notification`,
        component: React.lazy(() => import('views/fob/notification')),
    },
    {
        key: 'apps.notification.notification-list',
        path: `${APP_PREFIX_PATH}/notification/notification-list`,
        component: React.lazy(() => import('views/fob/notification/notification-list')),
    },

    {
        key: 'apps.article_by_supplier',
        path: `${APP_PREFIX_PATH}/apps/article_by_supplier`,
        component: React.lazy(() => import('views/fob/apps/article_by_supplier')),
    },
    {
        key: 'apps.article_by_supplier.article_list',
        path: `${APP_PREFIX_PATH}/apps/article_by_supplier/article_list`,
        component: React.lazy(() => import('views/fob/apps/article_by_supplier/article_list')),
    },
    {
        key: 'apps.article_by_supplier',
        path: `${APP_PREFIX_PATH}/apps/article_by_supplier/`,
        component: React.lazy(() => import('views/fob/apps/article_by_supplier/article_list')),
    },
    {
        key: 'apps.article_by_supplier.article_add',
        path: `${APP_PREFIX_PATH}/apps/article_by_supplier/article_add`,
        component: React.lazy(() => import('views/fob/apps/article_by_supplier/article_add')),
    },
    {
        key: 'apps.article_by_supplier.article_add',
        path: `${APP_PREFIX_PATH}/apps/article_by_supplier/article_add/:id`,
        component: React.lazy(() => import('views/fob/apps/article_by_supplier/article_add')),
    },
    {
        key: 'apps.article_by_supplier.article_edit',
        path: `${APP_PREFIX_PATH}/apps/article_by_supplier/article_edit/:id`,
        component: React.lazy(() => import('views/fob/apps/article_by_supplier/article_edit')),
    },
    {
        key: 'apps.article_by_supplier.article_detail',
        path: `${APP_PREFIX_PATH}/apps/article_by_supplier/article_detail/:id`,
        component: React.lazy(() => import('views/fob/apps/article_by_supplier/article_detail')),
    },
    {
        key: 'apps.article_by_supplier.article_masscopy',
        path: `${APP_PREFIX_PATH}/apps/article_by_supplier/article_masscopy`,
        component: React.lazy(() => import('views/fob/apps/article_by_supplier/article_masscopy')),
    },
    {
        key: 'apps.article_by_supplier.article_export',
        path: `${APP_PREFIX_PATH}/apps/article_by_supplier/article_export`,
        component: React.lazy(() => import('views/fob/apps/article_by_supplier/article_export')),
    },
    {
        key: 'apps.article_by_supplier.article_import',
        path: `${APP_PREFIX_PATH}/apps/article_by_supplier/article_import`,
        component: React.lazy(() => import('views/fob/apps/article_by_supplier/article_import')),
    },
    {
        key: 'apps.article_by_supplier',
        path: `${APP_PREFIX_PATH}/categories/main_sub/article_by_supplier/`,
        component: React.lazy(() => import('views/fob/categories/main_sub/article_by_supplier')),
    },
    {
        key: 'apps.article_by_supplier.article_list',
        path: `${APP_PREFIX_PATH}/categories/main_sub/article_by_supplier/article_list`,
        component: React.lazy(() => import('views/fob/categories/main_sub/article_by_supplier/article_list')),
    },
    {
        key: 'apps.article_by_supplier',
        path: `${APP_PREFIX_PATH}/categories/main_sub/article_by_supplier/`,
        component: React.lazy(() => import('views/fob/categories/main_sub/article_by_supplier/article_list')),
    },
    {
        key: 'apps.article_by_supplier.article_add',
        path: `${APP_PREFIX_PATH}/categories/main_sub/article_by_supplier/article_add`,
        component: React.lazy(() => import('views/fob/categories/main_sub/article_by_supplier/article_add')),
    },
    {
        key: 'apps.article_by_supplier.article_add',
        path: `${APP_PREFIX_PATH}/categories/main_sub/article_by_supplier/article_add/:id`,
        component: React.lazy(() => import('views/fob/categories/main_sub/article_by_supplier/article_add')),
    },
    {
        key: 'apps.article_by_supplier.article_edit',
        path: `${APP_PREFIX_PATH}/categories/main_sub/article_by_supplier/article_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/main_sub/article_by_supplier/article_edit')),
    },
    {
        key: 'apps.article_by_supplier.article_detail',
        path: `${APP_PREFIX_PATH}/categories/main_sub/article_by_supplier/article_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/main_sub/article_by_supplier/article_detail')),
    },
    {
        key: 'apps.article_by_supplier.article_masscopy',
        path: `${APP_PREFIX_PATH}/categories/main_sub/article_by_supplier/article_masscopy`,
        component: React.lazy(() => import('views/fob/categories/main_sub/article_by_supplier/article_masscopy')),
    },
    {
        key: 'apps.article_by_supplier.article_export',
        path: `${APP_PREFIX_PATH}/categories/main_sub/article_by_supplier/article_export`,
        component: React.lazy(() => import('views/fob/categories/main_sub/article_by_supplier/article_export')),
    },
    {
        key: 'apps.article_by_supplier.article_import',
        path: `${APP_PREFIX_PATH}/categories/main_sub/article_by_supplier/article_import`,
        component: React.lazy(() => import('views/fob/categories/main_sub/article_by_supplier/article_import')),
    },

    {
        key: 'apps.unit_convert_by_unit',
        path: `${APP_PREFIX_PATH}/categories/main_sub/unit_convert_by_unit`,
        component: React.lazy(() => import('views/fob/categories/main_sub/unit_convert_by_unit')),
    },
    {
        key: 'apps.unit_convert_by_unit.unit_convert_list',
        path: `${APP_PREFIX_PATH}/categories/main_sub/unit_convert_by_unit/unit_convert_list`,
        component: React.lazy(() => import('views/fob/categories/main_sub/unit_convert_by_unit/unit_convert_list')),
    },
    {
        key: 'apps.unit_convert_by_unit',
        path: `${APP_PREFIX_PATH}/categories/main_sub/unit_convert_by_unit/`,
        component: React.lazy(() => import('views/fob/categories/main_sub/unit_convert_by_unit/unit_convert_list')),
    },
    {
        key: 'apps.unit_convert_by_unit.unit_convert_add',
        path: `${APP_PREFIX_PATH}/categories/main_sub/unit_convert_by_unit/unit_convert_add`,
        component: React.lazy(() => import('views/fob/categories/main_sub/unit_convert_by_unit/unit_convert_add')),
    },
    {
        key: 'apps.unit_convert_by_unit.unit_convert_add',
        path: `${APP_PREFIX_PATH}/categories/main_sub/unit_convert_by_unit/unit_convert_add/:id`,
        component: React.lazy(() => import('views/fob/categories/main_sub/unit_convert_by_unit/unit_convert_add')),
    },
    {
        key: 'apps.unit_convert_by_unit.unit_convert_edit',
        path: `${APP_PREFIX_PATH}/categories/main_sub/unit_convert_by_unit/unit_convert_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/main_sub/unit_convert_by_unit/unit_convert_edit')),
    },
    {
        key: 'apps.unit_convert_by_unit.unit_convert_detail',
        path: `${APP_PREFIX_PATH}/categories/main_sub/unit_convert_by_unit/unit_convert_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/main_sub/unit_convert_by_unit/unit_convert_detail')),
    },
    {
        key: 'apps.unit_convert_by_unit.unit_convert_masscopy',
        path: `${APP_PREFIX_PATH}/categories/main_sub/unit_convert_by_unit/unit_convert_masscopy`,
        component: React.lazy(() => import('views/fob/categories/main_sub/unit_convert_by_unit/unit_convert_masscopy')),
    },
    {
        key: 'apps.unit_convert_by_unit.unit_convert_export',
        path: `${APP_PREFIX_PATH}/categories/main_sub/unit_convert_by_unit/unit_convert_export`,
        component: React.lazy(() => import('views/fob/categories/main_sub/unit_convert_by_unit/unit_convert_export')),
    },
    {
        key: 'apps.unit_convert_by_unit.unit_convert_import',
        path: `${APP_PREFIX_PATH}/categories/main_sub/unit_convert_by_unit/unit_convert_import`,
        component: React.lazy(() => import('views/fob/categories/main_sub/unit_convert_by_unit/unit_convert_import')),
    },

    {
        key: 'apps.other_cost_calculation_by_other_cost',
        path: `${APP_PREFIX_PATH}/categories/main_sub/other_cost_calculation_by_other_cost`,
        component: React.lazy(() => import('views/fob/categories/main_sub/other_cost_calculation_by_other_cost')),
    },
    {
        key: 'apps.other_cost_calculation_by_other_cost.other_cost_calculation_list',
        path: `${APP_PREFIX_PATH}/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_list`,
        component: React.lazy(() =>
            import('views/fob/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_list'),
        ),
    },
    {
        key: 'apps.other_cost_calculation_by_other_cost',
        path: `${APP_PREFIX_PATH}/categories/main_sub/other_cost_calculation_by_other_cost/`,
        component: React.lazy(() =>
            import('views/fob/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_list'),
        ),
    },
    {
        key: 'apps.other_cost_calculation_by_other_cost.other_cost_calculation_add',
        path: `${APP_PREFIX_PATH}/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_add`,
        component: React.lazy(() =>
            import('views/fob/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_add'),
        ),
    },
    {
        key: 'apps.other_cost_calculation_by_other_cost.other_cost_calculation_add',
        path: `${APP_PREFIX_PATH}/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_add/:id`,
        component: React.lazy(() =>
            import('views/fob/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_add'),
        ),
    },
    {
        key: 'apps.other_cost_calculation_by_other_cost.other_cost_calculation_edit',
        path: `${APP_PREFIX_PATH}/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_edit/:id`,
        component: React.lazy(() =>
            import('views/fob/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_edit'),
        ),
    },
    {
        key: 'apps.other_cost_calculation_by_other_cost.other_cost_calculation_detail',
        path: `${APP_PREFIX_PATH}/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_detail/:id`,
        component: React.lazy(() =>
            import('views/fob/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_detail'),
        ),
    },
    {
        key: 'apps.other_cost_calculation_by_other_cost.other_cost_calculation_masscopy',
        path: `${APP_PREFIX_PATH}/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_masscopy`,
        component: React.lazy(() =>
            import(
                'views/fob/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_masscopy'
            ),
        ),
    },
    {
        key: 'apps.other_cost_calculation_by_other_cost.other_cost_calculation_export',
        path: `${APP_PREFIX_PATH}/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_export`,
        component: React.lazy(() =>
            import('views/fob/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_export'),
        ),
    },
    {
        key: 'apps.other_cost_calculation_by_other_cost.other_cost_calculation_import',
        path: `${APP_PREFIX_PATH}/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_import`,
        component: React.lazy(() =>
            import('views/fob/categories/main_sub/other_cost_calculation_by_other_cost/other_cost_calculation_import'),
        ),
    },
    /*-------------bom list----------------*/
    {
        key: 'apps.bom',
        path: `${APP_PREFIX_PATH}/bom/`,
        component: React.lazy(() => import('views/fob/bom/bom_list')),
    },
    {
        key: 'apps.bom.bom_list',
        path: `${APP_PREFIX_PATH}/bom/bom_list`,
        component: React.lazy(() => import('views/fob/bom/bom_list')),
    },
    {
        key: 'apps.bom.bom_add',
        path: `${APP_PREFIX_PATH}/bom/bom_add`,
        component: React.lazy(() => import('views/fob/bom/bom_add')),
    },
    {
        key: 'apps.bom.bom_edit',
        path: `${APP_PREFIX_PATH}/bom/bom_edit/:id`,
        component: React.lazy(() => import('views/fob/bom/bom_edit')),
    },
    {
        key: 'apps.bom.bom_detail',
        path: `${APP_PREFIX_PATH}/bom/bom_detail/:id`,
        component: React.lazy(() => import('views/fob/bom/bom_detail')),
    },

    /*-------------end bom----------------*/
    /*-------------group_purchasing_order list----------------*/
    {
        key: 'apps.bom.group_po',
        path: `${APP_PREFIX_PATH}/bom/group_purchasing_order/`,
        component: React.lazy(() => import('views/fob/bom/group_purchasing_order/group_purchasing_order_list')),
    },
    {
        key: 'apps.bom.group_po_list',
        path: `${APP_PREFIX_PATH}/bom/group_purchasing_order/group_purchasing_order_list`,
        component: React.lazy(() => import('views/fob/bom/group_purchasing_order/group_purchasing_order_list')),
    },
    {
        key: 'apps.bom.group_po_add',
        path: `${APP_PREFIX_PATH}/bom/group_purchasing_order/group_purchasing_order_add`,
        component: React.lazy(() => import('views/fob/bom/group_purchasing_order/group_purchasing_order_add')),
    },
    {
        key: 'apps.bom.group_po_edit',
        path: `${APP_PREFIX_PATH}/bom/group_purchasing_order/group_purchasing_order_edit/:id`,
        component: React.lazy(() => import('views/fob/bom/group_purchasing_order/group_purchasing_order_edit')),
    },
    {
        key: 'apps.bom.group_po_detail',
        path: `${APP_PREFIX_PATH}/bom/group_purchasing_order/group_purchasing_order_detail/:id`,
        component: React.lazy(() => import('views/fob/bom/group_purchasing_order/group_purchasing_order_detail')),
    },
    // {
    //     key: 'login-1',
    //     path: `${APP_PREFIX_PATH}/login-1`,
    //     component: React.lazy(() => import('views/auth/authentication/login-1')),
    //     meta: {
    //         blankLayout: true,
    //     },
    // },

    {
        key: 'register-1',
        path: `${APP_PREFIX_PATH}/register-1`,
        component: React.lazy(() => import('views/auth/authentication/register-1')),
        meta: {
            blankLayout: true,
        },
    },

    {
        key: 'forgot-password',
        path: `${APP_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth/authentication/forgot-password')),
        meta: {
            blankLayout: true,
        },
    },
    // {
    //     key: 'error-page-1',
    //     path: `${APP_PREFIX_PATH}/error-page-1`,
    //     component: React.lazy(() => import('views/auth/errors/error-page-1')),
    //     meta: {
    //         blankLayout: true,
    //     },
    // },
    {
        key: 'pages',
        path: `${APP_PREFIX_PATH}/pages`,
        component: React.lazy(() => import('views/fob/pages')),
    },
    {
        key: 'pages.profile',
        path: `${APP_PREFIX_PATH}/pages/profile`,
        component: React.lazy(() => import('views/fob/pages/profile')),
    },
    {
        key: 'pages.pricing',
        path: `${APP_PREFIX_PATH}/pages/pricing`,
        component: React.lazy(() => import('views/fob/pages/pricing')),
    },
    {
        key: 'pages.faq',
        path: `${APP_PREFIX_PATH}/pages/faq`,
        component: React.lazy(() => import('views/fob/pages/faq')),
    },
    {
        key: 'pages.setting',
        path: `${APP_PREFIX_PATH}/pages/setting/*`,
        component: React.lazy(() => import('views/fob/pages/setting')),
    },
    {
        key: 'pages.user-list',
        path: `${APP_PREFIX_PATH}/pages/user-list`,
        component: React.lazy(() => import('views/fob/pages/user-list')),
    },
    {
        key: 'docs.documentation',
        path: `${APP_PREFIX_PATH}/docs/documentation/*`,
        component: React.lazy(() => import('views/fob/docs')),
    },

    /*-------------setting_template----------------*/
    {
        key: 'apps.setting_template',
        path: `${APP_PREFIX_PATH}/categories/setting_template`,
        component: React.lazy(() => import('views/fob/categories/setting_template')),
    },
    {
        key: 'apps.setting_template.setting_template_list',
        path: `${APP_PREFIX_PATH}/categories/setting_template/setting_template_list`,
        component: React.lazy(() => import('views/fob/categories/setting_template/setting_template_list')),
    },
    {
        key: 'apps.setting_template.setting_template_add',
        path: `${APP_PREFIX_PATH}/categories/setting_template/setting_template_add`,
        component: React.lazy(() => import('views/fob/categories/setting_template/setting_template_add')),
    },
    {
        key: 'apps.setting_template.setting_template_add',
        path: `${APP_PREFIX_PATH}/categories/setting_template/setting_template_add/:id`,
        component: React.lazy(() => import('views/fob/categories/setting_template/setting_template_add')),
    },
    {
        key: 'apps.setting_template.setting_template_edit',
        path: `${APP_PREFIX_PATH}/categories/setting_template/setting_template_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/setting_template/setting_template_edit')),
    },
    {
        key: 'apps.setting_template.setting_template_detail',
        path: `${APP_PREFIX_PATH}/categories/setting_template/setting_template_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/setting_template/setting_template_detail')),
    },
    {
        key: 'apps.setting_template.setting_template_masscopy',
        path: `${APP_PREFIX_PATH}/categories/setting_template/setting_template_masscopy`,
        component: React.lazy(() => import('views/fob/categories/setting_template/setting_template_masscopy')),
    },
    {
        key: 'apps.setting_template.setting_template_export',
        path: `${APP_PREFIX_PATH}/categories/setting_template/setting_template_export`,
        component: React.lazy(() => import('views/fob/categories/setting_template/setting_template_export')),
    },
    {
        key: 'apps.setting_template.setting_template_export_one',
        path: `${APP_PREFIX_PATH}/categories/setting_template/setting_template_export_one`,
        component: React.lazy(() => import('views/fob/categories/setting_template/setting_template_export_one')),
    },
    {
        key: 'apps.setting_template.setting_template_import',
        path: `${APP_PREFIX_PATH}/categories/setting_template/setting_template_import`,
        component: React.lazy(() => import('views/fob/categories/setting_template/setting_template_import')),
    },
    /*-------------End of setting_template----------------*/

    /*-------------unit_convert----------------*/
    {
        key: 'apps.unit_convert',
        path: `${APP_PREFIX_PATH}/categories/unit_convert`,
        component: React.lazy(() => import('views/fob/categories/unit_convert')),
    },
    {
        key: 'apps.unit_convert.unit_convert_list',
        path: `${APP_PREFIX_PATH}/categories/unit_convert/unit_convert_list`,
        component: React.lazy(() => import('views/fob/categories/unit_convert/unit_convert_list')),
    },
    {
        key: 'apps.unit_convert.unit_convert_add',
        path: `${APP_PREFIX_PATH}/categories/unit_convert/unit_convert_add`,
        component: React.lazy(() => import('views/fob/categories/unit_convert/unit_convert_add')),
    },
    {
        key: 'apps.unit_convert.unit_convert_add',
        path: `${APP_PREFIX_PATH}/categories/unit_convert/unit_convert_add/:id`,
        component: React.lazy(() => import('views/fob/categories/unit_convert/unit_convert_add')),
    },
    {
        key: 'apps.unit_convert.unit_convert_edit',
        path: `${APP_PREFIX_PATH}/categories/unit_convert/unit_convert_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/unit_convert/unit_convert_edit')),
    },
    {
        key: 'apps.unit_convert.unit_convert_detail',
        path: `${APP_PREFIX_PATH}/categories/unit_convert/unit_convert_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/unit_convert/unit_convert_detail')),
    },
    {
        key: 'apps.unit_convert.unit_convert_masscopy',
        path: `${APP_PREFIX_PATH}/categories/unit_convert/unit_convert_masscopy`,
        component: React.lazy(() => import('views/fob/categories/unit_convert/unit_convert_masscopy')),
    },
    {
        key: 'apps.unit_convert.unit_convert_export',
        path: `${APP_PREFIX_PATH}/categories/unit_convert/unit_convert_export`,
        component: React.lazy(() => import('views/fob/categories/unit_convert/unit_convert_export')),
    },
    {
        key: 'apps.unit_convert.unit_convert_export_one',
        path: `${APP_PREFIX_PATH}/categories/unit_convert/unit_convert_export_one`,
        component: React.lazy(() => import('views/fob/categories/unit_convert/unit_convert_export_one')),
    },
    {
        key: 'apps.unit_convert.unit_convert_import',
        path: `${APP_PREFIX_PATH}/categories/unit_convert/unit_convert_import`,
        component: React.lazy(() => import('views/fob/categories/unit_convert/unit_convert_import')),
    },
    /*-------------End of unit_convert----------------*/

    /*-------------lot----------------*/
    {
        key: 'apps.lot',
        path: `${APP_PREFIX_PATH}/categories/lot`,
        component: React.lazy(() => import('views/fob/categories/lot')),
    },
    {
        key: 'apps.lot.lot_list',
        path: `${APP_PREFIX_PATH}/categories/lot/lot_list`,
        component: React.lazy(() => import('views/fob/categories/lot/lot_list')),
    },
    {
        key: 'apps.lot.lot_add',
        path: `${APP_PREFIX_PATH}/categories/lot/lot_add`,
        component: React.lazy(() => import('views/fob/categories/lot/lot_add')),
    },
    {
        key: 'apps.lot.lot_add',
        path: `${APP_PREFIX_PATH}/categories/lot/lot_add/:id`,
        component: React.lazy(() => import('views/fob/categories/lot/lot_add')),
    },
    {
        key: 'apps.lot.lot_edit',
        path: `${APP_PREFIX_PATH}/categories/lot/lot_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/lot/lot_edit')),
    },
    {
        key: 'apps.lot.lot_detail',
        path: `${APP_PREFIX_PATH}/categories/lot/lot_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/lot/lot_detail')),
    },
    {
        key: 'apps.lot.lot_masscopy',
        path: `${APP_PREFIX_PATH}/categories/lot/lot_masscopy`,
        component: React.lazy(() => import('views/fob/categories/lot/lot_masscopy')),
    },
    {
        key: 'apps.lot.lot_export',
        path: `${APP_PREFIX_PATH}/categories/lot/lot_export`,
        component: React.lazy(() => import('views/fob/categories/lot/lot_export')),
    },
    {
        key: 'apps.lot.lot_export_one',
        path: `${APP_PREFIX_PATH}/categories/lot/lot_export_one`,
        component: React.lazy(() => import('views/fob/categories/lot/lot_export_one')),
    },
    {
        key: 'apps.lot.lot_import',
        path: `${APP_PREFIX_PATH}/categories/lot/lot_import`,
        component: React.lazy(() => import('views/fob/categories/lot/lot_import')),
    },
    /*-------------End of lot----------------*/

    /*-------------article----------------*/
    {
        key: 'apps.article',
        path: `${APP_PREFIX_PATH}/categories/article`,
        component: React.lazy(() => import('views/fob/categories/article')),
    },
    {
        key: 'apps.article.article_list',
        path: `${APP_PREFIX_PATH}/categories/article/article_list`,
        component: React.lazy(() => import('views/fob/categories/article/article_list')),
    },
    {
        key: 'apps.article.article_add',
        path: `${APP_PREFIX_PATH}/categories/article/article_add`,
        component: React.lazy(() => import('views/fob/categories/article/article_add')),
    },
    {
        key: 'apps.article.article_add',
        path: `${APP_PREFIX_PATH}/categories/article/article_add/:id`,
        component: React.lazy(() => import('views/fob/categories/article/article_add')),
    },
    {
        key: 'apps.article.article_edit',
        path: `${APP_PREFIX_PATH}/categories/article/article_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/article/article_edit')),
    },
    {
        key: 'apps.article.article_detail',
        path: `${APP_PREFIX_PATH}/categories/article/article_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/article/article_detail')),
    },
    {
        key: 'apps.article.article_masscopy',
        path: `${APP_PREFIX_PATH}/categories/article/article_masscopy`,
        component: React.lazy(() => import('views/fob/categories/article/article_masscopy')),
    },
    {
        key: 'apps.article.article_export',
        path: `${APP_PREFIX_PATH}/categories/article/article_export`,
        component: React.lazy(() => import('views/fob/categories/article/article_export')),
    },
    {
        key: 'apps.article.article_export_one',
        path: `${APP_PREFIX_PATH}/categories/article/article_export_one`,
        component: React.lazy(() => import('views/fob/categories/article/article_export_one')),
    },
    {
        key: 'apps.article.article_import',
        path: `${APP_PREFIX_PATH}/categories/article/article_import`,
        component: React.lazy(() => import('views/fob/categories/article/article_import')),
    },
    /*-------------End of article----------------*/

    /*-------------article_type----------------*/
    {
        key: 'apps.article_type',
        path: `${APP_PREFIX_PATH}/categories/article_type`,
        component: React.lazy(() => import('views/fob/categories/article_type')),
    },
    {
        key: 'apps.article_type.article_type_list',
        path: `${APP_PREFIX_PATH}/categories/article_type/article_type_list`,
        component: React.lazy(() => import('views/fob/categories/article_type/article_type_list')),
    },
    {
        key: 'apps.article_type.article_type_add',
        path: `${APP_PREFIX_PATH}/categories/article_type/article_type_add`,
        component: React.lazy(() => import('views/fob/categories/article_type/article_type_add')),
    },
    {
        key: 'apps.article_type.article_type_add',
        path: `${APP_PREFIX_PATH}/categories/article_type/article_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/article_type/article_type_add')),
    },
    {
        key: 'apps.article_type.article_type_edit',
        path: `${APP_PREFIX_PATH}/categories/article_type/article_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/article_type/article_type_edit')),
    },
    {
        key: 'apps.article_type.article_type_detail',
        path: `${APP_PREFIX_PATH}/categories/article_type/article_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/article_type/article_type_detail')),
    },
    {
        key: 'apps.article_type.article_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/article_type/article_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/article_type/article_type_masscopy')),
    },
    {
        key: 'apps.article_type.article_type_export',
        path: `${APP_PREFIX_PATH}/categories/article_type/article_type_export`,
        component: React.lazy(() => import('views/fob/categories/article_type/article_type_export')),
    },
    {
        key: 'apps.article_type.article_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/article_type/article_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/article_type/article_type_export_one')),
    },
    {
        key: 'apps.article_type.article_type_import',
        path: `${APP_PREFIX_PATH}/categories/article_type/article_type_import`,
        component: React.lazy(() => import('views/fob/categories/article_type/article_type_import')),
    },
    /*-------------End of article_type----------------*/

    /*-------------brand----------------*/
    {
        key: 'apps.brand',
        path: `${APP_PREFIX_PATH}/categories/brand`,
        component: React.lazy(() => import('views/fob/categories/brand')),
    },
    {
        key: 'apps.brand.brand_list',
        path: `${APP_PREFIX_PATH}/categories/brand/brand_list`,
        component: React.lazy(() => import('views/fob/categories/brand/brand_list')),
    },
    {
        key: 'apps.brand.brand_add',
        path: `${APP_PREFIX_PATH}/categories/brand/brand_add`,
        component: React.lazy(() => import('views/fob/categories/brand/brand_add')),
    },
    {
        key: 'apps.brand.brand_add',
        path: `${APP_PREFIX_PATH}/categories/brand/brand_add/:id`,
        component: React.lazy(() => import('views/fob/categories/brand/brand_add')),
    },
    {
        key: 'apps.brand.brand_edit',
        path: `${APP_PREFIX_PATH}/categories/brand/brand_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/brand/brand_edit')),
    },
    {
        key: 'apps.brand.brand_detail',
        path: `${APP_PREFIX_PATH}/categories/brand/brand_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/brand/brand_detail')),
    },
    {
        key: 'apps.brand.brand_masscopy',
        path: `${APP_PREFIX_PATH}/categories/brand/brand_masscopy`,
        component: React.lazy(() => import('views/fob/categories/brand/brand_masscopy')),
    },
    {
        key: 'apps.brand.brand_export',
        path: `${APP_PREFIX_PATH}/categories/brand/brand_export`,
        component: React.lazy(() => import('views/fob/categories/brand/brand_export')),
    },
    {
        key: 'apps.brand.brand_export_one',
        path: `${APP_PREFIX_PATH}/categories/brand/brand_export_one`,
        component: React.lazy(() => import('views/fob/categories/brand/brand_export_one')),
    },
    {
        key: 'apps.brand.brand_import',
        path: `${APP_PREFIX_PATH}/categories/brand/brand_import`,
        component: React.lazy(() => import('views/fob/categories/brand/brand_import')),
    },
    /*-------------End of brand----------------*/

    /*-------------color----------------*/
    {
        key: 'apps.color',
        path: `${APP_PREFIX_PATH}/categories/color`,
        component: React.lazy(() => import('views/fob/categories/color')),
    },
    {
        key: 'apps.color.color_list',
        path: `${APP_PREFIX_PATH}/categories/color/color_list`,
        component: React.lazy(() => import('views/fob/categories/color/color_list')),
    },
    {
        key: 'apps.color.color_add',
        path: `${APP_PREFIX_PATH}/categories/color/color_add`,
        component: React.lazy(() => import('views/fob/categories/color/color_add')),
    },
    {
        key: 'apps.color.color_add',
        path: `${APP_PREFIX_PATH}/categories/color/color_add/:id`,
        component: React.lazy(() => import('views/fob/categories/color/color_add')),
    },
    {
        key: 'apps.color.color_edit',
        path: `${APP_PREFIX_PATH}/categories/color/color_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/color/color_edit')),
    },
    {
        key: 'apps.color.color_detail',
        path: `${APP_PREFIX_PATH}/categories/color/color_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/color/color_detail')),
    },
    {
        key: 'apps.color.color_masscopy',
        path: `${APP_PREFIX_PATH}/categories/color/color_masscopy`,
        component: React.lazy(() => import('views/fob/categories/color/color_masscopy')),
    },
    {
        key: 'apps.color.color_export',
        path: `${APP_PREFIX_PATH}/categories/color/color_export`,
        component: React.lazy(() => import('views/fob/categories/color/color_export')),
    },
    {
        key: 'apps.color.color_export_one',
        path: `${APP_PREFIX_PATH}/categories/color/color_export_one`,
        component: React.lazy(() => import('views/fob/categories/color/color_export_one')),
    },
    {
        key: 'apps.color.color_import',
        path: `${APP_PREFIX_PATH}/categories/color/color_import`,
        component: React.lazy(() => import('views/fob/categories/color/color_import')),
    },
    /*-------------End of color----------------*/

    /*-------------contract_status----------------*/
    {
        key: 'apps.contract_status',
        path: `${APP_PREFIX_PATH}/categories/contract_status`,
        component: React.lazy(() => import('views/fob/categories/contract_status')),
    },
    {
        key: 'apps.contract_status.contract_status_list',
        path: `${APP_PREFIX_PATH}/categories/contract_status/contract_status_list`,
        component: React.lazy(() => import('views/fob/categories/contract_status/contract_status_list')),
    },
    {
        key: 'apps.contract_status.contract_status_add',
        path: `${APP_PREFIX_PATH}/categories/contract_status/contract_status_add`,
        component: React.lazy(() => import('views/fob/categories/contract_status/contract_status_add')),
    },
    {
        key: 'apps.contract_status.contract_status_add',
        path: `${APP_PREFIX_PATH}/categories/contract_status/contract_status_add/:id`,
        component: React.lazy(() => import('views/fob/categories/contract_status/contract_status_add')),
    },
    {
        key: 'apps.contract_status.contract_status_edit',
        path: `${APP_PREFIX_PATH}/categories/contract_status/contract_status_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/contract_status/contract_status_edit')),
    },
    {
        key: 'apps.contract_status.contract_status_detail',
        path: `${APP_PREFIX_PATH}/categories/contract_status/contract_status_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/contract_status/contract_status_detail')),
    },
    {
        key: 'apps.contract_status.contract_status_masscopy',
        path: `${APP_PREFIX_PATH}/categories/contract_status/contract_status_masscopy`,
        component: React.lazy(() => import('views/fob/categories/contract_status/contract_status_masscopy')),
    },
    {
        key: 'apps.contract_status.contract_status_export',
        path: `${APP_PREFIX_PATH}/categories/contract_status/contract_status_export`,
        component: React.lazy(() => import('views/fob/categories/contract_status/contract_status_export')),
    },
    {
        key: 'apps.contract_status.contract_status_export_one',
        path: `${APP_PREFIX_PATH}/categories/contract_status/contract_status_export_one`,
        component: React.lazy(() => import('views/fob/categories/contract_status/contract_status_export_one')),
    },
    {
        key: 'apps.contract_status.contract_status_import',
        path: `${APP_PREFIX_PATH}/categories/contract_status/contract_status_import`,
        component: React.lazy(() => import('views/fob/categories/contract_status/contract_status_import')),
    },
    /*-------------End of contract_status----------------*/

    /*-------------cost_sheet_template----------------*/
    {
        key: 'apps.cost_sheet_template',
        path: `${APP_PREFIX_PATH}/categories/cost_sheet_template`,
        component: React.lazy(() => import('views/fob/categories/cost_sheet_template')),
    },
    {
        key: 'apps.cost_sheet_template.cost_sheet_template_list',
        path: `${APP_PREFIX_PATH}/categories/cost_sheet_template/cost_sheet_template_list`,
        component: React.lazy(() => import('views/fob/categories/cost_sheet_template/cost_sheet_template_list')),
    },
    {
        key: 'apps.cost_sheet_template.cost_sheet_template_add',
        path: `${APP_PREFIX_PATH}/categories/cost_sheet_template/cost_sheet_template_add`,
        component: React.lazy(() => import('views/fob/categories/cost_sheet_template/cost_sheet_template_add')),
    },
    {
        key: 'apps.cost_sheet_template.cost_sheet_template_add',
        path: `${APP_PREFIX_PATH}/categories/cost_sheet_template/cost_sheet_template_add/:id`,
        component: React.lazy(() => import('views/fob/categories/cost_sheet_template/cost_sheet_template_add')),
    },
    {
        key: 'apps.cost_sheet_template.cost_sheet_template_edit',
        path: `${APP_PREFIX_PATH}/categories/cost_sheet_template/cost_sheet_template_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/cost_sheet_template/cost_sheet_template_edit')),
    },
    {
        key: 'apps.cost_sheet_template.cost_sheet_template_detail',
        path: `${APP_PREFIX_PATH}/categories/cost_sheet_template/cost_sheet_template_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/cost_sheet_template/cost_sheet_template_detail')),
    },
    {
        key: 'apps.cost_sheet_template.cost_sheet_template_masscopy',
        path: `${APP_PREFIX_PATH}/categories/cost_sheet_template/cost_sheet_template_masscopy`,
        component: React.lazy(() => import('views/fob/categories/cost_sheet_template/cost_sheet_template_masscopy')),
    },
    {
        key: 'apps.cost_sheet_template.cost_sheet_template_export',
        path: `${APP_PREFIX_PATH}/categories/cost_sheet_template/cost_sheet_template_export`,
        component: React.lazy(() => import('views/fob/categories/cost_sheet_template/cost_sheet_template_export')),
    },
    {
        key: 'apps.cost_sheet_template.cost_sheet_template_export_one',
        path: `${APP_PREFIX_PATH}/categories/cost_sheet_template/cost_sheet_template_export_one`,
        component: React.lazy(() => import('views/fob/categories/cost_sheet_template/cost_sheet_template_export_one')),
    },
    {
        key: 'apps.cost_sheet_template.cost_sheet_template_import',
        path: `${APP_PREFIX_PATH}/categories/cost_sheet_template/cost_sheet_template_import`,
        component: React.lazy(() => import('views/fob/categories/cost_sheet_template/cost_sheet_template_import')),
    },
    /*-------------End of cost_sheet_template----------------*/

    /*-------------country----------------*/
    {
        key: 'apps.country',
        path: `${APP_PREFIX_PATH}/categories/country`,
        component: React.lazy(() => import('views/fob/categories/country')),
    },
    {
        key: 'apps.country.country_list',
        path: `${APP_PREFIX_PATH}/categories/country/country_list`,
        component: React.lazy(() => import('views/fob/categories/country/country_list')),
    },
    {
        key: 'apps.country.country_add',
        path: `${APP_PREFIX_PATH}/categories/country/country_add`,
        component: React.lazy(() => import('views/fob/categories/country/country_add')),
    },
    {
        key: 'apps.country.country_add',
        path: `${APP_PREFIX_PATH}/categories/country/country_add/:id`,
        component: React.lazy(() => import('views/fob/categories/country/country_add')),
    },
    {
        key: 'apps.country.country_edit',
        path: `${APP_PREFIX_PATH}/categories/country/country_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/country/country_edit')),
    },
    {
        key: 'apps.country.country_detail',
        path: `${APP_PREFIX_PATH}/categories/country/country_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/country/country_detail')),
    },
    {
        key: 'apps.country.country_masscopy',
        path: `${APP_PREFIX_PATH}/categories/country/country_masscopy`,
        component: React.lazy(() => import('views/fob/categories/country/country_masscopy')),
    },
    {
        key: 'apps.country.country_export',
        path: `${APP_PREFIX_PATH}/categories/country/country_export`,
        component: React.lazy(() => import('views/fob/categories/country/country_export')),
    },
    {
        key: 'apps.country.country_export_one',
        path: `${APP_PREFIX_PATH}/categories/country/country_export_one`,
        component: React.lazy(() => import('views/fob/categories/country/country_export_one')),
    },
    {
        key: 'apps.country.country_import',
        path: `${APP_PREFIX_PATH}/categories/country/country_import`,
        component: React.lazy(() => import('views/fob/categories/country/country_import')),
    },
    /*-------------End of country----------------*/

    /*-------------currency----------------*/
    {
        key: 'apps.currency',
        path: `${APP_PREFIX_PATH}/categories/currency`,
        component: React.lazy(() => import('views/fob/categories/currency')),
    },
    {
        key: 'apps.currency.currency_list',
        path: `${APP_PREFIX_PATH}/categories/currency/currency_list`,
        component: React.lazy(() => import('views/fob/categories/currency/currency_list')),
    },
    {
        key: 'apps.currency.currency_add',
        path: `${APP_PREFIX_PATH}/categories/currency/currency_add`,
        component: React.lazy(() => import('views/fob/categories/currency/currency_add')),
    },
    {
        key: 'apps.currency.currency_add',
        path: `${APP_PREFIX_PATH}/categories/currency/currency_add/:id`,
        component: React.lazy(() => import('views/fob/categories/currency/currency_add')),
    },
    {
        key: 'apps.currency.currency_edit',
        path: `${APP_PREFIX_PATH}/categories/currency/currency_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/currency/currency_edit')),
    },
    {
        key: 'apps.currency.currency_detail',
        path: `${APP_PREFIX_PATH}/categories/currency/currency_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/currency/currency_detail')),
    },
    {
        key: 'apps.currency.currency_masscopy',
        path: `${APP_PREFIX_PATH}/categories/currency/currency_masscopy`,
        component: React.lazy(() => import('views/fob/categories/currency/currency_masscopy')),
    },
    {
        key: 'apps.currency.currency_export',
        path: `${APP_PREFIX_PATH}/categories/currency/currency_export`,
        component: React.lazy(() => import('views/fob/categories/currency/currency_export')),
    },
    {
        key: 'apps.currency.currency_export_one',
        path: `${APP_PREFIX_PATH}/categories/currency/currency_export_one`,
        component: React.lazy(() => import('views/fob/categories/currency/currency_export_one')),
    },
    {
        key: 'apps.currency.currency_import',
        path: `${APP_PREFIX_PATH}/categories/currency/currency_import`,
        component: React.lazy(() => import('views/fob/categories/currency/currency_import')),
    },
    /*-------------End of currency----------------*/

    /*-------------customer_group----------------*/
    {
        key: 'apps.customer_group',
        path: `${APP_PREFIX_PATH}/categories/customer_group`,
        component: React.lazy(() => import('views/fob/categories/customer_group')),
    },
    {
        key: 'apps.customer_group.customer_group_list',
        path: `${APP_PREFIX_PATH}/categories/customer_group/customer_group_list`,
        component: React.lazy(() => import('views/fob/categories/customer_group/customer_group_list')),
    },
    {
        key: 'apps.customer_group.customer_group_add',
        path: `${APP_PREFIX_PATH}/categories/customer_group/customer_group_add`,
        component: React.lazy(() => import('views/fob/categories/customer_group/customer_group_add')),
    },
    {
        key: 'apps.customer_group.customer_group_add',
        path: `${APP_PREFIX_PATH}/categories/customer_group/customer_group_add/:id`,
        component: React.lazy(() => import('views/fob/categories/customer_group/customer_group_add')),
    },
    {
        key: 'apps.customer_group.customer_group_edit',
        path: `${APP_PREFIX_PATH}/categories/customer_group/customer_group_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/customer_group/customer_group_edit')),
    },
    {
        key: 'apps.customer_group.customer_group_detail',
        path: `${APP_PREFIX_PATH}/categories/customer_group/customer_group_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/customer_group/customer_group_detail')),
    },
    {
        key: 'apps.customer_group.customer_group_masscopy',
        path: `${APP_PREFIX_PATH}/categories/customer_group/customer_group_masscopy`,
        component: React.lazy(() => import('views/fob/categories/customer_group/customer_group_masscopy')),
    },
    {
        key: 'apps.customer_group.customer_group_export',
        path: `${APP_PREFIX_PATH}/categories/customer_group/customer_group_export`,
        component: React.lazy(() => import('views/fob/categories/customer_group/customer_group_export')),
    },
    {
        key: 'apps.customer_group.customer_group_export_one',
        path: `${APP_PREFIX_PATH}/categories/customer_group/customer_group_export_one`,
        component: React.lazy(() => import('views/fob/categories/customer_group/customer_group_export_one')),
    },
    {
        key: 'apps.customer_group.customer_group_import',
        path: `${APP_PREFIX_PATH}/categories/customer_group/customer_group_import`,
        component: React.lazy(() => import('views/fob/categories/customer_group/customer_group_import')),
    },
    /*-------------End of customer_group----------------*/

    /*-------------customer----------------*/
    {
        key: 'apps.customer',
        path: `${APP_PREFIX_PATH}/categories/customer`,
        component: React.lazy(() => import('views/fob/categories/customer')),
    },
    {
        key: 'apps.customer.customer_list',
        path: `${APP_PREFIX_PATH}/categories/customer/customer_list`,
        component: React.lazy(() => import('views/fob/categories/customer/customer_list')),
    },
    {
        key: 'apps.customer.customer_add',
        path: `${APP_PREFIX_PATH}/categories/customer/customer_add`,
        component: React.lazy(() => import('views/fob/categories/customer/customer_add')),
    },
    {
        key: 'apps.customer.customer_add',
        path: `${APP_PREFIX_PATH}/categories/customer/customer_add/:id`,
        component: React.lazy(() => import('views/fob/categories/customer/customer_add')),
    },
    {
        key: 'apps.customer.customer_edit',
        path: `${APP_PREFIX_PATH}/categories/customer/customer_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/customer/customer_edit')),
    },
    {
        key: 'apps.customer.customer_detail',
        path: `${APP_PREFIX_PATH}/categories/customer/customer_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/customer/customer_detail')),
    },
    {
        key: 'apps.customer.customer_masscopy',
        path: `${APP_PREFIX_PATH}/categories/customer/customer_masscopy`,
        component: React.lazy(() => import('views/fob/categories/customer/customer_masscopy')),
    },
    {
        key: 'apps.customer.customer_export',
        path: `${APP_PREFIX_PATH}/categories/customer/customer_export`,
        component: React.lazy(() => import('views/fob/categories/customer/customer_export')),
    },
    {
        key: 'apps.customer.customer_export_one',
        path: `${APP_PREFIX_PATH}/categories/customer/customer_export_one`,
        component: React.lazy(() => import('views/fob/categories/customer/customer_export_one')),
    },
    {
        key: 'apps.customer.customer_import',
        path: `${APP_PREFIX_PATH}/categories/customer/customer_import`,
        component: React.lazy(() => import('views/fob/categories/customer/customer_import')),
    },
    /*-------------End of customer----------------*/

    /*-------------customer_type----------------*/
    {
        key: 'apps.customer_type',
        path: `${APP_PREFIX_PATH}/categories/customer_type`,
        component: React.lazy(() => import('views/fob/categories/customer_type')),
    },
    {
        key: 'apps.customer_type.customer_type_list',
        path: `${APP_PREFIX_PATH}/categories/customer_type/customer_type_list`,
        component: React.lazy(() => import('views/fob/categories/customer_type/customer_type_list')),
    },
    {
        key: 'apps.customer_type.customer_type_add',
        path: `${APP_PREFIX_PATH}/categories/customer_type/customer_type_add`,
        component: React.lazy(() => import('views/fob/categories/customer_type/customer_type_add')),
    },
    {
        key: 'apps.customer_type.customer_type_add',
        path: `${APP_PREFIX_PATH}/categories/customer_type/customer_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/customer_type/customer_type_add')),
    },
    {
        key: 'apps.customer_type.customer_type_edit',
        path: `${APP_PREFIX_PATH}/categories/customer_type/customer_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/customer_type/customer_type_edit')),
    },
    {
        key: 'apps.customer_type.customer_type_detail',
        path: `${APP_PREFIX_PATH}/categories/customer_type/customer_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/customer_type/customer_type_detail')),
    },
    {
        key: 'apps.customer_type.customer_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/customer_type/customer_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/customer_type/customer_type_masscopy')),
    },
    {
        key: 'apps.customer_type.customer_type_export',
        path: `${APP_PREFIX_PATH}/categories/customer_type/customer_type_export`,
        component: React.lazy(() => import('views/fob/categories/customer_type/customer_type_export')),
    },
    {
        key: 'apps.customer_type.customer_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/customer_type/customer_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/customer_type/customer_type_export_one')),
    },
    {
        key: 'apps.customer_type.customer_type_import',
        path: `${APP_PREFIX_PATH}/categories/customer_type/customer_type_import`,
        component: React.lazy(() => import('views/fob/categories/customer_type/customer_type_import')),
    },
    /*-------------End of customer_type----------------*/

    /*-------------fit----------------*/
    {
        key: 'apps.fit',
        path: `${APP_PREFIX_PATH}/categories/fit`,
        component: React.lazy(() => import('views/fob/categories/fit')),
    },
    {
        key: 'apps.fit.fit_list',
        path: `${APP_PREFIX_PATH}/categories/fit/fit_list`,
        component: React.lazy(() => import('views/fob/categories/fit/fit_list')),
    },
    {
        key: 'apps.fit.fit_add',
        path: `${APP_PREFIX_PATH}/categories/fit/fit_add`,
        component: React.lazy(() => import('views/fob/categories/fit/fit_add')),
    },
    {
        key: 'apps.fit.fit_add',
        path: `${APP_PREFIX_PATH}/categories/fit/fit_add/:id`,
        component: React.lazy(() => import('views/fob/categories/fit/fit_add')),
    },
    {
        key: 'apps.fit.fit_edit',
        path: `${APP_PREFIX_PATH}/categories/fit/fit_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/fit/fit_edit')),
    },
    {
        key: 'apps.fit.fit_detail',
        path: `${APP_PREFIX_PATH}/categories/fit/fit_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/fit/fit_detail')),
    },
    {
        key: 'apps.fit.fit_masscopy',
        path: `${APP_PREFIX_PATH}/categories/fit/fit_masscopy`,
        component: React.lazy(() => import('views/fob/categories/fit/fit_masscopy')),
    },
    {
        key: 'apps.fit.fit_export',
        path: `${APP_PREFIX_PATH}/categories/fit/fit_export`,
        component: React.lazy(() => import('views/fob/categories/fit/fit_export')),
    },
    {
        key: 'apps.fit.fit_export_one',
        path: `${APP_PREFIX_PATH}/categories/fit/fit_export_one`,
        component: React.lazy(() => import('views/fob/categories/fit/fit_export_one')),
    },
    {
        key: 'apps.fit.fit_import',
        path: `${APP_PREFIX_PATH}/categories/fit/fit_import`,
        component: React.lazy(() => import('views/fob/categories/fit/fit_import')),
    },
    /*-------------End of fit----------------*/

    /*-------------group_article_type----------------*/
    {
        key: 'apps.group_article_type',
        path: `${APP_PREFIX_PATH}/categories/group_article_type`,
        component: React.lazy(() => import('views/fob/categories/group_article_type')),
    },
    {
        key: 'apps.group_article_type.group_article_type_list',
        path: `${APP_PREFIX_PATH}/categories/group_article_type/group_article_type_list`,
        component: React.lazy(() => import('views/fob/categories/group_article_type/group_article_type_list')),
    },
    {
        key: 'apps.group_article_type.group_article_type_add',
        path: `${APP_PREFIX_PATH}/categories/group_article_type/group_article_type_add`,
        component: React.lazy(() => import('views/fob/categories/group_article_type/group_article_type_add')),
    },
    {
        key: 'apps.group_article_type.group_article_type_add',
        path: `${APP_PREFIX_PATH}/categories/group_article_type/group_article_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/group_article_type/group_article_type_add')),
    },
    {
        key: 'apps.group_article_type.group_article_type_edit',
        path: `${APP_PREFIX_PATH}/categories/group_article_type/group_article_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/group_article_type/group_article_type_edit')),
    },
    {
        key: 'apps.group_article_type.group_article_type_detail',
        path: `${APP_PREFIX_PATH}/categories/group_article_type/group_article_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/group_article_type/group_article_type_detail')),
    },
    {
        key: 'apps.group_article_type.group_article_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/group_article_type/group_article_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/group_article_type/group_article_type_masscopy')),
    },
    {
        key: 'apps.group_article_type.group_article_type_export',
        path: `${APP_PREFIX_PATH}/categories/group_article_type/group_article_type_export`,
        component: React.lazy(() => import('views/fob/categories/group_article_type/group_article_type_export')),
    },
    {
        key: 'apps.group_article_type.group_article_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/group_article_type/group_article_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/group_article_type/group_article_type_export_one')),
    },
    {
        key: 'apps.group_article_type.group_article_type_import',
        path: `${APP_PREFIX_PATH}/categories/group_article_type/group_article_type_import`,
        component: React.lazy(() => import('views/fob/categories/group_article_type/group_article_type_import')),
    },
    /*-------------End of group_article_type----------------*/

    /*-------------invoice_contract_type----------------*/
    {
        key: 'apps.invoice_contract_type',
        path: `${APP_PREFIX_PATH}/categories/invoice_contract_type`,
        component: React.lazy(() => import('views/fob/categories/invoice_contract_type')),
    },
    {
        key: 'apps.invoice_contract_type.invoice_contract_type_list',
        path: `${APP_PREFIX_PATH}/categories/invoice_contract_type/invoice_contract_type_list`,
        component: React.lazy(() => import('views/fob/categories/invoice_contract_type/invoice_contract_type_list')),
    },
    {
        key: 'apps.invoice_contract_type.invoice_contract_type_add',
        path: `${APP_PREFIX_PATH}/categories/invoice_contract_type/invoice_contract_type_add`,
        component: React.lazy(() => import('views/fob/categories/invoice_contract_type/invoice_contract_type_add')),
    },
    {
        key: 'apps.invoice_contract_type.invoice_contract_type_add',
        path: `${APP_PREFIX_PATH}/categories/invoice_contract_type/invoice_contract_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_contract_type/invoice_contract_type_add')),
    },
    {
        key: 'apps.invoice_contract_type.invoice_contract_type_edit',
        path: `${APP_PREFIX_PATH}/categories/invoice_contract_type/invoice_contract_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_contract_type/invoice_contract_type_edit')),
    },
    {
        key: 'apps.invoice_contract_type.invoice_contract_type_detail',
        path: `${APP_PREFIX_PATH}/categories/invoice_contract_type/invoice_contract_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_contract_type/invoice_contract_type_detail')),
    },
    {
        key: 'apps.invoice_contract_type.invoice_contract_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/invoice_contract_type/invoice_contract_type_masscopy`,
        component: React.lazy(() =>
            import('views/fob/categories/invoice_contract_type/invoice_contract_type_masscopy'),
        ),
    },
    {
        key: 'apps.invoice_contract_type.invoice_contract_type_export',
        path: `${APP_PREFIX_PATH}/categories/invoice_contract_type/invoice_contract_type_export`,
        component: React.lazy(() => import('views/fob/categories/invoice_contract_type/invoice_contract_type_export')),
    },
    {
        key: 'apps.invoice_contract_type.invoice_contract_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/invoice_contract_type/invoice_contract_type_export_one`,
        component: React.lazy(() =>
            import('views/fob/categories/invoice_contract_type/invoice_contract_type_export_one'),
        ),
    },
    {
        key: 'apps.invoice_contract_type.invoice_contract_type_import',
        path: `${APP_PREFIX_PATH}/categories/invoice_contract_type/invoice_contract_type_import`,
        component: React.lazy(() => import('views/fob/categories/invoice_contract_type/invoice_contract_type_import')),
    },
    /*-------------End of invoice_contract_type----------------*/

    /*-------------invoice_status----------------*/
    {
        key: 'apps.invoice_status',
        path: `${APP_PREFIX_PATH}/categories/invoice_status`,
        component: React.lazy(() => import('views/fob/categories/invoice_status')),
    },
    {
        key: 'apps.invoice_status.invoice_status_list',
        path: `${APP_PREFIX_PATH}/categories/invoice_status/invoice_status_list`,
        component: React.lazy(() => import('views/fob/categories/invoice_status/invoice_status_list')),
    },
    {
        key: 'apps.invoice_status.invoice_status_add',
        path: `${APP_PREFIX_PATH}/categories/invoice_status/invoice_status_add`,
        component: React.lazy(() => import('views/fob/categories/invoice_status/invoice_status_add')),
    },
    {
        key: 'apps.invoice_status.invoice_status_add',
        path: `${APP_PREFIX_PATH}/categories/invoice_status/invoice_status_add/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_status/invoice_status_add')),
    },
    {
        key: 'apps.invoice_status.invoice_status_edit',
        path: `${APP_PREFIX_PATH}/categories/invoice_status/invoice_status_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_status/invoice_status_edit')),
    },
    {
        key: 'apps.invoice_status.invoice_status_detail',
        path: `${APP_PREFIX_PATH}/categories/invoice_status/invoice_status_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_status/invoice_status_detail')),
    },
    {
        key: 'apps.invoice_status.invoice_status_masscopy',
        path: `${APP_PREFIX_PATH}/categories/invoice_status/invoice_status_masscopy`,
        component: React.lazy(() => import('views/fob/categories/invoice_status/invoice_status_masscopy')),
    },
    {
        key: 'apps.invoice_status.invoice_status_export',
        path: `${APP_PREFIX_PATH}/categories/invoice_status/invoice_status_export`,
        component: React.lazy(() => import('views/fob/categories/invoice_status/invoice_status_export')),
    },
    {
        key: 'apps.invoice_status.invoice_status_export_one',
        path: `${APP_PREFIX_PATH}/categories/invoice_status/invoice_status_export_one`,
        component: React.lazy(() => import('views/fob/categories/invoice_status/invoice_status_export_one')),
    },
    {
        key: 'apps.invoice_status.invoice_status_import',
        path: `${APP_PREFIX_PATH}/categories/invoice_status/invoice_status_import`,
        component: React.lazy(() => import('views/fob/categories/invoice_status/invoice_status_import')),
    },
    /*-------------End of invoice_status----------------*/

    /*-------------invoice_term----------------*/
    {
        key: 'apps.invoice_term',
        path: `${APP_PREFIX_PATH}/categories/invoice_term`,
        component: React.lazy(() => import('views/fob/categories/invoice_term')),
    },
    {
        key: 'apps.invoice_term.invoice_term_list',
        path: `${APP_PREFIX_PATH}/categories/invoice_term/invoice_term_list`,
        component: React.lazy(() => import('views/fob/categories/invoice_term/invoice_term_list')),
    },
    {
        key: 'apps.invoice_term.invoice_term_add',
        path: `${APP_PREFIX_PATH}/categories/invoice_term/invoice_term_add`,
        component: React.lazy(() => import('views/fob/categories/invoice_term/invoice_term_add')),
    },
    {
        key: 'apps.invoice_term.invoice_term_add',
        path: `${APP_PREFIX_PATH}/categories/invoice_term/invoice_term_add/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_term/invoice_term_add')),
    },
    {
        key: 'apps.invoice_term.invoice_term_edit',
        path: `${APP_PREFIX_PATH}/categories/invoice_term/invoice_term_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_term/invoice_term_edit')),
    },
    {
        key: 'apps.invoice_term.invoice_term_detail',
        path: `${APP_PREFIX_PATH}/categories/invoice_term/invoice_term_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_term/invoice_term_detail')),
    },
    {
        key: 'apps.invoice_term.invoice_term_masscopy',
        path: `${APP_PREFIX_PATH}/categories/invoice_term/invoice_term_masscopy`,
        component: React.lazy(() => import('views/fob/categories/invoice_term/invoice_term_masscopy')),
    },
    {
        key: 'apps.invoice_term.invoice_term_export',
        path: `${APP_PREFIX_PATH}/categories/invoice_term/invoice_term_export`,
        component: React.lazy(() => import('views/fob/categories/invoice_term/invoice_term_export')),
    },
    {
        key: 'apps.invoice_term.invoice_term_export_one',
        path: `${APP_PREFIX_PATH}/categories/invoice_term/invoice_term_export_one`,
        component: React.lazy(() => import('views/fob/categories/invoice_term/invoice_term_export_one')),
    },
    {
        key: 'apps.invoice_term.invoice_term_import',
        path: `${APP_PREFIX_PATH}/categories/invoice_term/invoice_term_import`,
        component: React.lazy(() => import('views/fob/categories/invoice_term/invoice_term_import')),
    },
    /*-------------End of invoice_term----------------*/

    /*-------------invoice_type----------------*/
    {
        key: 'apps.invoice_type',
        path: `${APP_PREFIX_PATH}/categories/invoice_type`,
        component: React.lazy(() => import('views/fob/categories/invoice_type')),
    },
    {
        key: 'apps.invoice_type.invoice_type_list',
        path: `${APP_PREFIX_PATH}/categories/invoice_type/invoice_type_list`,
        component: React.lazy(() => import('views/fob/categories/invoice_type/invoice_type_list')),
    },
    {
        key: 'apps.invoice_type.invoice_type_add',
        path: `${APP_PREFIX_PATH}/categories/invoice_type/invoice_type_add`,
        component: React.lazy(() => import('views/fob/categories/invoice_type/invoice_type_add')),
    },
    {
        key: 'apps.invoice_type.invoice_type_add',
        path: `${APP_PREFIX_PATH}/categories/invoice_type/invoice_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_type/invoice_type_add')),
    },
    {
        key: 'apps.invoice_type.invoice_type_edit',
        path: `${APP_PREFIX_PATH}/categories/invoice_type/invoice_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_type/invoice_type_edit')),
    },
    {
        key: 'apps.invoice_type.invoice_type_detail',
        path: `${APP_PREFIX_PATH}/categories/invoice_type/invoice_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/invoice_type/invoice_type_detail')),
    },
    {
        key: 'apps.invoice_type.invoice_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/invoice_type/invoice_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/invoice_type/invoice_type_masscopy')),
    },
    {
        key: 'apps.invoice_type.invoice_type_export',
        path: `${APP_PREFIX_PATH}/categories/invoice_type/invoice_type_export`,
        component: React.lazy(() => import('views/fob/categories/invoice_type/invoice_type_export')),
    },
    {
        key: 'apps.invoice_type.invoice_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/invoice_type/invoice_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/invoice_type/invoice_type_export_one')),
    },
    {
        key: 'apps.invoice_type.invoice_type_import',
        path: `${APP_PREFIX_PATH}/categories/invoice_type/invoice_type_import`,
        component: React.lazy(() => import('views/fob/categories/invoice_type/invoice_type_import')),
    },
    /*-------------End of invoice_type----------------*/

    /*-------------market----------------*/
    {
        key: 'apps.market',
        path: `${APP_PREFIX_PATH}/categories/market`,
        component: React.lazy(() => import('views/fob/categories/market')),
    },
    {
        key: 'apps.market.market_list',
        path: `${APP_PREFIX_PATH}/categories/market/market_list`,
        component: React.lazy(() => import('views/fob/categories/market/market_list')),
    },
    {
        key: 'apps.market.market_add',
        path: `${APP_PREFIX_PATH}/categories/market/market_add`,
        component: React.lazy(() => import('views/fob/categories/market/market_add')),
    },
    {
        key: 'apps.market.market_add',
        path: `${APP_PREFIX_PATH}/categories/market/market_add/:id`,
        component: React.lazy(() => import('views/fob/categories/market/market_add')),
    },
    {
        key: 'apps.market.market_edit',
        path: `${APP_PREFIX_PATH}/categories/market/market_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/market/market_edit')),
    },
    {
        key: 'apps.market.market_detail',
        path: `${APP_PREFIX_PATH}/categories/market/market_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/market/market_detail')),
    },
    {
        key: 'apps.market.market_masscopy',
        path: `${APP_PREFIX_PATH}/categories/market/market_masscopy`,
        component: React.lazy(() => import('views/fob/categories/market/market_masscopy')),
    },
    {
        key: 'apps.market.market_export',
        path: `${APP_PREFIX_PATH}/categories/market/market_export`,
        component: React.lazy(() => import('views/fob/categories/market/market_export')),
    },
    {
        key: 'apps.market.market_export_one',
        path: `${APP_PREFIX_PATH}/categories/market/market_export_one`,
        component: React.lazy(() => import('views/fob/categories/market/market_export_one')),
    },
    {
        key: 'apps.market.market_import',
        path: `${APP_PREFIX_PATH}/categories/market/market_import`,
        component: React.lazy(() => import('views/fob/categories/market/market_import')),
    },
    /*-------------End of market----------------*/

    /*-------------other_cost_group----------------*/
    {
        key: 'apps.other_cost_group',
        path: `${APP_PREFIX_PATH}/categories/other_cost_group`,
        component: React.lazy(() => import('views/fob/categories/other_cost_group')),
    },
    {
        key: 'apps.other_cost_group.other_cost_group_list',
        path: `${APP_PREFIX_PATH}/categories/other_cost_group/other_cost_group_list`,
        component: React.lazy(() => import('views/fob/categories/other_cost_group/other_cost_group_list')),
    },
    {
        key: 'apps.other_cost_group.other_cost_group_add',
        path: `${APP_PREFIX_PATH}/categories/other_cost_group/other_cost_group_add`,
        component: React.lazy(() => import('views/fob/categories/other_cost_group/other_cost_group_add')),
    },
    {
        key: 'apps.other_cost_group.other_cost_group_add',
        path: `${APP_PREFIX_PATH}/categories/other_cost_group/other_cost_group_add/:id`,
        component: React.lazy(() => import('views/fob/categories/other_cost_group/other_cost_group_add')),
    },
    {
        key: 'apps.other_cost_group.other_cost_group_edit',
        path: `${APP_PREFIX_PATH}/categories/other_cost_group/other_cost_group_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/other_cost_group/other_cost_group_edit')),
    },
    {
        key: 'apps.other_cost_group.other_cost_group_detail',
        path: `${APP_PREFIX_PATH}/categories/other_cost_group/other_cost_group_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/other_cost_group/other_cost_group_detail')),
    },
    {
        key: 'apps.other_cost_group.other_cost_group_masscopy',
        path: `${APP_PREFIX_PATH}/categories/other_cost_group/other_cost_group_masscopy`,
        component: React.lazy(() => import('views/fob/categories/other_cost_group/other_cost_group_masscopy')),
    },
    {
        key: 'apps.other_cost_group.other_cost_group_export',
        path: `${APP_PREFIX_PATH}/categories/other_cost_group/other_cost_group_export`,
        component: React.lazy(() => import('views/fob/categories/other_cost_group/other_cost_group_export')),
    },
    {
        key: 'apps.other_cost_group.other_cost_group_export_one',
        path: `${APP_PREFIX_PATH}/categories/other_cost_group/other_cost_group_export_one`,
        component: React.lazy(() => import('views/fob/categories/other_cost_group/other_cost_group_export_one')),
    },
    {
        key: 'apps.other_cost_group.other_cost_group_import',
        path: `${APP_PREFIX_PATH}/categories/other_cost_group/other_cost_group_import`,
        component: React.lazy(() => import('views/fob/categories/other_cost_group/other_cost_group_import')),
    },
    /*-------------End of other_cost_group----------------*/

    /*-------------other_cost----------------*/
    {
        key: 'apps.other_cost',
        path: `${APP_PREFIX_PATH}/categories/other_cost`,
        component: React.lazy(() => import('views/fob/categories/other_cost')),
    },
    {
        key: 'apps.other_cost.other_cost_list',
        path: `${APP_PREFIX_PATH}/categories/other_cost/other_cost_list`,
        component: React.lazy(() => import('views/fob/categories/other_cost/other_cost_list')),
    },
    {
        key: 'apps.other_cost.other_cost_add',
        path: `${APP_PREFIX_PATH}/categories/other_cost/other_cost_add`,
        component: React.lazy(() => import('views/fob/categories/other_cost/other_cost_add')),
    },
    {
        key: 'apps.other_cost.other_cost_add',
        path: `${APP_PREFIX_PATH}/categories/other_cost/other_cost_add/:id`,
        component: React.lazy(() => import('views/fob/categories/other_cost/other_cost_add')),
    },
    {
        key: 'apps.other_cost.other_cost_edit',
        path: `${APP_PREFIX_PATH}/categories/other_cost/other_cost_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/other_cost/other_cost_edit')),
    },
    {
        key: 'apps.other_cost.other_cost_detail',
        path: `${APP_PREFIX_PATH}/categories/other_cost/other_cost_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/other_cost/other_cost_detail')),
    },
    {
        key: 'apps.other_cost.other_cost_masscopy',
        path: `${APP_PREFIX_PATH}/categories/other_cost/other_cost_masscopy`,
        component: React.lazy(() => import('views/fob/categories/other_cost/other_cost_masscopy')),
    },
    {
        key: 'apps.other_cost.other_cost_export',
        path: `${APP_PREFIX_PATH}/categories/other_cost/other_cost_export`,
        component: React.lazy(() => import('views/fob/categories/other_cost/other_cost_export')),
    },
    {
        key: 'apps.other_cost.other_cost_export_one',
        path: `${APP_PREFIX_PATH}/categories/other_cost/other_cost_export_one`,
        component: React.lazy(() => import('views/fob/categories/other_cost/other_cost_export_one')),
    },
    {
        key: 'apps.other_cost.other_cost_import',
        path: `${APP_PREFIX_PATH}/categories/other_cost/other_cost_import`,
        component: React.lazy(() => import('views/fob/categories/other_cost/other_cost_import')),
    },
    /*-------------End of other_cost----------------*/

    /*-------------other_cost_calculation----------------*/
    {
        key: 'apps.other_cost_calculation',
        path: `${APP_PREFIX_PATH}/categories/other_cost_calculation`,
        component: React.lazy(() => import('views/fob/categories/other_cost_calculation')),
    },
    {
        key: 'apps.other_cost_calculation.other_cost_calculation_list',
        path: `${APP_PREFIX_PATH}/categories/other_cost_calculation/other_cost_calculation_list`,
        component: React.lazy(() => import('views/fob/categories/other_cost_calculation/other_cost_calculation_list')),
    },
    {
        key: 'apps.other_cost_calculation.other_cost_calculation_add',
        path: `${APP_PREFIX_PATH}/categories/other_cost_calculation/other_cost_calculation_add`,
        component: React.lazy(() => import('views/fob/categories/other_cost_calculation/other_cost_calculation_add')),
    },
    {
        key: 'apps.other_cost_calculation.other_cost_calculation_add',
        path: `${APP_PREFIX_PATH}/categories/other_cost_calculation/other_cost_calculation_add/:id`,
        component: React.lazy(() => import('views/fob/categories/other_cost_calculation/other_cost_calculation_add')),
    },
    {
        key: 'apps.other_cost_calculation.other_cost_calculation_edit',
        path: `${APP_PREFIX_PATH}/categories/other_cost_calculation/other_cost_calculation_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/other_cost_calculation/other_cost_calculation_edit')),
    },
    {
        key: 'apps.other_cost_calculation.other_cost_calculation_detail',
        path: `${APP_PREFIX_PATH}/categories/other_cost_calculation/other_cost_calculation_detail/:id`,
        component: React.lazy(() =>
            import('views/fob/categories/other_cost_calculation/other_cost_calculation_detail'),
        ),
    },
    {
        key: 'apps.other_cost_calculation.other_cost_calculation_masscopy',
        path: `${APP_PREFIX_PATH}/categories/other_cost_calculation/other_cost_calculation_masscopy`,
        component: React.lazy(() =>
            import('views/fob/categories/other_cost_calculation/other_cost_calculation_masscopy'),
        ),
    },
    {
        key: 'apps.other_cost_calculation.other_cost_calculation_export',
        path: `${APP_PREFIX_PATH}/categories/other_cost_calculation/other_cost_calculation_export`,
        component: React.lazy(() =>
            import('views/fob/categories/other_cost_calculation/other_cost_calculation_export'),
        ),
    },
    {
        key: 'apps.other_cost_calculation.other_cost_calculation_export_one',
        path: `${APP_PREFIX_PATH}/categories/other_cost_calculation/other_cost_calculation_export_one`,
        component: React.lazy(() =>
            import('views/fob/categories/other_cost_calculation/other_cost_calculation_export_one'),
        ),
    },
    {
        key: 'apps.other_cost_calculation.other_cost_calculation_import',
        path: `${APP_PREFIX_PATH}/categories/other_cost_calculation/other_cost_calculation_import`,
        component: React.lazy(() =>
            import('views/fob/categories/other_cost_calculation/other_cost_calculation_import'),
        ),
    },
    /*-------------End of other_cost_calculation----------------*/

    /*-------------other_cost_type----------------*/
    {
        key: 'apps.other_cost_type',
        path: `${APP_PREFIX_PATH}/categories/other_cost_type`,
        component: React.lazy(() => import('views/fob/categories/other_cost_type')),
    },
    {
        key: 'apps.other_cost_type.other_cost_type_list',
        path: `${APP_PREFIX_PATH}/categories/other_cost_type/other_cost_type_list`,
        component: React.lazy(() => import('views/fob/categories/other_cost_type/other_cost_type_list')),
    },
    {
        key: 'apps.other_cost_type.other_cost_type_add',
        path: `${APP_PREFIX_PATH}/categories/other_cost_type/other_cost_type_add`,
        component: React.lazy(() => import('views/fob/categories/other_cost_type/other_cost_type_add')),
    },
    {
        key: 'apps.other_cost_type.other_cost_type_add',
        path: `${APP_PREFIX_PATH}/categories/other_cost_type/other_cost_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/other_cost_type/other_cost_type_add')),
    },
    {
        key: 'apps.other_cost_type.other_cost_type_edit',
        path: `${APP_PREFIX_PATH}/categories/other_cost_type/other_cost_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/other_cost_type/other_cost_type_edit')),
    },
    {
        key: 'apps.other_cost_type.other_cost_type_detail',
        path: `${APP_PREFIX_PATH}/categories/other_cost_type/other_cost_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/other_cost_type/other_cost_type_detail')),
    },
    {
        key: 'apps.other_cost_type.other_cost_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/other_cost_type/other_cost_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/other_cost_type/other_cost_type_masscopy')),
    },
    {
        key: 'apps.other_cost_type.other_cost_type_export',
        path: `${APP_PREFIX_PATH}/categories/other_cost_type/other_cost_type_export`,
        component: React.lazy(() => import('views/fob/categories/other_cost_type/other_cost_type_export')),
    },
    {
        key: 'apps.other_cost_type.other_cost_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/other_cost_type/other_cost_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/other_cost_type/other_cost_type_export_one')),
    },
    {
        key: 'apps.other_cost_type.other_cost_type_import',
        path: `${APP_PREFIX_PATH}/categories/other_cost_type/other_cost_type_import`,
        component: React.lazy(() => import('views/fob/categories/other_cost_type/other_cost_type_import')),
    },
    /*-------------End of other_cost_type----------------*/

    /*-------------package----------------*/
    {
        key: 'apps.package',
        path: `${APP_PREFIX_PATH}/categories/package`,
        component: React.lazy(() => import('views/fob/categories/package')),
    },
    {
        key: 'apps.package.package_list',
        path: `${APP_PREFIX_PATH}/categories/package/package_list`,
        component: React.lazy(() => import('views/fob/categories/package/package_list')),
    },
    {
        key: 'apps.package.package_add',
        path: `${APP_PREFIX_PATH}/categories/package/package_add`,
        component: React.lazy(() => import('views/fob/categories/package/package_add')),
    },
    {
        key: 'apps.package.package_add',
        path: `${APP_PREFIX_PATH}/categories/package/package_add/:id`,
        component: React.lazy(() => import('views/fob/categories/package/package_add')),
    },
    {
        key: 'apps.package.package_edit',
        path: `${APP_PREFIX_PATH}/categories/package/package_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/package/package_edit')),
    },
    {
        key: 'apps.package.package_detail',
        path: `${APP_PREFIX_PATH}/categories/package/package_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/package/package_detail')),
    },
    {
        key: 'apps.package.package_masscopy',
        path: `${APP_PREFIX_PATH}/categories/package/package_masscopy`,
        component: React.lazy(() => import('views/fob/categories/package/package_masscopy')),
    },
    {
        key: 'apps.package.package_export',
        path: `${APP_PREFIX_PATH}/categories/package/package_export`,
        component: React.lazy(() => import('views/fob/categories/package/package_export')),
    },
    {
        key: 'apps.package.package_export_one',
        path: `${APP_PREFIX_PATH}/categories/package/package_export_one`,
        component: React.lazy(() => import('views/fob/categories/package/package_export_one')),
    },
    {
        key: 'apps.package.package_import',
        path: `${APP_PREFIX_PATH}/categories/package/package_import`,
        component: React.lazy(() => import('views/fob/categories/package/package_import')),
    },
    /*-------------End of package----------------*/

    /*-------------payment_method----------------*/
    {
        key: 'apps.payment_method',
        path: `${APP_PREFIX_PATH}/categories/payment_method`,
        component: React.lazy(() => import('views/fob/categories/payment_method')),
    },
    {
        key: 'apps.payment_method.payment_method_list',
        path: `${APP_PREFIX_PATH}/categories/payment_method/payment_method_list`,
        component: React.lazy(() => import('views/fob/categories/payment_method/payment_method_list')),
    },
    {
        key: 'apps.payment_method.payment_method_add',
        path: `${APP_PREFIX_PATH}/categories/payment_method/payment_method_add`,
        component: React.lazy(() => import('views/fob/categories/payment_method/payment_method_add')),
    },
    {
        key: 'apps.payment_method.payment_method_add',
        path: `${APP_PREFIX_PATH}/categories/payment_method/payment_method_add/:id`,
        component: React.lazy(() => import('views/fob/categories/payment_method/payment_method_add')),
    },
    {
        key: 'apps.payment_method.payment_method_edit',
        path: `${APP_PREFIX_PATH}/categories/payment_method/payment_method_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/payment_method/payment_method_edit')),
    },
    {
        key: 'apps.payment_method.payment_method_detail',
        path: `${APP_PREFIX_PATH}/categories/payment_method/payment_method_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/payment_method/payment_method_detail')),
    },
    {
        key: 'apps.payment_method.payment_method_masscopy',
        path: `${APP_PREFIX_PATH}/categories/payment_method/payment_method_masscopy`,
        component: React.lazy(() => import('views/fob/categories/payment_method/payment_method_masscopy')),
    },
    {
        key: 'apps.payment_method.payment_method_export',
        path: `${APP_PREFIX_PATH}/categories/payment_method/payment_method_export`,
        component: React.lazy(() => import('views/fob/categories/payment_method/payment_method_export')),
    },
    {
        key: 'apps.payment_method.payment_method_export_one',
        path: `${APP_PREFIX_PATH}/categories/payment_method/payment_method_export_one`,
        component: React.lazy(() => import('views/fob/categories/payment_method/payment_method_export_one')),
    },
    {
        key: 'apps.payment_method.payment_method_import',
        path: `${APP_PREFIX_PATH}/categories/payment_method/payment_method_import`,
        component: React.lazy(() => import('views/fob/categories/payment_method/payment_method_import')),
    },
    /*-------------End of payment_method----------------*/

    /*-------------product_group----------------*/
    {
        key: 'apps.product_group',
        path: `${APP_PREFIX_PATH}/categories/product_group`,
        component: React.lazy(() => import('views/fob/categories/product_group')),
    },
    {
        key: 'apps.product_group.product_group_list',
        path: `${APP_PREFIX_PATH}/categories/product_group/product_group_list`,
        component: React.lazy(() => import('views/fob/categories/product_group/product_group_list')),
    },
    {
        key: 'apps.product_group.product_group_add',
        path: `${APP_PREFIX_PATH}/categories/product_group/product_group_add`,
        component: React.lazy(() => import('views/fob/categories/product_group/product_group_add')),
    },
    {
        key: 'apps.product_group.product_group_add',
        path: `${APP_PREFIX_PATH}/categories/product_group/product_group_add/:id`,
        component: React.lazy(() => import('views/fob/categories/product_group/product_group_add')),
    },
    {
        key: 'apps.product_group.product_group_edit',
        path: `${APP_PREFIX_PATH}/categories/product_group/product_group_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/product_group/product_group_edit')),
    },
    {
        key: 'apps.product_group.product_group_detail',
        path: `${APP_PREFIX_PATH}/categories/product_group/product_group_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/product_group/product_group_detail')),
    },
    {
        key: 'apps.product_group.product_group_masscopy',
        path: `${APP_PREFIX_PATH}/categories/product_group/product_group_masscopy`,
        component: React.lazy(() => import('views/fob/categories/product_group/product_group_masscopy')),
    },
    {
        key: 'apps.product_group.product_group_export',
        path: `${APP_PREFIX_PATH}/categories/product_group/product_group_export`,
        component: React.lazy(() => import('views/fob/categories/product_group/product_group_export')),
    },
    {
        key: 'apps.product_group.product_group_export_one',
        path: `${APP_PREFIX_PATH}/categories/product_group/product_group_export_one`,
        component: React.lazy(() => import('views/fob/categories/product_group/product_group_export_one')),
    },
    {
        key: 'apps.product_group.product_group_import',
        path: `${APP_PREFIX_PATH}/categories/product_group/product_group_import`,
        component: React.lazy(() => import('views/fob/categories/product_group/product_group_import')),
    },
    /*-------------End of product_group----------------*/

    /*-------------product_type----------------*/
    {
        key: 'apps.product_type',
        path: `${APP_PREFIX_PATH}/categories/product_type`,
        component: React.lazy(() => import('views/fob/categories/product_type')),
    },
    {
        key: 'apps.product_type.product_type_list',
        path: `${APP_PREFIX_PATH}/categories/product_type/product_type_list`,
        component: React.lazy(() => import('views/fob/categories/product_type/product_type_list')),
    },
    {
        key: 'apps.product_type.product_type_add',
        path: `${APP_PREFIX_PATH}/categories/product_type/product_type_add`,
        component: React.lazy(() => import('views/fob/categories/product_type/product_type_add')),
    },
    {
        key: 'apps.product_type.product_type_add',
        path: `${APP_PREFIX_PATH}/categories/product_type/product_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/product_type/product_type_add')),
    },
    {
        key: 'apps.product_type.product_type_edit',
        path: `${APP_PREFIX_PATH}/categories/product_type/product_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/product_type/product_type_edit')),
    },
    {
        key: 'apps.product_type.product_type_detail',
        path: `${APP_PREFIX_PATH}/categories/product_type/product_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/product_type/product_type_detail')),
    },
    {
        key: 'apps.product_type.product_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/product_type/product_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/product_type/product_type_masscopy')),
    },
    {
        key: 'apps.product_type.product_type_export',
        path: `${APP_PREFIX_PATH}/categories/product_type/product_type_export`,
        component: React.lazy(() => import('views/fob/categories/product_type/product_type_export')),
    },
    {
        key: 'apps.product_type.product_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/product_type/product_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/product_type/product_type_export_one')),
    },
    {
        key: 'apps.product_type.product_type_import',
        path: `${APP_PREFIX_PATH}/categories/product_type/product_type_import`,
        component: React.lazy(() => import('views/fob/categories/product_type/product_type_import')),
    },
    /*-------------End of product_type----------------*/

    /*-------------season----------------*/
    {
        key: 'apps.season',
        path: `${APP_PREFIX_PATH}/categories/season`,
        component: React.lazy(() => import('views/fob/categories/season')),
    },
    {
        key: 'apps.season.season_list',
        path: `${APP_PREFIX_PATH}/categories/season/season_list`,
        component: React.lazy(() => import('views/fob/categories/season/season_list')),
    },
    {
        key: 'apps.season.season_add',
        path: `${APP_PREFIX_PATH}/categories/season/season_add`,
        component: React.lazy(() => import('views/fob/categories/season/season_add')),
    },
    {
        key: 'apps.season.season_add',
        path: `${APP_PREFIX_PATH}/categories/season/season_add/:id`,
        component: React.lazy(() => import('views/fob/categories/season/season_add')),
    },
    {
        key: 'apps.season.season_edit',
        path: `${APP_PREFIX_PATH}/categories/season/season_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/season/season_edit')),
    },
    {
        key: 'apps.season.season_detail',
        path: `${APP_PREFIX_PATH}/categories/season/season_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/season/season_detail')),
    },
    {
        key: 'apps.season.season_masscopy',
        path: `${APP_PREFIX_PATH}/categories/season/season_masscopy`,
        component: React.lazy(() => import('views/fob/categories/season/season_masscopy')),
    },
    {
        key: 'apps.season.season_export',
        path: `${APP_PREFIX_PATH}/categories/season/season_export`,
        component: React.lazy(() => import('views/fob/categories/season/season_export')),
    },
    {
        key: 'apps.season.season_export_one',
        path: `${APP_PREFIX_PATH}/categories/season/season_export_one`,
        component: React.lazy(() => import('views/fob/categories/season/season_export_one')),
    },
    {
        key: 'apps.season.season_import',
        path: `${APP_PREFIX_PATH}/categories/season/season_import`,
        component: React.lazy(() => import('views/fob/categories/season/season_import')),
    },
    /*-------------End of season----------------*/

    /*-------------size----------------*/
    {
        key: 'apps.size',
        path: `${APP_PREFIX_PATH}/categories/size`,
        component: React.lazy(() => import('views/fob/categories/size')),
    },
    {
        key: 'apps.size.size_list',
        path: `${APP_PREFIX_PATH}/categories/size/size_list`,
        component: React.lazy(() => import('views/fob/categories/size/size_list')),
    },
    {
        key: 'apps.size.size_add',
        path: `${APP_PREFIX_PATH}/categories/size/size_add`,
        component: React.lazy(() => import('views/fob/categories/size/size_add')),
    },
    {
        key: 'apps.size.size_add',
        path: `${APP_PREFIX_PATH}/categories/size/size_add/:id`,
        component: React.lazy(() => import('views/fob/categories/size/size_add')),
    },
    {
        key: 'apps.size.size_edit',
        path: `${APP_PREFIX_PATH}/categories/size/size_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/size/size_edit')),
    },
    {
        key: 'apps.size.size_detail',
        path: `${APP_PREFIX_PATH}/categories/size/size_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/size/size_detail')),
    },
    {
        key: 'apps.size.size_masscopy',
        path: `${APP_PREFIX_PATH}/categories/size/size_masscopy`,
        component: React.lazy(() => import('views/fob/categories/size/size_masscopy')),
    },
    {
        key: 'apps.size.size_export',
        path: `${APP_PREFIX_PATH}/categories/size/size_export`,
        component: React.lazy(() => import('views/fob/categories/size/size_export')),
    },
    {
        key: 'apps.size.size_export_one',
        path: `${APP_PREFIX_PATH}/categories/size/size_export_one`,
        component: React.lazy(() => import('views/fob/categories/size/size_export_one')),
    },
    {
        key: 'apps.size.size_import',
        path: `${APP_PREFIX_PATH}/categories/size/size_import`,
        component: React.lazy(() => import('views/fob/categories/size/size_import')),
    },
    /*-------------End of size----------------*/

    /*-------------stuff----------------*/
    {
        key: 'apps.stuff',
        path: `${APP_PREFIX_PATH}/categories/stuff`,
        component: React.lazy(() => import('views/fob/categories/stuff')),
    },
    {
        key: 'apps.stuff.stuff_list',
        path: `${APP_PREFIX_PATH}/categories/stuff/stuff_list`,
        component: React.lazy(() => import('views/fob/categories/stuff/stuff_list')),
    },
    {
        key: 'apps.stuff.stuff_add',
        path: `${APP_PREFIX_PATH}/categories/stuff/stuff_add`,
        component: React.lazy(() => import('views/fob/categories/stuff/stuff_add')),
    },
    {
        key: 'apps.stuff.stuff_add',
        path: `${APP_PREFIX_PATH}/categories/stuff/stuff_add/:id`,
        component: React.lazy(() => import('views/fob/categories/stuff/stuff_add')),
    },
    {
        key: 'apps.stuff.stuff_edit',
        path: `${APP_PREFIX_PATH}/categories/stuff/stuff_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/stuff/stuff_edit')),
    },
    {
        key: 'apps.stuff.stuff_detail',
        path: `${APP_PREFIX_PATH}/categories/stuff/stuff_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/stuff/stuff_detail')),
    },
    {
        key: 'apps.stuff.stuff_masscopy',
        path: `${APP_PREFIX_PATH}/categories/stuff/stuff_masscopy`,
        component: React.lazy(() => import('views/fob/categories/stuff/stuff_masscopy')),
    },
    {
        key: 'apps.stuff.stuff_export',
        path: `${APP_PREFIX_PATH}/categories/stuff/stuff_export`,
        component: React.lazy(() => import('views/fob/categories/stuff/stuff_export')),
    },
    {
        key: 'apps.stuff.stuff_export_one',
        path: `${APP_PREFIX_PATH}/categories/stuff/stuff_export_one`,
        component: React.lazy(() => import('views/fob/categories/stuff/stuff_export_one')),
    },
    {
        key: 'apps.stuff.stuff_import',
        path: `${APP_PREFIX_PATH}/categories/stuff/stuff_import`,
        component: React.lazy(() => import('views/fob/categories/stuff/stuff_import')),
    },
    /*-------------End of stuff----------------*/

    /*-------------style_status----------------*/
    {
        key: 'apps.style_status',
        path: `${APP_PREFIX_PATH}/categories/style_status`,
        component: React.lazy(() => import('views/fob/categories/style_status')),
    },
    {
        key: 'apps.style_status.style_status_list',
        path: `${APP_PREFIX_PATH}/categories/style_status/style_status_list`,
        component: React.lazy(() => import('views/fob/categories/style_status/style_status_list')),
    },
    {
        key: 'apps.style_status.style_status_add',
        path: `${APP_PREFIX_PATH}/categories/style_status/style_status_add`,
        component: React.lazy(() => import('views/fob/categories/style_status/style_status_add')),
    },
    {
        key: 'apps.style_status.style_status_add',
        path: `${APP_PREFIX_PATH}/categories/style_status/style_status_add/:id`,
        component: React.lazy(() => import('views/fob/categories/style_status/style_status_add')),
    },
    {
        key: 'apps.style_status.style_status_edit',
        path: `${APP_PREFIX_PATH}/categories/style_status/style_status_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/style_status/style_status_edit')),
    },
    {
        key: 'apps.style_status.style_status_detail',
        path: `${APP_PREFIX_PATH}/categories/style_status/style_status_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/style_status/style_status_detail')),
    },
    {
        key: 'apps.style_status.style_status_masscopy',
        path: `${APP_PREFIX_PATH}/categories/style_status/style_status_masscopy`,
        component: React.lazy(() => import('views/fob/categories/style_status/style_status_masscopy')),
    },
    {
        key: 'apps.style_status.style_status_export',
        path: `${APP_PREFIX_PATH}/categories/style_status/style_status_export`,
        component: React.lazy(() => import('views/fob/categories/style_status/style_status_export')),
    },
    {
        key: 'apps.style_status.style_status_export_one',
        path: `${APP_PREFIX_PATH}/categories/style_status/style_status_export_one`,
        component: React.lazy(() => import('views/fob/categories/style_status/style_status_export_one')),
    },
    {
        key: 'apps.style_status.style_status_import',
        path: `${APP_PREFIX_PATH}/categories/style_status/style_status_import`,
        component: React.lazy(() => import('views/fob/categories/style_status/style_status_import')),
    },
    /*-------------End of style_status----------------*/

    /*-------------supplier----------------*/
    {
        key: 'apps.supplier',
        path: `${APP_PREFIX_PATH}/categories/supplier`,
        component: React.lazy(() => import('views/fob/categories/supplier')),
    },
    {
        key: 'apps.supplier.supplier_list',
        path: `${APP_PREFIX_PATH}/categories/supplier/supplier_list`,
        component: React.lazy(() => import('views/fob/categories/supplier/supplier_list')),
    },
    {
        key: 'apps.supplier.supplier_add',
        path: `${APP_PREFIX_PATH}/categories/supplier/supplier_add`,
        component: React.lazy(() => import('views/fob/categories/supplier/supplier_add')),
    },
    {
        key: 'apps.supplier.supplier_add',
        path: `${APP_PREFIX_PATH}/categories/supplier/supplier_add/:id`,
        component: React.lazy(() => import('views/fob/categories/supplier/supplier_add')),
    },
    {
        key: 'apps.supplier.supplier_edit',
        path: `${APP_PREFIX_PATH}/categories/supplier/supplier_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/supplier/supplier_edit')),
    },
    {
        key: 'apps.supplier.supplier_detail',
        path: `${APP_PREFIX_PATH}/categories/supplier/supplier_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/supplier/supplier_detail')),
    },
    {
        key: 'apps.supplier.supplier_masscopy',
        path: `${APP_PREFIX_PATH}/categories/supplier/supplier_masscopy`,
        component: React.lazy(() => import('views/fob/categories/supplier/supplier_masscopy')),
    },
    {
        key: 'apps.supplier.supplier_export',
        path: `${APP_PREFIX_PATH}/categories/supplier/supplier_export`,
        component: React.lazy(() => import('views/fob/categories/supplier/supplier_export')),
    },
    {
        key: 'apps.supplier.supplier_export_one',
        path: `${APP_PREFIX_PATH}/categories/supplier/supplier_export_one`,
        component: React.lazy(() => import('views/fob/categories/supplier/supplier_export_one')),
    },
    {
        key: 'apps.supplier.supplier_import',
        path: `${APP_PREFIX_PATH}/categories/supplier/supplier_import`,
        component: React.lazy(() => import('views/fob/categories/supplier/supplier_import')),
    },
    /*-------------End of supplier----------------*/

    /*-------------unit----------------*/
    {
        key: 'apps.unit',
        path: `${APP_PREFIX_PATH}/categories/unit`,
        component: React.lazy(() => import('views/fob/categories/unit')),
    },
    {
        key: 'apps.unit.unit_list',
        path: `${APP_PREFIX_PATH}/categories/unit/unit_list`,
        component: React.lazy(() => import('views/fob/categories/unit/unit_list')),
    },
    {
        key: 'apps.unit.unit_add',
        path: `${APP_PREFIX_PATH}/categories/unit/unit_add`,
        component: React.lazy(() => import('views/fob/categories/unit/unit_add')),
    },
    {
        key: 'apps.unit.unit_add',
        path: `${APP_PREFIX_PATH}/categories/unit/unit_add/:id`,
        component: React.lazy(() => import('views/fob/categories/unit/unit_add')),
    },
    {
        key: 'apps.unit.unit_edit',
        path: `${APP_PREFIX_PATH}/categories/unit/unit_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/unit/unit_edit')),
    },
    {
        key: 'apps.unit.unit_detail',
        path: `${APP_PREFIX_PATH}/categories/unit/unit_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/unit/unit_detail')),
    },
    {
        key: 'apps.unit.unit_masscopy',
        path: `${APP_PREFIX_PATH}/categories/unit/unit_masscopy`,
        component: React.lazy(() => import('views/fob/categories/unit/unit_masscopy')),
    },
    {
        key: 'apps.unit.unit_export',
        path: `${APP_PREFIX_PATH}/categories/unit/unit_export`,
        component: React.lazy(() => import('views/fob/categories/unit/unit_export')),
    },
    {
        key: 'apps.unit.unit_export_one',
        path: `${APP_PREFIX_PATH}/categories/unit/unit_export_one`,
        component: React.lazy(() => import('views/fob/categories/unit/unit_export_one')),
    },
    {
        key: 'apps.unit.unit_import',
        path: `${APP_PREFIX_PATH}/categories/unit/unit_import`,
        component: React.lazy(() => import('views/fob/categories/unit/unit_import')),
    },
    /*-------------End of unit----------------*/

    /*-------------wash_type----------------*/
    {
        key: 'apps.wash_type',
        path: `${APP_PREFIX_PATH}/categories/wash_type`,
        component: React.lazy(() => import('views/fob/categories/wash_type')),
    },
    {
        key: 'apps.wash_type.wash_type_list',
        path: `${APP_PREFIX_PATH}/categories/wash_type/wash_type_list`,
        component: React.lazy(() => import('views/fob/categories/wash_type/wash_type_list')),
    },
    {
        key: 'apps.wash_type.wash_type_add',
        path: `${APP_PREFIX_PATH}/categories/wash_type/wash_type_add`,
        component: React.lazy(() => import('views/fob/categories/wash_type/wash_type_add')),
    },
    {
        key: 'apps.wash_type.wash_type_add',
        path: `${APP_PREFIX_PATH}/categories/wash_type/wash_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/wash_type/wash_type_add')),
    },
    {
        key: 'apps.wash_type.wash_type_edit',
        path: `${APP_PREFIX_PATH}/categories/wash_type/wash_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/wash_type/wash_type_edit')),
    },
    {
        key: 'apps.wash_type.wash_type_detail',
        path: `${APP_PREFIX_PATH}/categories/wash_type/wash_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/wash_type/wash_type_detail')),
    },
    {
        key: 'apps.wash_type.wash_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/wash_type/wash_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/wash_type/wash_type_masscopy')),
    },
    {
        key: 'apps.wash_type.wash_type_export',
        path: `${APP_PREFIX_PATH}/categories/wash_type/wash_type_export`,
        component: React.lazy(() => import('views/fob/categories/wash_type/wash_type_export')),
    },
    {
        key: 'apps.wash_type.wash_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/wash_type/wash_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/wash_type/wash_type_export_one')),
    },
    {
        key: 'apps.wash_type.wash_type_import',
        path: `${APP_PREFIX_PATH}/categories/wash_type/wash_type_import`,
        component: React.lazy(() => import('views/fob/categories/wash_type/wash_type_import')),
    },
    /*-------------End of wash_type----------------*/

    /*-------------master_file_type----------------*/
    {
        key: 'apps.master_file_type',
        path: `${APP_PREFIX_PATH}/categories/master_file_type`,
        component: React.lazy(() => import('views/fob/categories/master_file_type')),
    },
    {
        key: 'apps.master_file_type.master_file_type_list',
        path: `${APP_PREFIX_PATH}/categories/master_file_type/master_file_type_list`,
        component: React.lazy(() => import('views/fob/categories/master_file_type/master_file_type_list')),
    },
    {
        key: 'apps.master_file_type.master_file_type_add',
        path: `${APP_PREFIX_PATH}/categories/master_file_type/master_file_type_add`,
        component: React.lazy(() => import('views/fob/categories/master_file_type/master_file_type_add')),
    },
    {
        key: 'apps.master_file_type.master_file_type_add',
        path: `${APP_PREFIX_PATH}/categories/master_file_type/master_file_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/master_file_type/master_file_type_add')),
    },
    {
        key: 'apps.master_file_type.master_file_type_edit',
        path: `${APP_PREFIX_PATH}/categories/master_file_type/master_file_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/master_file_type/master_file_type_edit')),
    },
    {
        key: 'apps.master_file_type.master_file_type_detail',
        path: `${APP_PREFIX_PATH}/categories/master_file_type/master_file_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/master_file_type/master_file_type_detail')),
    },
    {
        key: 'apps.master_file_type.master_file_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/master_file_type/master_file_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/master_file_type/master_file_type_masscopy')),
    },
    {
        key: 'apps.master_file_type.master_file_type_export',
        path: `${APP_PREFIX_PATH}/categories/master_file_type/master_file_type_export`,
        component: React.lazy(() => import('views/fob/categories/master_file_type/master_file_type_export')),
    },
    {
        key: 'apps.master_file_type.master_file_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/master_file_type/master_file_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/master_file_type/master_file_type_export_one')),
    },
    {
        key: 'apps.master_file_type.master_file_type_import',
        path: `${APP_PREFIX_PATH}/categories/master_file_type/master_file_type_import`,
        component: React.lazy(() => import('views/fob/categories/master_file_type/master_file_type_import')),
    },
    /*-------------End of master_file_type----------------*/

    /*-------------product----------------*/
    {
        key: 'apps.product',
        path: `${APP_PREFIX_PATH}/categories/product`,
        component: React.lazy(() => import('views/fob/categories/product')),
    },
    {
        key: 'apps.product.product_list',
        path: `${APP_PREFIX_PATH}/categories/product/product_list`,
        component: React.lazy(() => import('views/fob/categories/product/product_list')),
    },
    {
        key: 'apps.product.product_add',
        path: `${APP_PREFIX_PATH}/categories/product/product_add`,
        component: React.lazy(() => import('views/fob/categories/product/product_add')),
    },
    {
        key: 'apps.product.product_add',
        path: `${APP_PREFIX_PATH}/categories/product/product_add/:id`,
        component: React.lazy(() => import('views/fob/categories/product/product_add')),
    },
    {
        key: 'apps.product.product_edit',
        path: `${APP_PREFIX_PATH}/categories/product/product_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/product/product_edit')),
    },
    {
        key: 'apps.product.product_detail',
        path: `${APP_PREFIX_PATH}/categories/product/product_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/product/product_detail')),
    },
    {
        key: 'apps.product.product_masscopy',
        path: `${APP_PREFIX_PATH}/categories/product/product_masscopy`,
        component: React.lazy(() => import('views/fob/categories/product/product_masscopy')),
    },
    {
        key: 'apps.product.product_export',
        path: `${APP_PREFIX_PATH}/categories/product/product_export`,
        component: React.lazy(() => import('views/fob/categories/product/product_export')),
    },
    {
        key: 'apps.product.product_export_one',
        path: `${APP_PREFIX_PATH}/categories/product/product_export_one`,
        component: React.lazy(() => import('views/fob/categories/product/product_export_one')),
    },
    {
        key: 'apps.product.product_import',
        path: `${APP_PREFIX_PATH}/categories/product/product_import`,
        component: React.lazy(() => import('views/fob/categories/product/product_import')),
    },
    /*-------------End of product----------------*/

    /*-------------saleorder_group----------------*/
    {
        key: 'apps.saleorder_group',
        path: `${APP_PREFIX_PATH}/categories/saleorder_group`,
        component: React.lazy(() => import('views/fob/categories/saleorder_group')),
    },
    {
        key: 'apps.saleorder_group.saleorder_group_list',
        path: `${APP_PREFIX_PATH}/categories/saleorder_group/saleorder_group_list`,
        component: React.lazy(() => import('views/fob/categories/saleorder_group/saleorder_group_list')),
    },
    {
        key: 'apps.saleorder_group.saleorder_group_add',
        path: `${APP_PREFIX_PATH}/categories/saleorder_group/saleorder_group_add`,
        component: React.lazy(() => import('views/fob/categories/saleorder_group/saleorder_group_add')),
    },
    {
        key: 'apps.saleorder_group.saleorder_group_add',
        path: `${APP_PREFIX_PATH}/categories/saleorder_group/saleorder_group_add/:id`,
        component: React.lazy(() => import('views/fob/categories/saleorder_group/saleorder_group_add')),
    },
    {
        key: 'apps.saleorder_group.saleorder_group_edit',
        path: `${APP_PREFIX_PATH}/categories/saleorder_group/saleorder_group_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/saleorder_group/saleorder_group_edit')),
    },
    {
        key: 'apps.saleorder_group.saleorder_group_detail',
        path: `${APP_PREFIX_PATH}/categories/saleorder_group/saleorder_group_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/saleorder_group/saleorder_group_detail')),
    },
    {
        key: 'apps.saleorder_group.saleorder_group_masscopy',
        path: `${APP_PREFIX_PATH}/categories/saleorder_group/saleorder_group_masscopy`,
        component: React.lazy(() => import('views/fob/categories/saleorder_group/saleorder_group_masscopy')),
    },
    {
        key: 'apps.saleorder_group.saleorder_group_export',
        path: `${APP_PREFIX_PATH}/categories/saleorder_group/saleorder_group_export`,
        component: React.lazy(() => import('views/fob/categories/saleorder_group/saleorder_group_export')),
    },
    {
        key: 'apps.saleorder_group.saleorder_group_export_one',
        path: `${APP_PREFIX_PATH}/categories/saleorder_group/saleorder_group_export_one`,
        component: React.lazy(() => import('views/fob/categories/saleorder_group/saleorder_group_export_one')),
    },
    {
        key: 'apps.saleorder_group.saleorder_group_import',
        path: `${APP_PREFIX_PATH}/categories/saleorder_group/saleorder_group_import`,
        component: React.lazy(() => import('views/fob/categories/saleorder_group/saleorder_group_import')),
    },
    /*-------------End of saleorder_group----------------*/

    /*-------------style_document_type----------------*/
    {
        key: 'apps.style_document_type',
        path: `${APP_PREFIX_PATH}/categories/style_document_type`,
        component: React.lazy(() => import('views/fob/categories/style_document_type')),
    },
    {
        key: 'apps.style_document_type.style_document_type_list',
        path: `${APP_PREFIX_PATH}/categories/style_document_type/style_document_type_list`,
        component: React.lazy(() => import('views/fob/categories/style_document_type/style_document_type_list')),
    },
    {
        key: 'apps.style_document_type.style_document_type_add',
        path: `${APP_PREFIX_PATH}/categories/style_document_type/style_document_type_add`,
        component: React.lazy(() => import('views/fob/categories/style_document_type/style_document_type_add')),
    },
    {
        key: 'apps.style_document_type.style_document_type_add',
        path: `${APP_PREFIX_PATH}/categories/style_document_type/style_document_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/style_document_type/style_document_type_add')),
    },
    {
        key: 'apps.style_document_type.style_document_type_edit',
        path: `${APP_PREFIX_PATH}/categories/style_document_type/style_document_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/style_document_type/style_document_type_edit')),
    },
    {
        key: 'apps.style_document_type.style_document_type_detail',
        path: `${APP_PREFIX_PATH}/categories/style_document_type/style_document_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/style_document_type/style_document_type_detail')),
    },
    {
        key: 'apps.style_document_type.style_document_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/style_document_type/style_document_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/style_document_type/style_document_type_masscopy')),
    },
    {
        key: 'apps.style_document_type.style_document_type_export',
        path: `${APP_PREFIX_PATH}/categories/style_document_type/style_document_type_export`,
        component: React.lazy(() => import('views/fob/categories/style_document_type/style_document_type_export')),
    },
    {
        key: 'apps.style_document_type.style_document_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/style_document_type/style_document_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/style_document_type/style_document_type_export_one')),
    },
    {
        key: 'apps.style_document_type.style_document_type_import',
        path: `${APP_PREFIX_PATH}/categories/style_document_type/style_document_type_import`,
        component: React.lazy(() => import('views/fob/categories/style_document_type/style_document_type_import')),
    },
    /*-------------End of style_document_type----------------*/

    /*-------------notification_flow----------------*/
    {
        key: 'apps.notification_flow',
        path: `${APP_PREFIX_PATH}/categories/notification_flow`,
        component: React.lazy(() => import('views/fob/categories/notification_flow')),
    },
    {
        key: 'apps.notification_flow.notification_flow_list',
        path: `${APP_PREFIX_PATH}/categories/notification_flow/notification_flow_list`,
        component: React.lazy(() => import('views/fob/categories/notification_flow/notification_flow_list')),
    },
    {
        key: 'apps.notification_flow.notification_flow_add',
        path: `${APP_PREFIX_PATH}/categories/notification_flow/notification_flow_add`,
        component: React.lazy(() => import('views/fob/categories/notification_flow/notification_flow_add')),
    },
    {
        key: 'apps.notification_flow.notification_flow_add',
        path: `${APP_PREFIX_PATH}/categories/notification_flow/notification_flow_add/:id`,
        component: React.lazy(() => import('views/fob/categories/notification_flow/notification_flow_add')),
    },
    {
        key: 'apps.notification_flow.notification_flow_edit',
        path: `${APP_PREFIX_PATH}/categories/notification_flow/notification_flow_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/notification_flow/notification_flow_edit')),
    },
    {
        key: 'apps.notification_flow.notification_flow_detail',
        path: `${APP_PREFIX_PATH}/categories/notification_flow/notification_flow_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/notification_flow/notification_flow_detail')),
    },
    {
        key: 'apps.notification_flow.notification_flow_masscopy',
        path: `${APP_PREFIX_PATH}/categories/notification_flow/notification_flow_masscopy`,
        component: React.lazy(() => import('views/fob/categories/notification_flow/notification_flow_masscopy')),
    },
    {
        key: 'apps.notification_flow.notification_flow_export',
        path: `${APP_PREFIX_PATH}/categories/notification_flow/notification_flow_export`,
        component: React.lazy(() => import('views/fob/categories/notification_flow/notification_flow_export')),
    },
    {
        key: 'apps.notification_flow.notification_flow_export_one',
        path: `${APP_PREFIX_PATH}/categories/notification_flow/notification_flow_export_one`,
        component: React.lazy(() => import('views/fob/categories/notification_flow/notification_flow_export_one')),
    },
    {
        key: 'apps.notification_flow.notification_flow_import',
        path: `${APP_PREFIX_PATH}/categories/notification_flow/notification_flow_import`,
        component: React.lazy(() => import('views/fob/categories/notification_flow/notification_flow_import')),
    },
    /*-------------End of notification_flow----------------*/

    /*-------------notification_template----------------*/
    {
        key: 'apps.notification_template',
        path: `${APP_PREFIX_PATH}/categories/notification_template`,
        component: React.lazy(() => import('views/fob/categories/notification_template')),
    },
    {
        key: 'apps.notification_template.notification_template_list',
        path: `${APP_PREFIX_PATH}/categories/notification_template/notification_template_list`,
        component: React.lazy(() => import('views/fob/categories/notification_template/notification_template_list')),
    },
    {
        key: 'apps.notification_template.notification_template_add',
        path: `${APP_PREFIX_PATH}/categories/notification_template/notification_template_add`,
        component: React.lazy(() => import('views/fob/categories/notification_template/notification_template_add')),
    },
    {
        key: 'apps.notification_template.notification_template_add',
        path: `${APP_PREFIX_PATH}/categories/notification_template/notification_template_add/:id`,
        component: React.lazy(() => import('views/fob/categories/notification_template/notification_template_add')),
    },
    {
        key: 'apps.notification_template.notification_template_edit',
        path: `${APP_PREFIX_PATH}/categories/notification_template/notification_template_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/notification_template/notification_template_edit')),
    },
    {
        key: 'apps.notification_template.notification_template_detail',
        path: `${APP_PREFIX_PATH}/categories/notification_template/notification_template_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/notification_template/notification_template_detail')),
    },
    {
        key: 'apps.notification_template.notification_template_masscopy',
        path: `${APP_PREFIX_PATH}/categories/notification_template/notification_template_masscopy`,
        component: React.lazy(() =>
            import('views/fob/categories/notification_template/notification_template_masscopy'),
        ),
    },
    {
        key: 'apps.notification_template.notification_template_export',
        path: `${APP_PREFIX_PATH}/categories/notification_template/notification_template_export`,
        component: React.lazy(() => import('views/fob/categories/notification_template/notification_template_export')),
    },
    {
        key: 'apps.notification_template.notification_template_export_one',
        path: `${APP_PREFIX_PATH}/categories/notification_template/notification_template_export_one`,
        component: React.lazy(() =>
            import('views/fob/categories/notification_template/notification_template_export_one'),
        ),
    },
    {
        key: 'apps.notification_template.notification_template_import',
        path: `${APP_PREFIX_PATH}/categories/notification_template/notification_template_import`,
        component: React.lazy(() => import('views/fob/categories/notification_template/notification_template_import')),
    },
    /*-------------End of notification_template----------------*/

    /*-------------entry_status----------------*/
    {
        key: 'apps.entry_status',
        path: `${APP_PREFIX_PATH}/categories/entry_status`,
        component: React.lazy(() => import('views/fob/categories/entry_status')),
    },
    {
        key: 'apps.entry_status.entry_status_list',
        path: `${APP_PREFIX_PATH}/categories/entry_status/entry_status_list`,
        component: React.lazy(() => import('views/fob/categories/entry_status/entry_status_list')),
    },
    {
        key: 'apps.entry_status.entry_status_add',
        path: `${APP_PREFIX_PATH}/categories/entry_status/entry_status_add`,
        component: React.lazy(() => import('views/fob/categories/entry_status/entry_status_add')),
    },
    {
        key: 'apps.entry_status.entry_status_add',
        path: `${APP_PREFIX_PATH}/categories/entry_status/entry_status_add/:id`,
        component: React.lazy(() => import('views/fob/categories/entry_status/entry_status_add')),
    },
    {
        key: 'apps.entry_status.entry_status_edit',
        path: `${APP_PREFIX_PATH}/categories/entry_status/entry_status_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/entry_status/entry_status_edit')),
    },
    {
        key: 'apps.entry_status.entry_status_detail',
        path: `${APP_PREFIX_PATH}/categories/entry_status/entry_status_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/entry_status/entry_status_detail')),
    },
    {
        key: 'apps.entry_status.entry_status_masscopy',
        path: `${APP_PREFIX_PATH}/categories/entry_status/entry_status_masscopy`,
        component: React.lazy(() => import('views/fob/categories/entry_status/entry_status_masscopy')),
    },
    {
        key: 'apps.entry_status.entry_status_export',
        path: `${APP_PREFIX_PATH}/categories/entry_status/entry_status_export`,
        component: React.lazy(() => import('views/fob/categories/entry_status/entry_status_export')),
    },
    {
        key: 'apps.entry_status.entry_status_export_one',
        path: `${APP_PREFIX_PATH}/categories/entry_status/entry_status_export_one`,
        component: React.lazy(() => import('views/fob/categories/entry_status/entry_status_export_one')),
    },
    {
        key: 'apps.entry_status.entry_status_import',
        path: `${APP_PREFIX_PATH}/categories/entry_status/entry_status_import`,
        component: React.lazy(() => import('views/fob/categories/entry_status/entry_status_import')),
    },
    /*-------------End of entry_status----------------*/

    /*-------------material_size----------------*/
    {
        key: 'apps.material_size',
        path: `${APP_PREFIX_PATH}/categories/material_size`,
        component: React.lazy(() => import('views/fob/categories/material_size')),
    },
    {
        key: 'apps.material_size.material_size_list',
        path: `${APP_PREFIX_PATH}/categories/material_size/material_size_list`,
        component: React.lazy(() => import('views/fob/categories/material_size/material_size_list')),
    },
    {
        key: 'apps.material_size.material_size_add',
        path: `${APP_PREFIX_PATH}/categories/material_size/material_size_add`,
        component: React.lazy(() => import('views/fob/categories/material_size/material_size_add')),
    },
    {
        key: 'apps.material_size.material_size_add',
        path: `${APP_PREFIX_PATH}/categories/material_size/material_size_add/:id`,
        component: React.lazy(() => import('views/fob/categories/material_size/material_size_add')),
    },
    {
        key: 'apps.material_size.material_size_edit',
        path: `${APP_PREFIX_PATH}/categories/material_size/material_size_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/material_size/material_size_edit')),
    },
    {
        key: 'apps.material_size.material_size_detail',
        path: `${APP_PREFIX_PATH}/categories/material_size/material_size_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/material_size/material_size_detail')),
    },
    {
        key: 'apps.material_size.material_size_masscopy',
        path: `${APP_PREFIX_PATH}/categories/material_size/material_size_masscopy`,
        component: React.lazy(() => import('views/fob/categories/material_size/material_size_masscopy')),
    },
    {
        key: 'apps.material_size.material_size_export',
        path: `${APP_PREFIX_PATH}/categories/material_size/material_size_export`,
        component: React.lazy(() => import('views/fob/categories/material_size/material_size_export')),
    },
    {
        key: 'apps.material_size.material_size_export_one',
        path: `${APP_PREFIX_PATH}/categories/material_size/material_size_export_one`,
        component: React.lazy(() => import('views/fob/categories/material_size/material_size_export_one')),
    },
    {
        key: 'apps.material_size.material_size_import',
        path: `${APP_PREFIX_PATH}/categories/material_size/material_size_import`,
        component: React.lazy(() => import('views/fob/categories/material_size/material_size_import')),
    },
    /*-------------End of material_size----------------*/

    /*-------------material_category----------------*/
    {
        key: 'apps.material_category',
        path: `${APP_PREFIX_PATH}/categories/material_category`,
        component: React.lazy(() => import('views/fob/categories/material_category')),
    },
    {
        key: 'apps.material_category.material_category_list',
        path: `${APP_PREFIX_PATH}/categories/material_category/material_category_list`,
        component: React.lazy(() => import('views/fob/categories/material_category/material_category_list')),
    },
    {
        key: 'apps.material_category.material_category_add',
        path: `${APP_PREFIX_PATH}/categories/material_category/material_category_add`,
        component: React.lazy(() => import('views/fob/categories/material_category/material_category_add')),
    },
    {
        key: 'apps.material_category.material_category_add',
        path: `${APP_PREFIX_PATH}/categories/material_category/material_category_add/:id`,
        component: React.lazy(() => import('views/fob/categories/material_category/material_category_add')),
    },
    {
        key: 'apps.material_category.material_category_edit',
        path: `${APP_PREFIX_PATH}/categories/material_category/material_category_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/material_category/material_category_edit')),
    },
    {
        key: 'apps.material_category.material_category_detail',
        path: `${APP_PREFIX_PATH}/categories/material_category/material_category_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/material_category/material_category_detail')),
    },
    {
        key: 'apps.material_category.material_category_masscopy',
        path: `${APP_PREFIX_PATH}/categories/material_category/material_category_masscopy`,
        component: React.lazy(() => import('views/fob/categories/material_category/material_category_masscopy')),
    },
    {
        key: 'apps.material_category.material_category_export',
        path: `${APP_PREFIX_PATH}/categories/material_category/material_category_export`,
        component: React.lazy(() => import('views/fob/categories/material_category/material_category_export')),
    },
    {
        key: 'apps.material_category.material_category_export_one',
        path: `${APP_PREFIX_PATH}/categories/material_category/material_category_export_one`,
        component: React.lazy(() => import('views/fob/categories/material_category/material_category_export_one')),
    },
    {
        key: 'apps.material_category.material_category_import',
        path: `${APP_PREFIX_PATH}/categories/material_category/material_category_import`,
        component: React.lazy(() => import('views/fob/categories/material_category/material_category_import')),
    },
    /*-------------End of material_category----------------*/

    /*-------------material_accountant----------------*/
    {
        key: 'apps.material_accountant',
        path: `${APP_PREFIX_PATH}/categories/material_accountant`,
        component: React.lazy(() => import('views/fob/categories/material_accountant')),
    },
    {
        key: 'apps.material_accountant.material_accountant_list',
        path: `${APP_PREFIX_PATH}/categories/material_accountant/material_accountant_list`,
        component: React.lazy(() => import('views/fob/categories/material_accountant/material_accountant_list')),
    },
    {
        key: 'apps.material_accountant.material_accountant_add',
        path: `${APP_PREFIX_PATH}/categories/material_accountant/material_accountant_add`,
        component: React.lazy(() => import('views/fob/categories/material_accountant/material_accountant_add')),
    },
    {
        key: 'apps.material_accountant.material_accountant_add',
        path: `${APP_PREFIX_PATH}/categories/material_accountant/material_accountant_add/:id`,
        component: React.lazy(() => import('views/fob/categories/material_accountant/material_accountant_add')),
    },
    {
        key: 'apps.material_accountant.material_accountant_edit',
        path: `${APP_PREFIX_PATH}/categories/material_accountant/material_accountant_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/material_accountant/material_accountant_edit')),
    },
    {
        key: 'apps.material_accountant.material_accountant_detail',
        path: `${APP_PREFIX_PATH}/categories/material_accountant/material_accountant_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/material_accountant/material_accountant_detail')),
    },
    {
        key: 'apps.material_accountant.material_accountant_masscopy',
        path: `${APP_PREFIX_PATH}/categories/material_accountant/material_accountant_masscopy`,
        component: React.lazy(() => import('views/fob/categories/material_accountant/material_accountant_masscopy')),
    },
    {
        key: 'apps.material_accountant.material_accountant_export',
        path: `${APP_PREFIX_PATH}/categories/material_accountant/material_accountant_export`,
        component: React.lazy(() => import('views/fob/categories/material_accountant/material_accountant_export')),
    },
    {
        key: 'apps.material_accountant.material_accountant_export_one',
        path: `${APP_PREFIX_PATH}/categories/material_accountant/material_accountant_export_one`,
        component: React.lazy(() => import('views/fob/categories/material_accountant/material_accountant_export_one')),
    },
    {
        key: 'apps.material_accountant.material_accountant_import',
        path: `${APP_PREFIX_PATH}/categories/material_accountant/material_accountant_import`,
        component: React.lazy(() => import('views/fob/categories/material_accountant/material_accountant_import')),
    },
    /*-------------End of material_accountant----------------*/

    /*-------------manufacturer----------------*/
    {
        key: 'apps.manufacturer',
        path: `${APP_PREFIX_PATH}/categories/manufacturer`,
        component: React.lazy(() => import('views/fob/categories/manufacturer')),
    },
    {
        key: 'apps.manufacturer.manufacturer_list',
        path: `${APP_PREFIX_PATH}/categories/manufacturer/manufacturer_list`,
        component: React.lazy(() => import('views/fob/categories/manufacturer/manufacturer_list')),
    },
    {
        key: 'apps.manufacturer.manufacturer_add',
        path: `${APP_PREFIX_PATH}/categories/manufacturer/manufacturer_add`,
        component: React.lazy(() => import('views/fob/categories/manufacturer/manufacturer_add')),
    },
    {
        key: 'apps.manufacturer.manufacturer_add',
        path: `${APP_PREFIX_PATH}/categories/manufacturer/manufacturer_add/:id`,
        component: React.lazy(() => import('views/fob/categories/manufacturer/manufacturer_add')),
    },
    {
        key: 'apps.manufacturer.manufacturer_edit',
        path: `${APP_PREFIX_PATH}/categories/manufacturer/manufacturer_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/manufacturer/manufacturer_edit')),
    },
    {
        key: 'apps.manufacturer.manufacturer_detail',
        path: `${APP_PREFIX_PATH}/categories/manufacturer/manufacturer_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/manufacturer/manufacturer_detail')),
    },
    {
        key: 'apps.manufacturer.manufacturer_masscopy',
        path: `${APP_PREFIX_PATH}/categories/manufacturer/manufacturer_masscopy`,
        component: React.lazy(() => import('views/fob/categories/manufacturer/manufacturer_masscopy')),
    },
    {
        key: 'apps.manufacturer.manufacturer_export',
        path: `${APP_PREFIX_PATH}/categories/manufacturer/manufacturer_export`,
        component: React.lazy(() => import('views/fob/categories/manufacturer/manufacturer_export')),
    },
    {
        key: 'apps.manufacturer.manufacturer_export_one',
        path: `${APP_PREFIX_PATH}/categories/manufacturer/manufacturer_export_one`,
        component: React.lazy(() => import('views/fob/categories/manufacturer/manufacturer_export_one')),
    },
    {
        key: 'apps.manufacturer.manufacturer_import',
        path: `${APP_PREFIX_PATH}/categories/manufacturer/manufacturer_import`,
        component: React.lazy(() => import('views/fob/categories/manufacturer/manufacturer_import')),
    },
    /*-------------End of manufacturer----------------*/

    /*-------------material----------------*/
    {
        key: 'apps.material',
        path: `${APP_PREFIX_PATH}/categories/material`,
        component: React.lazy(() => import('views/fob/categories/material')),
    },
    {
        key: 'apps.material.material_list',
        path: `${APP_PREFIX_PATH}/categories/material/material_list`,
        component: React.lazy(() => import('views/fob/categories/material/material_list')),
    },
    {
        key: 'apps.material.material_add',
        path: `${APP_PREFIX_PATH}/categories/material/material_add`,
        component: React.lazy(() => import('views/fob/categories/material/material_add')),
    },
    {
        key: 'apps.material.material_add',
        path: `${APP_PREFIX_PATH}/categories/material/material_add/:id`,
        component: React.lazy(() => import('views/fob/categories/material/material_add')),
    },
    {
        key: 'apps.material.material_edit',
        path: `${APP_PREFIX_PATH}/categories/material/material_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/material/material_edit')),
    },
    {
        key: 'apps.material.material_detail',
        path: `${APP_PREFIX_PATH}/categories/material/material_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/material/material_detail')),
    },
    {
        key: 'apps.material.material_masscopy',
        path: `${APP_PREFIX_PATH}/categories/material/material_masscopy`,
        component: React.lazy(() => import('views/fob/categories/material/material_masscopy')),
    },
    {
        key: 'apps.material.material_export',
        path: `${APP_PREFIX_PATH}/categories/material/material_export`,
        component: React.lazy(() => import('views/fob/categories/material/material_export')),
    },
    {
        key: 'apps.material.material_export_one',
        path: `${APP_PREFIX_PATH}/categories/material/material_export_one`,
        component: React.lazy(() => import('views/fob/categories/material/material_export_one')),
    },
    {
        key: 'apps.material.material_import',
        path: `${APP_PREFIX_PATH}/categories/material/material_import`,
        component: React.lazy(() => import('views/fob/categories/material/material_import')),
    },
    /*-------------End of material----------------*/

    /*-------------position_in_product----------------*/
    {
        key: 'apps.position_in_product',
        path: `${APP_PREFIX_PATH}/categories/position_in_product`,
        component: React.lazy(() => import('views/fob/categories/position_in_product')),
    },
    {
        key: 'apps.position_in_product.position_in_product_list',
        path: `${APP_PREFIX_PATH}/categories/position_in_product/position_in_product_list`,
        component: React.lazy(() => import('views/fob/categories/position_in_product/position_in_product_list')),
    },
    {
        key: 'apps.position_in_product.position_in_product_add',
        path: `${APP_PREFIX_PATH}/categories/position_in_product/position_in_product_add`,
        component: React.lazy(() => import('views/fob/categories/position_in_product/position_in_product_add')),
    },
    {
        key: 'apps.position_in_product.position_in_product_add',
        path: `${APP_PREFIX_PATH}/categories/position_in_product/position_in_product_add/:id`,
        component: React.lazy(() => import('views/fob/categories/position_in_product/position_in_product_add')),
    },
    {
        key: 'apps.position_in_product.position_in_product_edit',
        path: `${APP_PREFIX_PATH}/categories/position_in_product/position_in_product_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/position_in_product/position_in_product_edit')),
    },
    {
        key: 'apps.position_in_product.position_in_product_detail',
        path: `${APP_PREFIX_PATH}/categories/position_in_product/position_in_product_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/position_in_product/position_in_product_detail')),
    },
    {
        key: 'apps.position_in_product.position_in_product_masscopy',
        path: `${APP_PREFIX_PATH}/categories/position_in_product/position_in_product_masscopy`,
        component: React.lazy(() => import('views/fob/categories/position_in_product/position_in_product_masscopy')),
    },
    {
        key: 'apps.position_in_product.position_in_product_export',
        path: `${APP_PREFIX_PATH}/categories/position_in_product/position_in_product_export`,
        component: React.lazy(() => import('views/fob/categories/position_in_product/position_in_product_export')),
    },
    {
        key: 'apps.position_in_product.position_in_product_export_one',
        path: `${APP_PREFIX_PATH}/categories/position_in_product/position_in_product_export_one`,
        component: React.lazy(() => import('views/fob/categories/position_in_product/position_in_product_export_one')),
    },
    {
        key: 'apps.position_in_product.position_in_product_import',
        path: `${APP_PREFIX_PATH}/categories/position_in_product/position_in_product_import`,
        component: React.lazy(() => import('views/fob/categories/position_in_product/position_in_product_import')),
    },
    /*-------------End of position_in_product----------------*/

    /*-------------semi_product_type----------------*/
    {
        key: 'apps.semi_product_type',
        path: `${APP_PREFIX_PATH}/categories/semi_product_type`,
        component: React.lazy(() => import('views/fob/categories/semi_product_type')),
    },
    {
        key: 'apps.semi_product_type.semi_product_type_list',
        path: `${APP_PREFIX_PATH}/categories/semi_product_type/semi_product_type_list`,
        component: React.lazy(() => import('views/fob/categories/semi_product_type/semi_product_type_list')),
    },
    {
        key: 'apps.semi_product_type.semi_product_type_add',
        path: `${APP_PREFIX_PATH}/categories/semi_product_type/semi_product_type_add`,
        component: React.lazy(() => import('views/fob/categories/semi_product_type/semi_product_type_add')),
    },
    {
        key: 'apps.semi_product_type.semi_product_type_add',
        path: `${APP_PREFIX_PATH}/categories/semi_product_type/semi_product_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/semi_product_type/semi_product_type_add')),
    },
    {
        key: 'apps.semi_product_type.semi_product_type_edit',
        path: `${APP_PREFIX_PATH}/categories/semi_product_type/semi_product_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/semi_product_type/semi_product_type_edit')),
    },
    {
        key: 'apps.semi_product_type.semi_product_type_detail',
        path: `${APP_PREFIX_PATH}/categories/semi_product_type/semi_product_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/semi_product_type/semi_product_type_detail')),
    },
    {
        key: 'apps.semi_product_type.semi_product_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/semi_product_type/semi_product_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/semi_product_type/semi_product_type_masscopy')),
    },
    {
        key: 'apps.semi_product_type.semi_product_type_export',
        path: `${APP_PREFIX_PATH}/categories/semi_product_type/semi_product_type_export`,
        component: React.lazy(() => import('views/fob/categories/semi_product_type/semi_product_type_export')),
    },
    {
        key: 'apps.semi_product_type.semi_product_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/semi_product_type/semi_product_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/semi_product_type/semi_product_type_export_one')),
    },
    {
        key: 'apps.semi_product_type.semi_product_type_import',
        path: `${APP_PREFIX_PATH}/categories/semi_product_type/semi_product_type_import`,
        component: React.lazy(() => import('views/fob/categories/semi_product_type/semi_product_type_import')),
    },
    /*-------------End of semi_product_type----------------*/

    /*-------------sale_group----------------*/
    {
        key: 'apps.sale_group',
        path: `${APP_PREFIX_PATH}/categories/sale_group`,
        component: React.lazy(() => import('views/fob/categories/sale_group')),
    },
    {
        key: 'apps.sale_group.sale_group_list',
        path: `${APP_PREFIX_PATH}/categories/sale_group/sale_group_list`,
        component: React.lazy(() => import('views/fob/categories/sale_group/sale_group_list')),
    },
    {
        key: 'apps.sale_group.sale_group_add',
        path: `${APP_PREFIX_PATH}/categories/sale_group/sale_group_add`,
        component: React.lazy(() => import('views/fob/categories/sale_group/sale_group_add')),
    },
    {
        key: 'apps.sale_group.sale_group_add',
        path: `${APP_PREFIX_PATH}/categories/sale_group/sale_group_add/:id`,
        component: React.lazy(() => import('views/fob/categories/sale_group/sale_group_add')),
    },
    {
        key: 'apps.sale_group.sale_group_edit',
        path: `${APP_PREFIX_PATH}/categories/sale_group/sale_group_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/sale_group/sale_group_edit')),
    },
    {
        key: 'apps.sale_group.sale_group_detail',
        path: `${APP_PREFIX_PATH}/categories/sale_group/sale_group_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/sale_group/sale_group_detail')),
    },
    {
        key: 'apps.sale_group.sale_group_masscopy',
        path: `${APP_PREFIX_PATH}/categories/sale_group/sale_group_masscopy`,
        component: React.lazy(() => import('views/fob/categories/sale_group/sale_group_masscopy')),
    },
    {
        key: 'apps.sale_group.sale_group_export',
        path: `${APP_PREFIX_PATH}/categories/sale_group/sale_group_export`,
        component: React.lazy(() => import('views/fob/categories/sale_group/sale_group_export')),
    },
    {
        key: 'apps.sale_group.sale_group_export_one',
        path: `${APP_PREFIX_PATH}/categories/sale_group/sale_group_export_one`,
        component: React.lazy(() => import('views/fob/categories/sale_group/sale_group_export_one')),
    },
    {
        key: 'apps.sale_group.sale_group_import',
        path: `${APP_PREFIX_PATH}/categories/sale_group/sale_group_import`,
        component: React.lazy(() => import('views/fob/categories/sale_group/sale_group_import')),
    },
    /*-------------End of sale_group----------------*/

    /*-------------purchase_group----------------*/
    {
        key: 'apps.purchase_group',
        path: `${APP_PREFIX_PATH}/purchase_material/purchase_group`,
        component: React.lazy(() => import('views/fob/purchase_material/purchase_group')),
    },
    {
        key: 'apps.purchase_group.purchase_group_list',
        path: `${APP_PREFIX_PATH}/purchase_material/purchase_group/purchase_group_list`,
        component: React.lazy(() => import('views/fob//purchase_material/purchase_group/purchase_group_list')),
    },
    {
        key: 'apps.purchase_group.purchase_group_add',
        path: `${APP_PREFIX_PATH}/purchase_material/purchase_group/purchase_group_add`,
        component: React.lazy(() => import('views/fob/purchase_material/purchase_group/purchase_group_add')),
    },
    {
        key: 'apps.purchase_group.purchase_group_add',
        path: `${APP_PREFIX_PATH}/purchase_material/purchase_group/purchase_group_add/:id`,
        component: React.lazy(() => import('views/fob/purchase_material/purchase_group/purchase_group_add')),
    },
    {
        key: 'apps.purchase_group.purchase_group_edit',
        path: `${APP_PREFIX_PATH}/purchase_material/purchase_group/purchase_group_edit/:id`,
        component: React.lazy(() => import('views/fob/purchase_material/purchase_group/purchase_group_edit')),
    },
    {
        key: 'apps.purchase_group.purchase_group_detail',
        path: `${APP_PREFIX_PATH}/purchase_material/purchase_group/purchase_group_detail/:id`,
        component: React.lazy(() => import('views/fob/purchase_material/purchase_group/purchase_group_detail')),
    },
    {
        key: 'apps.purchase_group.purchase_group_control',
        path: `${APP_PREFIX_PATH}/purchase_material/purchase_group/purchase_group_control/:id`,
        component: React.lazy(() => import('views/fob/purchase_material/purchase_group/purchase_group_control')),
    },
    {
        key: 'apps.purchase_group.purchase_group_masscopy',
        path: `${APP_PREFIX_PATH}/purchase_material/purchase_group/purchase_group_masscopy`,
        component: React.lazy(() => import('views/fob/purchase_material/purchase_group/purchase_group_masscopy')),
    },
    {
        key: 'apps.purchase_group.purchase_group_export',
        path: `${APP_PREFIX_PATH}/purchase_material/purchase_group/purchase_group_export`,
        component: React.lazy(() => import('views/fob/purchase_material/purchase_group/purchase_group_export')),
    },
    {
        key: 'apps.purchase_group.purchase_group_export_one',
        path: `${APP_PREFIX_PATH}/purchase_material/purchase_group/purchase_group_export_one`,
        component: React.lazy(() => import('views/fob/purchase_material/purchase_group/purchase_group_export_one')),
    },
    {
        key: 'apps.purchase_group.purchase_group_import',
        path: `${APP_PREFIX_PATH}/purchase_material/purchase_group/purchase_group_import`,
        component: React.lazy(() => import('views/fob/purchase_material/purchase_group/purchase_group_import')),
    },
    /*-------------End of purchase_group----------------*/
    /*-------------purchase_material----------------*/
    // {
    //     key: 'apps.purchase_material',
    //     path: `${APP_PREFIX_PATH}/purchase_material/purchase_material`,
    //     component: React.lazy(() => import('views/fob/purchase_material')),
    // },
    // {
    //     key: 'apps.purchase_material.purchase_material_list',
    //     path: `${APP_PREFIX_PATH}/purchase_material/purchase_material_list`,
    //     component: React.lazy(() => import('views/fob/purchase_material/purchase_material_list')),
    // },
    // {
    //     key: 'apps.purchase_material.purchase_material_add',
    //     path: `${APP_PREFIX_PATH}/purchase_material/purchase_material_add`,
    //     component: React.lazy(() => import('views/fob/purchase_material/purchase_material_add')),
    // },
    // {
    //     key: 'apps.purchase_material.purchase_material_add',
    //     path: `${APP_PREFIX_PATH}/purchase_material/purchase_material_add/:id`,
    //     component: React.lazy(() => import('views/fob/purchase_material/purchase_material_add')),
    // },
    // {
    //     key: 'apps.purchase_material.purchase_material_edit',
    //     path: `${APP_PREFIX_PATH}/purchase_material/purchase_material_edit/:id`,
    //     component: React.lazy(() => import('views/fob/purchase_material/purchase_material_edit')),
    // },
    // {
    //     key: 'apps.purchase_material.purchase_material_detail',
    //     path: `${APP_PREFIX_PATH}/purchase_material/purchase_material_detail/:id`,
    //     component: React.lazy(() => import('views/fob/purchase_material/purchase_material_detail')),
    // },
    // {
    //     key: 'apps.purchase_material.purchase_material_masscopy',
    //     path: `${APP_PREFIX_PATH}/purchase_material/purchase_material_masscopy`,
    //     component: React.lazy(() => import('views/fob/purchase_material/purchase_material_masscopy')),
    // },
    // {
    //     key: 'apps.purchase_material.purchase_material_export',
    //     path: `${APP_PREFIX_PATH}/purchase_material/purchase_material_export`,
    //     component: React.lazy(() => import('views/fob/purchase_material/purchase_material_export')),
    // },
    // {
    //     key: 'apps.purchase_material.purchase_material_export_one',
    //     path: `${APP_PREFIX_PATH}/purchase_material/purchase_material_export_one`,
    //     component: React.lazy(() => import('views/fob/purchase_material/purchase_material_export_one')),
    // },
    // {
    //     key: 'apps.purchase_material.purchase_material_import',
    //     path: `${APP_PREFIX_PATH}/purchase_material/purchase_material_import`,
    //     component: React.lazy(() => import('views/fob/purchase_material/purchase_material_import')),
    // },
    /*-------------End of purchase_material---------------*/

    /*-------------doc_status----------------*/
    {
        key: 'apps.doc_status',
        path: `${APP_PREFIX_PATH}/categories/doc_status`,
        component: React.lazy(() => import('views/fob/categories/doc_status')),
    },
    {
        key: 'apps.doc_status.doc_status_list',
        path: `${APP_PREFIX_PATH}/categories/doc_status/doc_status_list`,
        component: React.lazy(() => import('views/fob/categories/doc_status/doc_status_list')),
    },
    {
        key: 'apps.doc_status.doc_status_add',
        path: `${APP_PREFIX_PATH}/categories/doc_status/doc_status_add`,
        component: React.lazy(() => import('views/fob/categories/doc_status/doc_status_add')),
    },
    {
        key: 'apps.doc_status.doc_status_add',
        path: `${APP_PREFIX_PATH}/categories/doc_status/doc_status_add/:id`,
        component: React.lazy(() => import('views/fob/categories/doc_status/doc_status_add')),
    },
    {
        key: 'apps.doc_status.doc_status_edit',
        path: `${APP_PREFIX_PATH}/categories/doc_status/doc_status_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/doc_status/doc_status_edit')),
    },
    {
        key: 'apps.doc_status.doc_status_detail',
        path: `${APP_PREFIX_PATH}/categories/doc_status/doc_status_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/doc_status/doc_status_detail')),
    },
    {
        key: 'apps.doc_status.doc_status_masscopy',
        path: `${APP_PREFIX_PATH}/categories/doc_status/doc_status_masscopy`,
        component: React.lazy(() => import('views/fob/categories/doc_status/doc_status_masscopy')),
    },
    {
        key: 'apps.doc_status.doc_status_export',
        path: `${APP_PREFIX_PATH}/categories/doc_status/doc_status_export`,
        component: React.lazy(() => import('views/fob/categories/doc_status/doc_status_export')),
    },
    {
        key: 'apps.doc_status.doc_status_export_one',
        path: `${APP_PREFIX_PATH}/categories/doc_status/doc_status_export_one`,
        component: React.lazy(() => import('views/fob/categories/doc_status/doc_status_export_one')),
    },
    {
        key: 'apps.doc_status.doc_status_import',
        path: `${APP_PREFIX_PATH}/categories/doc_status/doc_status_import`,
        component: React.lazy(() => import('views/fob/categories/doc_status/doc_status_import')),
    },
    /*-------------End of doc_status----------------*/

    /*-------------bom_doc_type----------------*/
    {
        key: 'apps.bom_doc_type',
        path: `${APP_PREFIX_PATH}/categories/bom_doc_type`,
        component: React.lazy(() => import('views/fob/categories/bom_doc_type')),
    },
    {
        key: 'apps.bom_doc_type.bom_doc_type_list',
        path: `${APP_PREFIX_PATH}/categories/bom_doc_type/bom_doc_type_list`,
        component: React.lazy(() => import('views/fob/categories/bom_doc_type/bom_doc_type_list')),
    },
    {
        key: 'apps.bom_doc_type.bom_doc_type_add',
        path: `${APP_PREFIX_PATH}/categories/bom_doc_type/bom_doc_type_add`,
        component: React.lazy(() => import('views/fob/categories/bom_doc_type/bom_doc_type_add')),
    },
    {
        key: 'apps.bom_doc_type.bom_doc_type_add',
        path: `${APP_PREFIX_PATH}/categories/bom_doc_type/bom_doc_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/bom_doc_type/bom_doc_type_add')),
    },
    {
        key: 'apps.bom_doc_type.bom_doc_type_edit',
        path: `${APP_PREFIX_PATH}/categories/bom_doc_type/bom_doc_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/bom_doc_type/bom_doc_type_edit')),
    },
    {
        key: 'apps.bom_doc_type.bom_doc_type_detail',
        path: `${APP_PREFIX_PATH}/categories/bom_doc_type/bom_doc_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/bom_doc_type/bom_doc_type_detail')),
    },
    {
        key: 'apps.bom_doc_type.bom_doc_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/bom_doc_type/bom_doc_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/bom_doc_type/bom_doc_type_masscopy')),
    },
    {
        key: 'apps.bom_doc_type.bom_doc_type_export',
        path: `${APP_PREFIX_PATH}/categories/bom_doc_type/bom_doc_type_export`,
        component: React.lazy(() => import('views/fob/categories/bom_doc_type/bom_doc_type_export')),
    },
    {
        key: 'apps.bom_doc_type.bom_doc_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/bom_doc_type/bom_doc_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/bom_doc_type/bom_doc_type_export_one')),
    },
    {
        key: 'apps.bom_doc_type.bom_doc_type_import',
        path: `${APP_PREFIX_PATH}/categories/bom_doc_type/bom_doc_type_import`,
        component: React.lazy(() => import('views/fob/categories/bom_doc_type/bom_doc_type_import')),
    },
    /*-------------End of bom_doc_type----------------*/

    /*-------------produce_status----------------*/
    {
        key: 'apps.produce_status',
        path: `${APP_PREFIX_PATH}/categories/produce_status`,
        component: React.lazy(() => import('views/fob/categories/produce_status')),
    },
    {
        key: 'apps.produce_status.produce_status_list',
        path: `${APP_PREFIX_PATH}/categories/produce_status/produce_status_list`,
        component: React.lazy(() => import('views/fob/categories/produce_status/produce_status_list')),
    },
    {
        key: 'apps.produce_status.produce_status_add',
        path: `${APP_PREFIX_PATH}/categories/produce_status/produce_status_add`,
        component: React.lazy(() => import('views/fob/categories/produce_status/produce_status_add')),
    },
    {
        key: 'apps.produce_status.produce_status_add',
        path: `${APP_PREFIX_PATH}/categories/produce_status/produce_status_add/:id`,
        component: React.lazy(() => import('views/fob/categories/produce_status/produce_status_add')),
    },
    {
        key: 'apps.produce_status.produce_status_edit',
        path: `${APP_PREFIX_PATH}/categories/produce_status/produce_status_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/produce_status/produce_status_edit')),
    },
    {
        key: 'apps.produce_status.produce_status_detail',
        path: `${APP_PREFIX_PATH}/categories/produce_status/produce_status_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/produce_status/produce_status_detail')),
    },
    {
        key: 'apps.produce_status.produce_status_masscopy',
        path: `${APP_PREFIX_PATH}/categories/produce_status/produce_status_masscopy`,
        component: React.lazy(() => import('views/fob/categories/produce_status/produce_status_masscopy')),
    },
    {
        key: 'apps.produce_status.produce_status_export',
        path: `${APP_PREFIX_PATH}/categories/produce_status/produce_status_export`,
        component: React.lazy(() => import('views/fob/categories/produce_status/produce_status_export')),
    },
    {
        key: 'apps.produce_status.produce_status_export_one',
        path: `${APP_PREFIX_PATH}/categories/produce_status/produce_status_export_one`,
        component: React.lazy(() => import('views/fob/categories/produce_status/produce_status_export_one')),
    },
    {
        key: 'apps.produce_status.produce_status_import',
        path: `${APP_PREFIX_PATH}/categories/produce_status/produce_status_import`,
        component: React.lazy(() => import('views/fob/categories/produce_status/produce_status_import')),
    },
    /*-------------End of produce_status----------------*/

    /*-------------inout_kind----------------*/
    {
        key: 'apps.inout_kind',
        path: `${APP_PREFIX_PATH}/categories/inout_kind`,
        component: React.lazy(() => import('views/fob/categories/inout_kind')),
    },
    {
        key: 'apps.inout_kind.inout_kind_list',
        path: `${APP_PREFIX_PATH}/categories/inout_kind/inout_kind_list`,
        component: React.lazy(() => import('views/fob/categories/inout_kind/inout_kind_list')),
    },
    {
        key: 'apps.inout_kind.inout_kind_add',
        path: `${APP_PREFIX_PATH}/categories/inout_kind/inout_kind_add`,
        component: React.lazy(() => import('views/fob/categories/inout_kind/inout_kind_add')),
    },
    {
        key: 'apps.inout_kind.inout_kind_add',
        path: `${APP_PREFIX_PATH}/categories/inout_kind/inout_kind_add/:id`,
        component: React.lazy(() => import('views/fob/categories/inout_kind/inout_kind_add')),
    },
    {
        key: 'apps.inout_kind.inout_kind_edit',
        path: `${APP_PREFIX_PATH}/categories/inout_kind/inout_kind_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/inout_kind/inout_kind_edit')),
    },
    {
        key: 'apps.inout_kind.inout_kind_detail',
        path: `${APP_PREFIX_PATH}/categories/inout_kind/inout_kind_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/inout_kind/inout_kind_detail')),
    },
    {
        key: 'apps.inout_kind.inout_kind_masscopy',
        path: `${APP_PREFIX_PATH}/categories/inout_kind/inout_kind_masscopy`,
        component: React.lazy(() => import('views/fob/categories/inout_kind/inout_kind_masscopy')),
    },
    {
        key: 'apps.inout_kind.inout_kind_export',
        path: `${APP_PREFIX_PATH}/categories/inout_kind/inout_kind_export`,
        component: React.lazy(() => import('views/fob/categories/inout_kind/inout_kind_export')),
    },
    {
        key: 'apps.inout_kind.inout_kind_export_one',
        path: `${APP_PREFIX_PATH}/categories/inout_kind/inout_kind_export_one`,
        component: React.lazy(() => import('views/fob/categories/inout_kind/inout_kind_export_one')),
    },
    {
        key: 'apps.inout_kind.inout_kind_import',
        path: `${APP_PREFIX_PATH}/categories/inout_kind/inout_kind_import`,
        component: React.lazy(() => import('views/fob/categories/inout_kind/inout_kind_import')),
    },
    /*-------------End of inout_kind----------------*/

    /*-------------partner_type----------------*/
    {
        key: 'apps.partner_type',
        path: `${APP_PREFIX_PATH}/categories/partner_type`,
        component: React.lazy(() => import('views/fob/categories/partner_type')),
    },
    {
        key: 'apps.partner_type.partner_type_list',
        path: `${APP_PREFIX_PATH}/categories/partner_type/partner_type_list`,
        component: React.lazy(() => import('views/fob/categories/partner_type/partner_type_list')),
    },
    {
        key: 'apps.partner_type.partner_type_add',
        path: `${APP_PREFIX_PATH}/categories/partner_type/partner_type_add`,
        component: React.lazy(() => import('views/fob/categories/partner_type/partner_type_add')),
    },
    {
        key: 'apps.partner_type.partner_type_add',
        path: `${APP_PREFIX_PATH}/categories/partner_type/partner_type_add/:id`,
        component: React.lazy(() => import('views/fob/categories/partner_type/partner_type_add')),
    },
    {
        key: 'apps.partner_type.partner_type_edit',
        path: `${APP_PREFIX_PATH}/categories/partner_type/partner_type_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/partner_type/partner_type_edit')),
    },
    {
        key: 'apps.partner_type.partner_type_detail',
        path: `${APP_PREFIX_PATH}/categories/partner_type/partner_type_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/partner_type/partner_type_detail')),
    },
    {
        key: 'apps.partner_type.partner_type_masscopy',
        path: `${APP_PREFIX_PATH}/categories/partner_type/partner_type_masscopy`,
        component: React.lazy(() => import('views/fob/categories/partner_type/partner_type_masscopy')),
    },
    {
        key: 'apps.partner_type.partner_type_export',
        path: `${APP_PREFIX_PATH}/categories/partner_type/partner_type_export`,
        component: React.lazy(() => import('views/fob/categories/partner_type/partner_type_export')),
    },
    {
        key: 'apps.partner_type.partner_type_export_one',
        path: `${APP_PREFIX_PATH}/categories/partner_type/partner_type_export_one`,
        component: React.lazy(() => import('views/fob/categories/partner_type/partner_type_export_one')),
    },
    {
        key: 'apps.partner_type.partner_type_import',
        path: `${APP_PREFIX_PATH}/categories/partner_type/partner_type_import`,
        component: React.lazy(() => import('views/fob/categories/partner_type/partner_type_import')),
    },
    /*-------------End of partner_type----------------*/

    /*-------------partner_contact----------------*/
    {
        key: 'apps.partner_contact',
        path: `${APP_PREFIX_PATH}/categories/partner_contact`,
        component: React.lazy(() => import('views/fob/categories/partner_contact')),
    },
    {
        key: 'apps.partner_contact.partner_contact_list',
        path: `${APP_PREFIX_PATH}/categories/partner_contact/partner_contact_list`,
        component: React.lazy(() => import('views/fob/categories/partner_contact/partner_contact_list')),
    },
    {
        key: 'apps.partner_contact.partner_contact_add',
        path: `${APP_PREFIX_PATH}/categories/partner_contact/partner_contact_add`,
        component: React.lazy(() => import('views/fob/categories/partner_contact/partner_contact_add')),
    },
    {
        key: 'apps.partner_contact.partner_contact_add',
        path: `${APP_PREFIX_PATH}/categories/partner_contact/partner_contact_add/:id`,
        component: React.lazy(() => import('views/fob/categories/partner_contact/partner_contact_add')),
    },
    {
        key: 'apps.partner_contact.partner_contact_edit',
        path: `${APP_PREFIX_PATH}/categories/partner_contact/partner_contact_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/partner_contact/partner_contact_edit')),
    },
    {
        key: 'apps.partner_contact.partner_contact_detail',
        path: `${APP_PREFIX_PATH}/categories/partner_contact/partner_contact_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/partner_contact/partner_contact_detail')),
    },
    {
        key: 'apps.partner_contact.partner_contact_masscopy',
        path: `${APP_PREFIX_PATH}/categories/partner_contact/partner_contact_masscopy`,
        component: React.lazy(() => import('views/fob/categories/partner_contact/partner_contact_masscopy')),
    },
    {
        key: 'apps.partner_contact.partner_contact_export',
        path: `${APP_PREFIX_PATH}/categories/partner_contact/partner_contact_export`,
        component: React.lazy(() => import('views/fob/categories/partner_contact/partner_contact_export')),
    },
    {
        key: 'apps.partner_contact.partner_contact_export_one',
        path: `${APP_PREFIX_PATH}/categories/partner_contact/partner_contact_export_one`,
        component: React.lazy(() => import('views/fob/categories/partner_contact/partner_contact_export_one')),
    },
    {
        key: 'apps.partner_contact.partner_contact_import',
        path: `${APP_PREFIX_PATH}/categories/partner_contact/partner_contact_import`,
        component: React.lazy(() => import('views/fob/categories/partner_contact/partner_contact_import')),
    },
    /*-------------End of partner_contact----------------*/

    /*-------------partner_payment_info----------------*/
    {
        key: 'apps.partner_payment_info',
        path: `${APP_PREFIX_PATH}/categories/partner_payment_info`,
        component: React.lazy(() => import('views/fob/categories/partner_payment_info')),
    },
    {
        key: 'apps.partner_payment_info.partner_payment_info_list',
        path: `${APP_PREFIX_PATH}/categories/partner_payment_info/partner_payment_info_list`,
        component: React.lazy(() => import('views/fob/categories/partner_payment_info/partner_payment_info_list')),
    },
    {
        key: 'apps.partner_payment_info.partner_payment_info_add',
        path: `${APP_PREFIX_PATH}/categories/partner_payment_info/partner_payment_info_add`,
        component: React.lazy(() => import('views/fob/categories/partner_payment_info/partner_payment_info_add')),
    },
    {
        key: 'apps.partner_payment_info.partner_payment_info_add',
        path: `${APP_PREFIX_PATH}/categories/partner_payment_info/partner_payment_info_add/:id`,
        component: React.lazy(() => import('views/fob/categories/partner_payment_info/partner_payment_info_add')),
    },
    {
        key: 'apps.partner_payment_info.partner_payment_info_edit',
        path: `${APP_PREFIX_PATH}/categories/partner_payment_info/partner_payment_info_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/partner_payment_info/partner_payment_info_edit')),
    },
    {
        key: 'apps.partner_payment_info.partner_payment_info_detail',
        path: `${APP_PREFIX_PATH}/categories/partner_payment_info/partner_payment_info_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/partner_payment_info/partner_payment_info_detail')),
    },
    {
        key: 'apps.partner_payment_info.partner_payment_info_masscopy',
        path: `${APP_PREFIX_PATH}/categories/partner_payment_info/partner_payment_info_masscopy`,
        component: React.lazy(() => import('views/fob/categories/partner_payment_info/partner_payment_info_masscopy')),
    },
    {
        key: 'apps.partner_payment_info.partner_payment_info_export',
        path: `${APP_PREFIX_PATH}/categories/partner_payment_info/partner_payment_info_export`,
        component: React.lazy(() => import('views/fob/categories/partner_payment_info/partner_payment_info_export')),
    },
    {
        key: 'apps.partner_payment_info.partner_payment_info_export_one',
        path: `${APP_PREFIX_PATH}/categories/partner_payment_info/partner_payment_info_export_one`,
        component: React.lazy(() =>
            import('views/fob/categories/partner_payment_info/partner_payment_info_export_one'),
        ),
    },
    {
        key: 'apps.partner_payment_info.partner_payment_info_import',
        path: `${APP_PREFIX_PATH}/categories/partner_payment_info/partner_payment_info_import`,
        component: React.lazy(() => import('views/fob/categories/partner_payment_info/partner_payment_info_import')),
    },
    /*-------------End of partner_payment_info----------------*/

    /*-------------language----------------*/
    {
        key: 'apps.language',
        path: `${APP_PREFIX_PATH}/categories/language`,
        component: React.lazy(() => import('views/fob/categories/language')),
    },
    {
        key: 'apps.language.language_list',
        path: `${APP_PREFIX_PATH}/categories/language/language_list`,
        component: React.lazy(() => import('views/fob/categories/language/language_list')),
    },
    {
        key: 'apps.language.language_add',
        path: `${APP_PREFIX_PATH}/categories/language/language_add`,
        component: React.lazy(() => import('views/fob/categories/language/language_add')),
    },
    {
        key: 'apps.language.language_add',
        path: `${APP_PREFIX_PATH}/categories/language/language_add/:id`,
        component: React.lazy(() => import('views/fob/categories/language/language_add')),
    },
    {
        key: 'apps.language.language_edit',
        path: `${APP_PREFIX_PATH}/categories/language/language_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/language/language_edit')),
    },
    {
        key: 'apps.language.language_detail',
        path: `${APP_PREFIX_PATH}/categories/language/language_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/language/language_detail')),
    },
    {
        key: 'apps.language.language_masscopy',
        path: `${APP_PREFIX_PATH}/categories/language/language_masscopy`,
        component: React.lazy(() => import('views/fob/categories/language/language_masscopy')),
    },
    {
        key: 'apps.language.language_export',
        path: `${APP_PREFIX_PATH}/categories/language/language_export`,
        component: React.lazy(() => import('views/fob/categories/language/language_export')),
    },
    {
        key: 'apps.language.language_export_one',
        path: `${APP_PREFIX_PATH}/categories/language/language_export_one`,
        component: React.lazy(() => import('views/fob/categories/language/language_export_one')),
    },
    {
        key: 'apps.language.language_import',
        path: `${APP_PREFIX_PATH}/categories/language/language_import`,
        component: React.lazy(() => import('views/fob/categories/language/language_import')),
    },
    /*-------------End of language----------------*/

    /*-------------company_profile----------------*/
    {
        key: 'apps.company_profile',
        path: `${APP_PREFIX_PATH}/categories/company_profile`,
        component: React.lazy(() => import('views/fob/categories/company_profile')),
    },
    {
        key: 'apps.company_profile.company_profile_list',
        path: `${APP_PREFIX_PATH}/categories/company_profile/company_profile_list`,
        component: React.lazy(() => import('views/fob/categories/company_profile/company_profile_list')),
    },
    {
        key: 'apps.company_profile.company_profile_add',
        path: `${APP_PREFIX_PATH}/categories/company_profile/company_profile_add`,
        component: React.lazy(() => import('views/fob/categories/company_profile/company_profile_add')),
    },
    {
        key: 'apps.company_profile.company_profile_add',
        path: `${APP_PREFIX_PATH}/categories/company_profile/company_profile_add/:id`,
        component: React.lazy(() => import('views/fob/categories/company_profile/company_profile_add')),
    },
    {
        key: 'apps.company_profile.company_profile_edit',
        path: `${APP_PREFIX_PATH}/categories/company_profile/company_profile_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/company_profile/company_profile_edit')),
    },
    {
        key: 'apps.company_profile.company_profile_detail',
        path: `${APP_PREFIX_PATH}/categories/company_profile/company_profile_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/company_profile/company_profile_detail')),
    },
    {
        key: 'apps.company_profile.company_profile_masscopy',
        path: `${APP_PREFIX_PATH}/categories/company_profile/company_profile_masscopy`,
        component: React.lazy(() => import('views/fob/categories/company_profile/company_profile_masscopy')),
    },
    {
        key: 'apps.company_profile.company_profile_export',
        path: `${APP_PREFIX_PATH}/categories/company_profile/company_profile_export`,
        component: React.lazy(() => import('views/fob/categories/company_profile/company_profile_export')),
    },
    {
        key: 'apps.company_profile.company_profile_export_one',
        path: `${APP_PREFIX_PATH}/categories/company_profile/company_profile_export_one`,
        component: React.lazy(() => import('views/fob/categories/company_profile/company_profile_export_one')),
    },
    {
        key: 'apps.company_profile.company_profile_import',
        path: `${APP_PREFIX_PATH}/categories/company_profile/company_profile_import`,
        component: React.lazy(() => import('views/fob/categories/company_profile/company_profile_import')),
    },
    /*-------------End of company_profile----------------*/

    /*-------------company_profile_bank----------------*/
    {
        key: 'apps.company_profile_bank',
        path: `${APP_PREFIX_PATH}/categories/company_profile_bank`,
        component: React.lazy(() => import('views/fob/categories/company_profile_bank')),
    },
    {
        key: 'apps.company_profile_bank.company_profile_bank_list',
        path: `${APP_PREFIX_PATH}/categories/company_profile_bank/company_profile_bank_list`,
        component: React.lazy(() => import('views/fob/categories/company_profile_bank/company_profile_bank_list')),
    },
    {
        key: 'apps.company_profile_bank.company_profile_bank_add',
        path: `${APP_PREFIX_PATH}/categories/company_profile_bank/company_profile_bank_add`,
        component: React.lazy(() => import('views/fob/categories/company_profile_bank/company_profile_bank_add')),
    },
    {
        key: 'apps.company_profile_bank.company_profile_bank_add',
        path: `${APP_PREFIX_PATH}/categories/company_profile_bank/company_profile_bank_add/:id`,
        component: React.lazy(() => import('views/fob/categories/company_profile_bank/company_profile_bank_add')),
    },
    {
        key: 'apps.company_profile_bank.company_profile_bank_edit',
        path: `${APP_PREFIX_PATH}/categories/company_profile_bank/company_profile_bank_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/company_profile_bank/company_profile_bank_edit')),
    },
    {
        key: 'apps.company_profile_bank.company_profile_bank_detail',
        path: `${APP_PREFIX_PATH}/categories/company_profile_bank/company_profile_bank_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/company_profile_bank/company_profile_bank_detail')),
    },
    {
        key: 'apps.company_profile_bank.company_profile_bank_masscopy',
        path: `${APP_PREFIX_PATH}/categories/company_profile_bank/company_profile_bank_masscopy`,
        component: React.lazy(() => import('views/fob/categories/company_profile_bank/company_profile_bank_masscopy')),
    },
    {
        key: 'apps.company_profile_bank.company_profile_bank_export',
        path: `${APP_PREFIX_PATH}/categories/company_profile_bank/company_profile_bank_export`,
        component: React.lazy(() => import('views/fob/categories/company_profile_bank/company_profile_bank_export')),
    },
    {
        key: 'apps.company_profile_bank.company_profile_bank_export_one',
        path: `${APP_PREFIX_PATH}/categories/company_profile_bank/company_profile_bank_export_one`,
        component: React.lazy(() =>
            import('views/fob/categories/company_profile_bank/company_profile_bank_export_one'),
        ),
    },
    {
        key: 'apps.company_profile_bank.company_profile_bank_import',
        path: `${APP_PREFIX_PATH}/categories/company_profile_bank/company_profile_bank_import`,
        component: React.lazy(() => import('views/fob/categories/company_profile_bank/company_profile_bank_import')),
    },
    /*-------------End of company_profile_bank----------------*/
    /*-------------Start of department----------------*/
    {
        key: 'apps.department',
        path: `${APP_PREFIX_PATH}/categories/department`,
        component: React.lazy(() => import('views/fob/categories/department')),
    },
    {
        key: 'apps.department.department_list',
        path: `${APP_PREFIX_PATH}/categories/department/department_list`,
        component: React.lazy(() => import('views/fob/categories/department/department_list')),
    },
    {
        key: 'apps.department.department_add',
        path: `${APP_PREFIX_PATH}/categories/department/department_add`,
        component: React.lazy(() => import('views/fob/categories/department/department_add')),
    },
    {
        key: 'apps.department.department_add',
        path: `${APP_PREFIX_PATH}/categories/department/department_add/:id`,
        component: React.lazy(() => import('views/fob/categories/department/department_add')),
    },
    {
        key: 'apps.department.department_edit',
        path: `${APP_PREFIX_PATH}/categories/department/department_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/department/department_edit')),
    },
    {
        key: 'apps.department.department_detail',
        path: `${APP_PREFIX_PATH}/categories/department/department_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/department/department_detail')),
    },
    {
        key: 'apps.department.department_masscopy',
        path: `${APP_PREFIX_PATH}/categories/department/department_masscopy`,
        component: React.lazy(() => import('views/fob/categories/department/department_masscopy')),
    },
    {
        key: 'apps.department.department_export',
        path: `${APP_PREFIX_PATH}/categories/department/department_export`,
        component: React.lazy(() => import('views/fob/categories/department/department_export')),
    },
    {
        key: 'apps.department.department_import',
        path: `${APP_PREFIX_PATH}/categories/department/department_import`,
        component: React.lazy(() => import('views/fob/categories/department/department_import')),
    },
    /*-------------End of department----------------*/
    /*-------------Start of employee----------------*/
    {
        key: 'apps.employee',
        path: `${APP_PREFIX_PATH}/categories/employee`,
        component: React.lazy(() => import('views/fob/categories/employee')),
    },
    {
        key: 'apps.employee.employee_list',
        path: `${APP_PREFIX_PATH}/categories/employee/employee_list`,
        component: React.lazy(() => import('views/fob/categories/employee/employee_list')),
    },
    {
        key: 'apps.employee.employee_add',
        path: `${APP_PREFIX_PATH}/categories/employee/employee_add`,
        component: React.lazy(() => import('views/fob/categories/employee/employee_add')),
    },
    {
        key: 'apps.employee.employee_add',
        path: `${APP_PREFIX_PATH}/categories/employee/employee_add/:id`,
        component: React.lazy(() => import('views/fob/categories/employee/employee_add')),
    },
    {
        key: 'apps.employee.employee_edit',
        path: `${APP_PREFIX_PATH}/categories/employee/employee_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/employee/employee_edit')),
    },
    {
        key: 'apps.employee.employee_detail',
        path: `${APP_PREFIX_PATH}/categories/employee/employee_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/employee/employee_detail')),
    },
    {
        key: 'apps.employee.employee_masscopy',
        path: `${APP_PREFIX_PATH}/categories/employee/employee_masscopy`,
        component: React.lazy(() => import('views/fob/categories/employee/employee_masscopy')),
    },
    {
        key: 'apps.employee.employee_export',
        path: `${APP_PREFIX_PATH}/categories/employee/employee_export`,
        component: React.lazy(() => import('views/fob/categories/employee/employee_export')),
    },
    {
        key: 'apps.employee.employee_import',
        path: `${APP_PREFIX_PATH}/categories/employee/employee_import`,
        component: React.lazy(() => import('views/fob/categories/employee/employee_import')),
    },
    /*-------------End of employee----------------*/
    /*-------------Start of hr_position----------------*/
    {
        key: 'apps.hr_position',
        path: `${APP_PREFIX_PATH}/categories/hr_position`,
        component: React.lazy(() => import('views/fob/categories/hr_position')),
    },
    {
        key: 'apps.hr_position.hr_position_list',
        path: `${APP_PREFIX_PATH}/categories/hr_position/hr_position_list`,
        component: React.lazy(() => import('views/fob/categories/hr_position/hr_position_list')),
    },
    {
        key: 'apps.hr_position.hr_position_add',
        path: `${APP_PREFIX_PATH}/categories/hr_position/hr_position_add`,
        component: React.lazy(() => import('views/fob/categories/hr_position/hr_position_add')),
    },
    {
        key: 'apps.hr_position.hr_position_add',
        path: `${APP_PREFIX_PATH}/categories/hr_position/hr_position_add/:id`,
        component: React.lazy(() => import('views/fob/categories/hr_position/hr_position_add')),
    },
    {
        key: 'apps.hr_position.hr_position_edit',
        path: `${APP_PREFIX_PATH}/categories/hr_position/hr_position_edit/:id`,
        component: React.lazy(() => import('views/fob/categories/hr_position/hr_position_edit')),
    },
    {
        key: 'apps.hr_position.hr_position_detail',
        path: `${APP_PREFIX_PATH}/categories/hr_position/hr_position_detail/:id`,
        component: React.lazy(() => import('views/fob/categories/hr_position/hr_position_detail')),
    },
    {
        key: 'apps.hr_position.hr_position_masscopy',
        path: `${APP_PREFIX_PATH}/categories/hr_position/hr_position_masscopy`,
        component: React.lazy(() => import('views/fob/categories/hr_position/hr_position_masscopy')),
    },
    {
        key: 'apps.hr_position.hr_position_export',
        path: `${APP_PREFIX_PATH}/categories/hr_position/hr_position_export`,
        component: React.lazy(() => import('views/fob/categories/hr_position/hr_position_export')),
    },
    {
        key: 'apps.hr_position.hr_position_import',
        path: `${APP_PREFIX_PATH}/categories/hr_position/hr_position_import`,
        component: React.lazy(() => import('views/fob/categories/hr_position/hr_position_import')),
    },
];
