import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { THEME_CONFIG } from 'configs/AppConfig';
i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            // translation file path
            loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
            //loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        // fallbackLng: 'vi',
        fallbackLng: THEME_CONFIG.locale,
        lng: THEME_CONFIG.locale,
        // disabled in production
        debug: false,

        // can have multiple namespaces, in case you want to divide a huge
        // translation into smaller pieces and load them on demand
        // ns: ['common', 'home', 'article'],
        defaultNS: 'home',
        interpolation: {
            espaceValue: false,
            formatSeparator: ',',
        },
        react: {
            wait: true,
        },
    });

export default i18n;
