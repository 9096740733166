import React from 'react';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const RoutesHrm = [
    {
        key: 'fob',
        path: `${APP_PREFIX_PATH}/invoices`,
        component: React.lazy(() => import('views/fob/invoices')),
    },
    {
        key: 'fob.invoices',
        path: `${APP_PREFIX_PATH}/invoices/`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_list')),
    },
    {
        key: 'fob.invoices.invoice_list',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_list`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_list')),
    },
    {
        key: 'fob.invoices.invoice_add',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_add`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_add')),
    },
    {
        key: 'fob.invoices.invoice_edit',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_edit/:id`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_edit')),
    },
    {
        key: 'fob.invoices.invoice_masscopy',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_masscopy`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_masscopy')),
    },
    {
        key: 'fob.invoices.invoice_export',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_export`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_export')),
    },
    {
        key: 'fob.invoices.invoice_import',
        path: `${APP_PREFIX_PATH}/invoices/invoice/invoice_import`,
        component: React.lazy(() => import('views/fob/invoices/invoice/invoice_import')),
    },
];
export default RoutesHrm;
