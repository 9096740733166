import axios from 'axios';
import { API_BASE_URL, API_AUTH_URL } from '../../configs/AppConfig';
import { AUTH_TOKEN, USER_KEY } from 'constants/AuthConstant';
const instance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        // "Content-Type": "application/pdf"
    },
});

let isRefreshToken = false;
let requestsToRefresh = [];

// before send request
instance.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem(AUTH_TOKEN);
        // const token = JSON.parse(sessionStorage.getItem("access_token"));
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    function (error) {
        Promise.reject(error);
    },
);

// after send request
instance.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
        const { response, config } = error;
        const status = response?.status;

        // The account not active
        if (status === 403) {
            // Fobiden
            localStorage.removeItem(AUTH_TOKEN);
            window.location.href = `/auth/login?redirect=${window.location.pathname}${window.location.search}`;
            return Promise.reject(error);
        }

        // 1. Should refresh token when status response 401
        // if status is response code 401, we need to send request token here
        if (status === 401) {
            const token = localStorage.getItem(AUTH_TOKEN); // Retrieve the stored expired token.
            if (!token) return Promise.reject(error);

            // 1.1 refresh token is false, to call refresh token api
            if (!isRefreshToken) {
                isRefreshToken = true;
                // Make a request to your auth server to refresh the token.
                axios
                    .post(
                        `${API_AUTH_URL}auth/refresh`,
                        {},
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: token,
                            },
                        },
                    )
                    .then((response) => {
                        const res = response?.data;

                        if (res?.data?.accessToken) {
                            localStorage.setItem(AUTH_TOKEN, res?.data?.accessToken);
                            localStorage.setItem(
                                USER_KEY,
                                JSON.stringify({                                  
                                    id: res?.data?.user?.id,
                                    displayname: res?.data?.user?.displayname,
                                    username: res?.data?.user?.username,
                                    email: res?.data?.user?.email,
                                    department_id: res?.data?.user?.department_id,
                                    complete_name: res?.data?.user?.complete_name,
                                    avatar: res?.data?.user?.avatar,
                                    employee_code: res?.data?.user?.employee_code,
                                    name: res?.data?.user?.name,
                                    permissions: res?.data?.permissions,
                                }),
                            );
                            requestsToRefresh.forEach((callback) => {
                                callback(res?.data?.accessToken);
                            });
                            // Update the authorization header with the new access token.
                            // instance.defaults.headers.common['Authorization'] = `${response.data.accessToken}`;
                            // return instance(originalRequest); // Retry the original request with the new access token.
                        } else {
                            localStorage.removeItem(AUTH_TOKEN);
                            requestsToRefresh.forEach((cb) => cb(null));
                            window.location.href = `/auth/login?redirect=${window.location.pathname}${window.location.search}`;
                        }
                    })
                    .catch((error) => {
                        localStorage.removeItem(AUTH_TOKEN);
                        requestsToRefresh.forEach((cb) => cb(null));
                        window.location.href = `/auth/login?redirect=${window.location.pathname}${window.location.search}`;
                    })
                    .finally(() => {
                        // 5. After that, to clear all setup
                        isRefreshToken = false;
                        requestsToRefresh = [];
                    });
            }

            // 3. Setup callback to change token in headers authorization
            return new Promise((resolve, reject) => {
                requestsToRefresh.push((token) => {
                    if (token) {
                        // Reset access_token for another request behind
                        config.headers.Authorization = `${token}`;
                        resolve(instance(config));
                    }

                    reject(error);
                });
            });
        }

        return Promise.reject(error);
    },
);
export default instance;
